import React, { useEffect, useState } from "react";
// import { dummyData } from ".";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Projects from "layouts/dashboard/components/Projects";
import Fields from "layouts/dashboard/components/Fields";
import Cookies from "universal-cookie";
import useGetAllQuestions from "hook/useGetAllQuestions";
import Tasks from "layouts/dashboard/components/Tasks";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { CircularProgress } from "@mui/material";

const AdminTasks = () => {
  const [saveData, setSaveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const cookies = new Cookies();

  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const fetchTasks = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(baseUrl + apiV1 + `/tasks/all`, {
        headers,
      });
      
      const tasksData = res.data?.tasks || [];
      console.log(tasksData)
      setSaveData(tasksData);
      setCount(Math.ceil(tasksData.length / 10));
    
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setSaveData([]);
      setCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [page, searchQuery]);

  console.log(saveData)

  return (
    <DashboardLayout>
      
      <MDBox py={3}  sx={{overflowY: "auto"}}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center">
            <CircularProgress />
          </MDBox>
        ) : (
          <Tasks
            dataType="users"
            setPage={setPage}
            count={count}
            page={page}
            saveData={saveData}
            setSaveData={setSaveData}
            handleSearchChange={setSearchQuery}
            title={"Tasks"}
          />
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default AdminTasks;
