import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutModal = ({ open, handleClose, handleLogout, loading }) => {
  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      aria-labelledby="logout-dialog"
      PaperProps={{
        style: {
          borderRadius: "10px",
        }
      }}
    >
      <div className="modal-dialog modal-confirm">
        <div
          className="modal-content"
          style={{
            padding: "20px",
            borderRadius: "10px",
            border: "none",
            textAlign: "center",
            fontSize: "14px",
            minWidth: "400px",
          }}
        >
          <div className="modal-header flex-column">
            
            <h4
              className="modal-title w-100"
              style={{ 
                textAlign: "center", 
                fontSize: "24px", 
                margin: "35px 0 15px",
                color: "#333",
                fontWeight: "500",
              }}
            >
              Confirm Logout
            </h4>
          </div>
          <div 
            className="modal-body" 
            style={{ 
              color: "#666", 
              margin: "10px 0 25px",
              fontSize: "15px" 
            }}
          >
            <p style={{ margin: 0 }}>Are you sure you want to logout?</p>
          </div>
          <div
            className="modal-footer justify-content-center"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "10px",
              fontSize: "13px",
              padding: "10px 15px 25px",
            }}
          >
            <Button
              style={{
                borderRadius: "8px",
                backgroundColor: "#e0e0e0",
                padding: "8px 25px",
                color: "#666",
                fontSize: "14px",
                marginRight: "12px",
                textTransform: "none",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "#d5d5d5",
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                borderRadius: "8px",
                backgroundColor: "#f15e5e",
                padding: "8px 25px",
                color: "white",
                fontSize: "14px",
                marginLeft: "12px",
                textTransform: "none",
                fontWeight: "500",
                boxShadow: "0 2px 6px rgba(241, 94, 94, 0.3)",
                "&:hover": {
                  backgroundColor: "#e14444",
                },
              }}
              onClick={handleLogout}
              disabled={loading}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LogoutModal; 