import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Chip,
  CircularProgress,
  Backdrop,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import { DataGrid } from "@mui/x-data-grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from "axios";
import Cookies from "universal-cookie";
import { baseUrl, apiV1 } from "utils/constants";
import DocumentViewer from './components/DocumentViewer';
import DocumentFilters from './components/DocumentFilters';

function DocumentLibrary() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    company: "",
    unit: "",
    department: "",
  });
  const [companies, setCompanies] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadingBackdrop, setDownloadingBackdrop] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [viewerError, setViewerError] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const cookies = new Cookies();

  useEffect(() => {
    fetchDocuments();
    fetchCompanies();
  }, []);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
      };

      const response = await axios.get(`${baseUrl}${apiV1}/attachments`, { headers });
      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
      };

      const response = await axios.get(`${baseUrl}${apiV1}/companies`, { headers });
      setCompanies(response.data.data || []);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleDownload = async (fileDoc) => {
    try {
      setDownloading(true);
      setDownloadingBackdrop(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
      };

      const response = await axios.get(`${baseUrl}${apiV1}/attachments/${fileDoc._id}`, {
        headers,
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileDoc.originalName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setDownloading(false);
      setDownloadingBackdrop(false);
    }
  };

  const getDocumentViewUrl = (document) => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
    };
    return `${baseUrl}${apiV1}/attachments/${document._id}/view`;
  };

  const handleView = async (document) => {
    setSelectedDocument(document);
    setViewerError(false);
    setViewerOpen(true);

    try {
      const url = getDocumentViewUrl(document);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Failed to load document');
      }

      // Create a blob URL from the response
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setDocumentUrl(blobUrl);
    } catch (error) {
      console.error("Error preparing document for viewing:", error);
      setViewerError(true);
    }
  };

  // Add cleanup for blob URLs
  useEffect(() => {
    return () => {
      // Cleanup blob URL when component unmounts
      if (documentUrl && documentUrl.startsWith('blob:')) {
        URL.revokeObjectURL(documentUrl);
      }
    };
  }, [documentUrl]);

  const getFileType = (filename) => {
    if (!filename) return "unknown";
    
    const extension = filename.split('.').pop().toLowerCase();
    
    if (['pdf'].includes(extension)) return "pdf";
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension)) return "image";
    if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'].includes(extension)) return "document";
    
    return "unknown";
  };

  const renderDocumentViewer = () => {
    if (!selectedDocument) return null;
    if (viewerError) {
      return (
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          height="100%"
          padding={4}
        >
          <DescriptionIcon sx={{ fontSize: 60, color: "grey.500", mb: 2 }} />
          <MDTypography variant="h6" color="error" gutterBottom>
            Unable to display document
          </MDTypography>
          <MDTypography variant="body2" color="text" textAlign="center" mb={3}>
            The document could not be displayed in the viewer. Please try downloading it instead.
          </MDTypography>
          <MDButton 
            variant="contained" 
            color="info" 
            onClick={() => handleDownload(selectedDocument)}
            startIcon={<GetAppIcon />}
          >
            Download Document
          </MDButton>
        </Box>
      );
    }

    const fileType = getFileType(selectedDocument.originalName);

    switch (fileType) {
      case "pdf":
        return (
          <iframe
            src={documentUrl}
            width="100%"
            height="100%"
            title="PDF Viewer"
            style={{ border: "none" }}
            onError={() => setViewerError(true)}
          />
        );
      case "image":
        return (
          <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            height="100%" 
            bgcolor="grey.100"
          >
            <img 
              src={documentUrl} 
              alt={selectedDocument.originalName}
              style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
              onError={() => setViewerError(true)}
            />
          </Box>
        );
      default:
        return (
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            height="100%"
            padding={4}
          >
            <DescriptionIcon sx={{ fontSize: 60, color: "grey.500", mb: 2 }} />
            <MDTypography variant="h6" gutterBottom>
              Preview not available
            </MDTypography>
            <MDTypography variant="body2" color="text" textAlign="center" mb={3}>
              This file type cannot be previewed directly in the browser. Please download it to view its contents.
            </MDTypography>
            <MDButton 
              variant="contained" 
              color="info" 
              onClick={() => handleDownload(selectedDocument)}
              startIcon={<GetAppIcon />}
            >
              Download Document
            </MDButton>
          </Box>
        );
    }
  };

  const handleClearFilters = () => {
    setFilters({
      company: "",
      unit: "",
      department: "",
    });
    setSearchTerm("");
  };

  const filteredDocuments = documents.filter((doc) => {
    const matchesSearch =
      !searchTerm ||
      doc.originalName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.question?.questionText?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.updatedByUser?.name?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCompany = !filters.company || doc.company?.name === filters.company;

    return matchesSearch && matchesCompany;
  });

  const getSerialNumber = (index) => {
    const currentPage = Math.floor(index / paginationModel.pageSize);
    return currentPage * paginationModel.pageSize + (index % paginationModel.pageSize) + 1;
  };

  const getFileIcon = (filename) => {
    const fileType = getFileType(filename);
    
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfIcon color="error" fontSize="small" />;
      case "image":
        return <ImageIcon color="success" fontSize="small" />;
      default:
        return <InsertDriveFileIcon color="primary" fontSize="small" />;
    }
  };

  const columns = [
    {
      field: 'srNo',
      headerName: 'Sr. No.',
      width: 80,
      valueGetter: (params) => {
        const index = filteredDocuments.indexOf(params.row);
        return getSerialNumber(index);
      },
      pinned: 'left',
    },
    {
      field: "originalName",
      headerName: "File Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {getFileIcon(params.value)}
          <MDTypography variant="caption">{params.value}</MDTypography>
        </Box>
      ),
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1.5,
      minWidth: 300,
      valueGetter: (params) => params.row.question?.questionText || "N/A",
      renderCell: (params) => (
        <MDTypography
          variant="caption"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => params.row.description || "N/A",
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => params.row.unit?.name || "N/A",
    },
    {
      field: "department",
      headerName: "Department",
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => params.row.department?.name || "N/A",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => params.row.company?.name || "N/A",
    },
    {
      field: "updatedByUser",
      headerName: "Uploaded By",
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => params.row.updatedByUser?.name || "N/A",
    },
    {
      field: "updatedAt",
      headerName: "Upload Date",
      flex: 0.8,
      minWidth: 150,
      valueGetter: (params) => new Date(params.row.updatedAt).toLocaleDateString(),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      minWidth: 120,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <Tooltip title="View Document">
            <IconButton onClick={() => handleView(params.row)} color="info" size="small">
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
              onClick={() => handleDownload(params.row)}
              disabled={downloading}
              color="primary"
              size="small"
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <MDBox p={3} display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6">Document Library</MDTypography>
                  <MDTypography variant="caption" color="text">
                    {filteredDocuments.length} documents found
                  </MDTypography>
                </MDBox>

                <DocumentFilters 
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  filters={filters}
                  setFilters={setFilters}
                  companies={companies}
                  handleClearFilters={handleClearFilters}
                />

                <Box
                  sx={{
                    height: "calc(100vh - 300px)",
                    width: "100%",
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                  }}
                >
                  <DataGrid
                    rows={filteredDocuments}
                    columns={columns}
                    getRowId={(row) => row._id}
                    loading={loading}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "updatedAt", sort: "desc" }],
                      },
                      pinnedColumns: { left: ['srNo'] },
                    }}
                    components={{
                      NoRowsOverlay: () => (
                        <Box sx={{ p: 4, textAlign: "center", color: "#546E7A" }}>
                          <InsertDriveFileIcon sx={{ fontSize: 40, mb: 1, opacity: 0.5 }} />
                          <MDTypography variant="body2">No documents available</MDTypography>
                        </Box>
                      ),
                    }}
                    sx={{
                      "& .MuiDataGrid-cell:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      "& .MuiDataGrid-row:nth-of-type(even)": {
                        backgroundColor: "rgba(0, 0, 0, 0.02)",
                      },
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Download Backdrop */}
      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
          flexDirection: "column",
          gap: 2,
        })}
        open={downloadingBackdrop}
      >
        <CircularProgress color="inherit" />
        <MDTypography color="white" variant="h6">
          Downloading attachment...
        </MDTypography>
      </Backdrop>

      <DocumentViewer 
        open={viewerOpen}
        onClose={() => setViewerOpen(false)}
        document={selectedDocument}
        documentUrl={documentUrl}
        viewerError={viewerError}
        onDownload={handleDownload}
        getFileIcon={getFileIcon}
      />
    </DashboardLayout>
  );
}

export default DocumentLibrary;