import { useEffect, useState, useMemo } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Components
import AddDepartment from "./AddDepartment";
import ConfirmDialog from "components/MDDialog/ConfirmDialog";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import CommonTable from "../CommonTable";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

function DepartmentTable({
  saveData,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  count,
  setCount,
  setPage,
  page,
  companies,
  units,
}) {
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedItemsEdit, setSelectedItemsEdit] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    companies: [],
    units: [],
    departments: [],
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const cookies = new Cookies();

  useEffect(() => {
    fetchDepartments();
  }, [page, searchTerm, filters]);

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      // Build query parameters including filters
      let queryParams = `limit=10&page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`;

      // Add filter parameters if they exist
      if (filters.company && filters.company.length > 0) {
        queryParams += `&companies=${filters.company.join(",")}`;
      }
      if (filters.unit && filters.unit.length > 0) {
        queryParams += `&units=${filters.unit.join(",")}`;
      }

      const res = await axios.get(`${baseUrl}${apiV1}/departments?${queryParams}`, { headers });

      if (res.status === 200) {
        setSaveData(res.data || []);
        setCount(res.data.totalPages || 1);
      }
    } catch (e) {
      console.error("Error fetching departments:", e);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "srNo",
      headerName: "SR No",
      flex: 0.4,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Department",
      flex: 1.2,
      sortable: true,
    },
    {
      field: "companyName",
      headerName: "Company",
      flex: 1,
      sortable: true,
    },
    {
      field: "unitName",
      headerName: "Unit",
      flex: 1,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      sortable: true,
      renderCell: (params) => (
        <MDTypography
          variant="caption"
          color={params.row.isActive ? "success" : "error"}
          fontWeight="medium"
        >
          {params.row.isActive ? "Active" : "Inactive"}
        </MDTypography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      sortable: false,
      renderCell: (params) => (
        <MDBox display="flex" gap={1}>
          <Icon
            sx={{ cursor: "pointer", color: "info.main" }}
            onClick={() => {
              setSelectedItemsEdit(params.row.originalData);
              setModalType("Edit");
              setOpen(true);
            }}
          >
            edit
          </Icon>
          <Icon
            sx={{ cursor: "pointer", color: "error.main" }}
            onClick={() => setSelectedItems(params.row.originalData)}
          >
            delete
          </Icon>
        </MDBox>
      ),
    },
  ];

  const filteredRows = useMemo(() => {
    let filtered = Array.isArray(saveData) ? saveData : saveData?.data || [];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.unit?.company?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.unit?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply company filter
    if (filters.companies.length > 0) {
      filtered = filtered.filter(item => {
        return item.unit?.company && filters.companies.includes(item.unit.company._id);
      });
    }

    // Apply unit filter
    if (filters.units.length > 0) {
      filtered = filtered.filter(item => {
        return item.unit && filters.units.includes(item.unit._id);
      });
    }

    return filtered.map((item, index) => ({
      id: item._id || item.id,
      srNo: index + 1,
      name: item.name || '',
      companyName: item.unit?.company?.name || '-',
      unitName: item.unit?.name || '-',
      description: item.description || '',
      isActive: item.isActive,
      originalData: item,
      _id: item._id || item.id
    }));
  }, [saveData, searchTerm, filters]);

  // Configuration for pinned columns
  const pinnedColumns = {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "srNo"],
    right: ["actions"],
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6">Departments</MDTypography>
          <MDButton
            variant="gradient"
            color="info"
            startIcon={<AddIcon />}
            onClick={() => {
              setModalType("Add");
              setOpen(true);
            }}
          >
            Department
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox>
        <CommonTable
          columns={columns}
          rows={filteredRows}
          loading={loading}
          onSearchChange={setSearchTerm}
          filterOptions={{
            companies,
            units,
            departments: [],
          }}
          filters={filters}
          setFilters={setFilters}
          showCompanyFilter={true}
          showUnitFilter={true}
          setSelectedRows={setSelectedRows}
          pinnedColumns={pinnedColumns}
        />
      </MDBox>

      <AddDepartment
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={() => {
          if (onSuccessPost) onSuccessPost();
          fetchDepartments();
        }}
        modalType={modalType}
        selectedDepartment={selectedItemsEdit}
        companies={companies}
        units={units}
      />

      <ConfirmDialog
        isOpen={selectedItems !== null}
        onClose={() => setSelectedItems(null)}
        onDeleteSuccess={() => {
          console.log(`Department ${selectedItems?.name} deleted successfully.`);
          fetchDepartments();
        }}
        name={selectedItems?.name}
        userId={selectedItems?.id || selectedItems?._id}
        saveData={saveData}
        setSaveData={setSaveData}
        entityType="department"
      />
    </Card>
  );
}

export default DepartmentTable;
