import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import MDButton from "components/MDButton";
import { useState } from "react";

export default function AssignedTasksTable({ tasks, onQuestionClick }) {
  // Add at the start of component
  console.log('AssignedTasksTable - Received tasks:', tasks);

  const [filters, setFilters] = useState({
    sections: [],
    parts: [],
    statuses: [],
    attachmentRequired: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  // Extract unique values for filters
  const sections = [...new Set(tasks.map((task) => task.question?.section))].filter(Boolean);
  const parts = [...new Set(tasks.map((task) => task.question?.part))].filter(Boolean);
  const statuses = [...new Set(tasks.map((task) => task.taskStatus))].filter(Boolean);

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [filterName]: value };
      // Clear parts when section changes
      if (filterName === "sections") {
        newFilters.parts = [];
      }
      return newFilters;
    });
  };

  const handleClearFilters = () => {
    setFilters({
      sections: [],
      parts: [],
      statuses: [],
      attachmentRequired: [],
    });
    setSearchTerm("");
  };

  // Status color mapping
  const getStatusColor = (status) => {
    switch (status) {
      case "PENDING":
        return { bg: "#FFF8E1", text: "#FF8F00" }; // Amber
      case "DRAFTED":
        return { bg: "#E3F2FD", text: "#1976D2" }; // Blue
      case "SUBMITTED":
        return { bg: "#E8F5E9", text: "#2E7D32" }; // Green
      case "Overdue":
        return { bg: "#FFEBEE", text: "#C62828" }; // Red
      default:
        return { bg: "#ECEFF1", text: "#546E7A" }; // Blue Grey
    }
  };

  // Check if task is due soon (within 2 days)
  const isDueSoon = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = due - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 2 && diffDays >= 0;
  };

  const filteredTasks = tasks.filter((task) => {
    // Search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = 
        task.question?.questionText?.toLowerCase().includes(searchLower) ||
        task.question?.section?.toLowerCase().includes(searchLower) ||
        task.question?.part?.toLowerCase().includes(searchLower) ||
        task.taskStatus?.toLowerCase().includes(searchLower);

      if (!matchesSearch) return false;
    }

    // Section filter
    if (filters.sections.length > 0) {
      if (!task.question?.section || !filters.sections.includes(task.question.section)) {
        return false;
      }
    }

    // Part filter
    if (filters.parts.length > 0) {
      if (!task.question?.part || !filters.parts.includes(task.question.part)) {
        return false;
      }
    }

    // Status filter
    if (filters.statuses.length > 0) {
      if (!task.taskStatus || !filters.statuses.includes(task.taskStatus)) {
        return false;
      }
    }

    // Attachment filter
    if (filters.attachmentRequired.length > 0) {
      const isRequired = task.isAttachmentRequired ? "Required" : "Not Required";
      if (!filters.attachmentRequired.includes(isRequired)) {
        return false;
      }
    }

    return true;
  });

  // Add this helper function near the top of the component
  const truncateText = (text, maxLength = 150) => {
    if (!text) return "No question text";
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const handleQuestionClick = (question, task) => {
    console.log('Question clicked:', {
      task
    });
    onQuestionClick(question, task);
  };

  const columns = [
    {
      field: "question",
      headerName: "Question",
      minWidth: 400,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.question?.questionText || "No question text"} arrow>
          <Button
            onClick={() => handleQuestionClick(params.row.question, params.row)}
            color="primary"
            sx={{
              textAlign: "left",
              justifyContent: "flex-start",
              width: "100%",
              textTransform: "none",
              padding: "8px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <AssignmentIcon fontSize="small" color="primary" />
              <MDTypography
                variant="button"
                fontWeight="regular"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  color: "#1a73e8",
                }}
              >
                {truncateText(params.row.question?.questionText)}
              </MDTypography>
            </Box>
          </Button>
        </Tooltip>
      ),
    },
    {
      field: "assigner",
      headerName: "Assigner",
      width: 160,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <PersonIcon fontSize="small" color="action" />
          <MDTypography variant="caption">{params.row.assigner?.name || "N/A"}</MDTypography>
        </Box>
      ),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 150,
      renderCell: (params) => {
        const dueSoon = isDueSoon(params.row.dueDate);
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CalendarTodayIcon
              fontSize="small"
              color={dueSoon ? "error" : "action"}
            />
            <MDTypography
              variant="caption"
              color={dueSoon ? "error" : "dark"}
              fontWeight={dueSoon ? "medium" : "regular"}
            >
              {new Date(params.row.dueDate).toLocaleDateString()}
            </MDTypography>
          </Box>
        );
      },
    },
    {
      field: "taskStatus",
      headerName: "Status",
      width: 130,
      renderCell: (params) => {
        const statusColors = getStatusColor(params.row.taskStatus);
        return (
          <Chip
            label={params.row.taskStatus}
            size="small"
            sx={{
              backgroundColor: statusColors.bg,
              color: statusColors.text,
              fontWeight: 500,
              fontSize: "0.75rem",
              height: "24px",
            }}
          />
        );
      },
    },
    {
      field: "section",
      headerName: "Section",
      width: 180,
      renderCell: (params) => (
        <Chip
          label={params.row.question?.section || "N/A"}
          size="small"
          variant="outlined"
          sx={{
            fontSize: "0.75rem",
            height: "24px",
          }}
        />
      ),
    },
    {
      field: "part",
      headerName: "Part",
      width: 150,
      renderCell: (params) => (
        <MDTypography variant="caption">{params.row.question?.part || "N/A"}</MDTypography>
      ),
    },
    {
      field: "dept",
      headerName: "Department",
      width: 150,
      renderCell: (params) => (
        <MDTypography variant="caption">{params.row.question?.dept || "N/A"}</MDTypography>
      ),
    },
    {
      field: "questionType",
      headerName: "Type",
      width: 120,
      renderCell: (params) => (
        <MDTypography variant="caption">{params.row.question?.questionType || "N/A"}</MDTypography>
      ),
    },
    {
      field: "isAttachmentRequired",
      headerName: "Attachment Required",
      width: 160,
      renderCell: (params) => {
        const isRequired = params.row.question?.isAttachmentRequired;
        const colors = getAttachmentColor(isRequired);
        return (
          <Chip
            label={isRequired ? "Required" : "Not Required"}
            size="small"
            sx={{
              backgroundColor: colors.bg,
              color: colors.text,
              fontWeight: 500,
              fontSize: "0.75rem",
              height: "24px",
            }}
          />
        );
      },
    }
  ];

  return (
    <>
      <MDBox p={3} >
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <MDTypography variant="h6">Assigned Tasks</MDTypography>
          <MDBox>
            <MDTypography variant="caption" color="text" mr={1}>
              {filteredTasks.length} tasks found
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox
          display="flex"
          flexWrap="wrap"
          gap={2}
          alignItems="center"
          mb={3}
        >
          {/* Search Bar */}
          <TextField
            placeholder="Search questions or assigners..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchTerm("")}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: 270,}}
          />

          {/* Section Filter */}
          <FormControl sx={{ width: 270 }} size="small">
            <InputLabel>Section</InputLabel>
            <Select
              multiple
              sx={{height: '40px'}}

              value={filters.sections}
              onChange={(e) => handleFilterChange("sections", e.target.value)}
              label="Section"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} size="small" />
                  ))}
                </Box>
              )}
            >
              {sections.map((section) => (
                <MenuItem key={section} value={section}>
                  {section}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Status Filter */}
          <FormControl sx={{ width: 270 }} size="small">
            <InputLabel>Status</InputLabel>
            <Select
              multiple
              sx={{height: '40px'}}

              value={filters.statuses}
              onChange={(e) => handleFilterChange("statuses", e.target.value)}
              label="Status"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const statusColors = getStatusColor(value);
                    return (
                      <Chip
                        key={value}
                        label={value}
                        size="small"
                        sx={{
                          backgroundColor: statusColors.bg,
                          color: statusColors.text,
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {statuses.map((status) => {
                const statusColors = getStatusColor(status);
                return (
                  <MenuItem key={status} value={status}>
                    <Chip
                      label={status}
                      size="small"
                      sx={{
                        backgroundColor: statusColors.bg,
                        color: statusColors.text,
                        mr: 1,
                      }}
                    />{" "}
                    {status}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* Attachment Required Filter */}
          <FormControl sx={{ width: 270 }} size="small">
            <InputLabel>Attachment Required</InputLabel>
            <Select
              multiple
              sx={{ height: '40px' }}
              value={filters.attachmentRequired}
              onChange={(e) => handleFilterChange("attachmentRequired", e.target.value)}
              label="Attachment Required"
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const colors = getAttachmentColor(value);
                    return (
                      <Chip
                        key={value.toString()}
                        label={value ? "Required" : "Not Required"}
                        size="small"
                        sx={{
                          backgroundColor: colors.bg,
                          color: colors.text,
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {[true, false].map((value) => {
                const colors = getAttachmentColor(value);
                return (
                  <MenuItem key={value.toString()} value={value}>
                    <Chip
                      label={value ? "Required" : "Not Required"}
                      size="small"
                      sx={{
                        backgroundColor: colors.bg,
                        color: colors.text,
                        mr: 1,
                      }}
                    />
                    {value ? "Required" : "Not Required"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        
          {/* Clear Filters Button */}
          <MDButton
            variant="outlined"
            color="error"
            onClick={handleClearFilters}
            startIcon={<ClearIcon />}
            sx={{
              height: "40px",
              marginLeft: "auto",
            }}
            disabled={!Object.values(filters).some((arr) => arr.length > 0) && !searchTerm}
          >
            Clear Filters
          </MDButton>
        </MDBox>
      </MDBox>

      <div style={{ height: 'calc(101vh - 310px)', width: '100%' }}>
        <DataGrid
          getRowId={(row) => row._id}
          rows={filteredTasks}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'dueDate', sort: 'asc' }],
            },
          }}
          disableRowSelectionOnClick
          autoHeight={false}
          sx={{
            maxHeight: "80vh",
            overflowY: "auto",
            "& .MuiDataGrid-cell:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
            "& .MuiDataGrid-cell": {
              padding: "8px",
            },
           
            "& .MuiDataGrid-footerContainer": {
              borderTop: "1px solid",
              borderColor: "divider",
              backgroundColor: "grey.50",
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{ p: 4, textAlign: "center", color: "#546E7A" }}>
                <AssignmentIcon sx={{ fontSize: 40, mb: 1, opacity: 0.5 }} />
                <MDTypography variant="body2">No assigned tasks available</MDTypography>
              </Box>
            ),
          }}
        />
      </div>
    </>
  );
}

// Add this helper function near getStatusColor
const getAttachmentColor = (isRequired) => {
  if (isRequired) {
    return { bg: '#E3F2FD', text: '#1976D2' }; // Light blue background
  }
  return { bg: '#ECEFF1', text: '#546E7A' }; // Light grey background
};