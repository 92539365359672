import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { baseUrl, apiV1, SURVEY_DASHBOARD_URL } from "utils/constants";
import Cookies from "universal-cookie";

function Survey() {
  const cookies = new Cookies();

  const handleSurveyClick = async () => {
    try {
      const token = cookies.get("token");
      window.location.href = `${SURVEY_DASHBOARD_URL}/auth/sso?token=${token}`;
    } catch (error) {
      console.error("Error accessing survey:", error);
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Survey Forms
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pb={3} px={3}>
                <MDTypography variant="body2" mb={3}>
                  Access and manage your BRSR surveys through our integrated survey dashboard.
                </MDTypography>
                <MDButton 
                  variant="gradient" 
                  color="info" 
                  onClick={handleSurveyClick}
                >
                  Open Survey Dashboard
                </MDButton>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Survey;
