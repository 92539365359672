import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedItems, theme) {
  if (!selectedItems) return { fontWeight: theme.typography.fontWeightRegular };
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// Helper function to get ID consistently
function getId(item) {
  return item._id || item.id;
}

export default function CommonTable({
  columns,
  rows,
  loading,
  filters = { companies: [], units: [], departments: [] },
  setFilters,
  filterOptions = { companies: [], units: [], departments: [] },
  onSearchChange,
  showCompanyFilter = false,
  showUnitFilter = false,
  showDepartmentFilter = false,
  setSelectedRows,
  pinnedColumns,
  page,
  setPage,
  rowCount,
}) {
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // Update pagination model when external page changes
  React.useEffect(() => {
    if (page) {
      setPaginationModel(prev => ({
        ...prev,
        page: page - 1 // DataGrid uses 0-based indexing
      }));
    }
  }, [page]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      newFilters[filterName] = value;

      // Clear dependent filters when parent filter changes
      if (filterName === 'companies') {
        newFilters.units = [];
        newFilters.departments = [];
      } else if (filterName === 'units') {
        newFilters.departments = [];
      }

      return newFilters;
    });
  };

  const handleClearFilters = () => {
    setFilters({
      companies: [],
      units: [],
      departments: [],
    });
  };

  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
    if (setPage) {
      setPage(newModel.page + 1); // Convert to 1-based indexing for API
    }
  };

  return (
    <>
      <MDBox p={2} display="flex" flexWrap="wrap" gap={2} alignItems="center" position="relative">
        {/* Search Bar */}
        <TextField
          placeholder="Search..."
          onChange={(e) => onSearchChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 270 }}
        />

        {/* Company Filter */}
        {showCompanyFilter && (
          <FormControl sx={{ m: 1, width: 270 }}>
            <InputLabel id="company-multiple-label">Company</InputLabel>
            <Select
              labelId="company-multiple-label"
              id="company-multiple"
              multiple
              value={filters.companies}
              onChange={(e) => handleFilterChange('companies', e.target.value)}
              input={<OutlinedInput label="Company" />}
              MenuProps={MenuProps}
              sx={{height: '40px'}}
              renderValue={(selected) => {
                const selectedNames = selected.map(id => {
                  const company = filterOptions.companies.find(c => getId(c) === id);
                  return company?.name || id;
                }).join(', ');
                return selectedNames;
              }}
            >
              {filterOptions.companies.map((company) => (
                <MenuItem
                  key={getId(company)}
                  value={getId(company)}
                  style={getStyles(getId(company), filters.companies, theme)}
                >
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Unit Filter */}
        {showUnitFilter && (
          <FormControl sx={{ m: 1, width: 270 }}>
            <InputLabel id="unit-multiple-label">Unit</InputLabel>
            <Select
              labelId="unit-multiple-label"
              id="unit-multiple"
              multiple
              value={filters.units}
              onChange={(e) => handleFilterChange('units', e.target.value)}
              input={<OutlinedInput label="Unit" />}
              MenuProps={MenuProps}
              sx={{height: '40px'}}
              disabled={!filters.companies.length}
              renderValue={(selected) => {
                const selectedNames = selected.map(id => {
                  const unit = filterOptions.units.find(u => getId(u) === id);
                  return unit?.name || id;
                }).join(', ');
                return selectedNames;
              }}
            >
              {filters.companies.length > 0 ? (
                filters.companies.map(companyId => {
                  const company = filterOptions.companies.find(c => getId(c) === companyId);
                  const companyUnits = filterOptions.units.filter(u => {
                    // Handle both nested and direct references
                    const unitCompanyId = u.company?._id || u.company?.id || u.company;
                    return unitCompanyId === companyId;
                  });
                  
                  return companyUnits.length > 0 ? (
                    <React.Fragment key={companyId}>
                      <MenuItem
                        disabled
                        sx={{
                          opacity: 1,
                          fontWeight: 'bold',
                          backgroundColor: 'background.default',
                          pointerEvents: 'none'
                        }}
                      >
                        {company?.name}
                      </MenuItem>
                      {companyUnits.map(unit => (
                        <MenuItem
                          key={getId(unit)}
                          value={getId(unit)}
                          style={getStyles(getId(unit), filters.units, theme)}
                          sx={{ pl: 4 }}
                        >
                          {unit.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              ) : (
                <MenuItem disabled>Please select a company first</MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        {/* Department Filter */}
        {showDepartmentFilter && (
          <FormControl sx={{ m: 1, width: 270 }}>
            <InputLabel id="department-multiple-label">Department</InputLabel>
            <Select
              labelId="department-multiple-label"
              id="department-multiple"
              multiple
              value={filters.departments}
              onChange={(e) => handleFilterChange('departments', e.target.value)}
              input={<OutlinedInput label="Department" />}
              MenuProps={MenuProps}
              sx={{height: '40px'}}
              disabled={!filters.units.length}
              renderValue={(selected) => {
                const selectedNames = selected.map(id => {
                  const dept = filterOptions.departments.find(d => getId(d) === id);
                  return dept?.name || id;
                }).join(', ');
                return selectedNames;
              }}
            >
              {filters.units.length > 0 ? (
                filters.units.map(unitId => {
                  const unit = filterOptions.units.find(u => getId(u) === unitId);
                  const unitDepartments = filterOptions.departments.filter(d => {
                    // Handle both nested and direct references
                    const deptUnitId = d.unit?._id || d.unit?.id || d.unit;
                    return deptUnitId === unitId;
                  });
                  
                  return unitDepartments.length > 0 ? (
                    <React.Fragment key={unitId}>
                      <MenuItem
                        disabled
                        sx={{
                          opacity: 1,
                          fontWeight: 'bold',
                          backgroundColor: 'background.default',
                          pointerEvents: 'none'
                        }}
                      >
                        {unit?.name}
                      </MenuItem>
                      {unitDepartments.map(dept => (
                        <MenuItem
                          key={getId(dept)}
                          value={getId(dept)}
                          style={getStyles(getId(dept), filters.departments, theme)}
                          sx={{ pl: 4 }}
                        >
                          {dept.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              ) : (
                <MenuItem disabled>Please select a unit first</MenuItem>
              )}
            </Select>
          </FormControl>
        )}

        {/* Clear Filters Button */}
        {(showCompanyFilter || showUnitFilter || showDepartmentFilter) && (
          <MDButton
            variant="outlined"
            color="error"
            onClick={handleClearFilters}
            startIcon={<ClearIcon />}
            sx={{
              height: "40px",
              marginLeft: "auto",
            }}
            disabled={!Object.values(filters).some((array) => array.length > 0)}
          >
            Clear All Filters
          </MDButton>
        )}
      </MDBox>

      <div
        style={{
          width: "100%",
          height: "calc(100vh - 340px)",
          maxHeight: "calc(100vh - 340px)",
          overflow: "auto",
        }}
      >
        {loading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => setSelectedRows?.(newSelection)}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[10, 25, 50, 100]}
            pagination
            paginationMode={setPage ? "server" : "client"}
            rowCount={rowCount || rows.length}
            filterMode="client"
            sortingMode="client"
            disableRowSelectionOnClick
            columnVisibilityModel={{}}
            pinnedColumns={pinnedColumns}
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                overflow: "auto !important",
              },
            }}
          />
        )}
      </div>
    </>
  );
}
