import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  CircularProgress,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

function TaskStatisticsTable({ data, loading }) {
  const [expandedCompanies, setExpandedCompanies] = useState({});
  const [expandedUnits, setExpandedUnits] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    companies: [],
    units: [],
    departments: [],
  });
  const [searchTerm, setSearchTerm] = useState("");

  const toggleCompany = (companyId) => {
    setExpandedCompanies((prev) => ({
      ...prev,
      [companyId]: !prev[companyId],
    }));
  };

  const toggleUnit = (companyId, unitId) => {
    setExpandedUnits((prev) => ({
      ...prev,
      [`${companyId}-${unitId}`]: !prev[`${companyId}-${unitId}`],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [filterName]: value };

      // Clear dependent filters
      if (filterName === "companies") {
        newFilters.units = [];
        newFilters.departments = [];
      } else if (filterName === "units") {
        newFilters.departments = [];
      }

      return newFilters;
    });
    setPage(0);
  };

  const handleClearFilters = () => {
    setFilters({
      companies: [],
      units: [],
      departments: [],
    });
    setSearchTerm("");
  };

  // Get available units based on selected companies
  const getAvailableUnits = () => {
    if (!data?.companies) return [];
    if (filters.companies.length === 0) {
      return (data.companies || [])
        .flatMap((company) => company?.units || [])
        .filter(unit => unit && unit.unitId);
    }
    return (data.companies || [])
      .filter((company) => company && filters.companies.includes(company.companyId))
      .flatMap((company) => company?.units || [])
      .filter(unit => unit && unit.unitId);
  };

  // Get available departments based on selected units
  const getAvailableDepartments = () => {
    const availableUnits = getAvailableUnits();
    if (filters.units.length === 0) {
      return availableUnits
        .flatMap((unit) => (unit?.departments || []))
        .filter((dept) => dept && dept.departmentId);
    }
    return availableUnits
      .filter((unit) => unit && filters.units.includes(unit.unitId))
      .flatMap((unit) => (unit?.departments || []))
      .filter((dept) => dept && dept.departmentId);
  };

  // Universal search function
  const searchInData = (item) => {
    const searchLower = searchTerm.toLowerCase();
    if (typeof item === "object") {
      return Object.values(item).some(
        (value) => typeof value === "string" && value.toLowerCase().includes(searchLower)
      );
    }
    return String(item).toLowerCase().includes(searchLower);
  };

  const filteredData = React.useMemo(() => {
    if (!data?.companies) return [];

    let filtered = [...(data.companies || [])].map((company) => ({
      ...company,
      units: company?.units || [],
    }));

    // Apply filters
    if (filters.companies.length > 0) {
      filtered = filtered.filter((company) => company && company.companyId && filters.companies.includes(company.companyId));
    }

    if (filters.units.length > 0) {
      filtered = filtered
        .map((company) => ({
          ...company,
          units: (company?.units || []).filter((unit) => unit && unit.unitId && filters.units.includes(unit.unitId)),
        }))
        .filter((company) => company.units.length > 0);
    }

    if (filters.departments.length > 0) {
      filtered = filtered
        .map((company) => ({
          ...company,
          units: (company?.units || [])
            .map((unit) => ({
              ...unit,
              departments: (unit?.departments || []).filter(
                (dept) => dept && dept.departmentId && filters.departments.includes(dept.departmentId)
              ),
            }))
            .filter((unit) => (unit?.departments || []).length > 0),
        }))
        .filter((company) => company.units.length > 0);
    }

    // Apply search globally across company, unit, and department levels
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();

      filtered = filtered
        .map((company) => {
          const companyMatch = company?.companyName?.toLowerCase().includes(searchLower);

          const filteredUnits = (company?.units || [])
            .map((unit) => {
              const unitMatch = unit?.unitName?.toLowerCase().includes(searchLower);

              const filteredDepartments = (unit?.departments || []).filter((dept) =>
                dept?.departmentName?.toLowerCase().includes(searchLower)
              );

              return unitMatch || filteredDepartments.length > 0
                ? { ...unit, departments: filteredDepartments }
                : null;
            })
            .filter(Boolean);

          return companyMatch || filteredUnits.length > 0
            ? { ...company, units: filteredUnits }
            : null;
        })
        .filter(Boolean);
    }

    return filtered;
  }, [data, filters, searchTerm]);

  const tableHeaders = [
    { id: "name", label: "Name", width: "40%" },
    { id: "pending", label: "Pending", width: "15%" },
    { id: "drafted", label: "Saved as Draft", width: "15%" },
    { id: "completed", label: "Completed", width: "15%" },
    { id: "total", label: "Total", width: "15%" },
  ];

  if (loading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <Box sx={{ width: "100%", maxHeight: "80vh", overflow: "auto" }}>
      <MDBox px={3} py={1} pb={3} display="flex" flexWrap="wrap" gap={2} alignItems="center">
        <TextField
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 270 }}
        />

        <FormControl sx={{ width: 270 }}>
          <InputLabel>Company</InputLabel>
          <Select
            multiple
            value={filters.companies}
            onChange={(e) => handleFilterChange("companies", e.target.value)}
            label="Company"
            sx={{ height: "40px" }}
          >
            {data?.companies?.map((company) => (
              <MenuItem key={company.companyId} value={company.companyId}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: 270 }}>
          <InputLabel>Unit</InputLabel>
          <Select
            multiple
            value={filters.units}
            onChange={(e) => handleFilterChange("units", e.target.value)}
            label="Unit"
            disabled={filters.companies.length === 0}
            sx={{ height: "40px" }}
          >
            {getAvailableUnits().map((unit) => (
              <MenuItem key={unit.unitId} value={unit.unitId}>
                {unit.unitName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: 270 }}>
          <InputLabel>Department</InputLabel>
          <Select
            multiple
            value={filters.departments}
            onChange={(e) => handleFilterChange("departments", e.target.value)}
            label="Department"
            disabled={filters.units.length === 0}
            sx={{ height: "40px" }}
          >
            {getAvailableDepartments().map((dept) => (
              <MenuItem key={dept.departmentId} value={dept.departmentId}>
                {dept.departmentName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <MDButton
          variant="outlined"
          color="error"
          onClick={handleClearFilters}
          startIcon={<ClearIcon />}
          sx={{ marginLeft: "auto" }}
          disabled={!Object.values(filters).some((array) => array.length > 0) && !searchTerm}
        >
          Clear Filters
        </MDButton>
      </MDBox>

      <TableContainer sx={{ maxHeight: "calc(100vh - 340px)" }}>
        <Table stickyHeader>
          <TableBody>
            {/* Header Row */}
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell
                  key={header.id}
                  align={header.id === "name" ? "left" : "center"}
                  sx={{
                    width: header.width,
                    backgroundColor: "background.paper",
                    fontWeight: "bold",
                    borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>

            {/* Data Rows */}
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((company) => (
                <React.Fragment key={company?.companyId || "unknown"}>
                  {/* Company Row */}
                  <TableRow
                    hover
                    onClick={() => toggleCompany(company?.companyId)}
                    sx={{ cursor: "pointer", backgroundColor: "background.light" }}
                  >
                    <TableCell>
                      <MDBox display="flex" alignItems="center">
                        <IconButton size="small" sx={{ mr: 1 }}>
                          {expandedCompanies[company?.companyId] ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowRightIcon />
                          )}
                        </IconButton>
                        <MDTypography variant="subtitle2" fontWeight="medium">
                          {company?.companyName || "Unnamed Company"}
                        </MDTypography>
                      </MDBox>
                    </TableCell>
                    <TableCell align="center">{company?.pending || 0}</TableCell>
                    <TableCell align="center">{company?.drafted || 0}</TableCell>
                    <TableCell align="center">{company?.completed || 0}</TableCell>
                    <TableCell align="center">{company?.total || 0}</TableCell>
                  </TableRow>

                  {/* Expanded Units */}
                  {expandedCompanies[company?.companyId] &&
                    (company?.units || []).map((unit) => (
                      <React.Fragment key={unit?.unitId || "unknown"}>
                        <TableRow
                          hover
                          onClick={() => toggleUnit(company?.companyId, unit?.unitId)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell>
                            <MDBox display="flex" alignItems="center" ml={4}>
                              <IconButton size="small" sx={{ mr: 1 }}>
                                {expandedUnits[`${company?.companyId}-${unit?.unitId}`] ? (
                                  <KeyboardArrowDownIcon />
                                ) : (
                                  <KeyboardArrowRightIcon />
                                )}
                              </IconButton>
                              <MDTypography variant="body2">
                                {unit?.unitName || "Unnamed Unit"}
                              </MDTypography>
                            </MDBox>
                          </TableCell>
                          <TableCell align="center">{unit?.pending || 0}</TableCell>
                          <TableCell align="center">{unit?.drafted || 0}</TableCell>
                          <TableCell align="center">{unit?.completed || 0}</TableCell>
                          <TableCell align="center">{unit?.total || 0}</TableCell>
                        </TableRow>

                        {/* Expanded Departments */}
                        {expandedUnits[`${company?.companyId}-${unit?.unitId}`] &&
                          (unit?.departments || []).map((department) => (
                            <TableRow
                              key={department?.departmentId || "unknown"}
                              sx={{ backgroundColor: "background.default" }}
                            >
                              <TableCell>
                                <MDBox ml={8}>
                                  <MDTypography variant="body2">
                                    {department?.departmentName || "Unnamed Department"}
                                  </MDTypography>
                                </MDBox>
                              </TableCell>
                              <TableCell align="center">{department?.pending || 0}</TableCell>
                              <TableCell align="center">{department?.drafted || 0}</TableCell>
                              <TableCell align="center">{department?.completed || 0}</TableCell>
                              <TableCell align="center">{department?.total || 0}</TableCell>
                            </TableRow>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default TaskStatisticsTable;
