import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  CircularProgress,
  IconButton,
  InputAdornment,
  Chip,
  Backdrop,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";

const StyledTableCell = ({ children, ...props }) => (
  <TableCell
    {...props}
    sx={{
      fontSize: "0.875rem",
      padding: "16px",
      borderBottom: "1px solid",
      borderColor: "divider",
      "&.MuiTableCell-head": {
        backgroundColor: "background.paper",
        fontWeight: 600,
      },
    }}
  >
    {children}
  </TableCell>
);

const DownloadWordModal = ({ open, onClose }) => {
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadingId, setDownloadingId] = useState(null);
  const [downloadingBackdrop, setDownloadingBackdrop] = useState(false);

  const cookies = new Cookies();
  const dispatch = useDispatch();

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}${apiV1}/companies`, {
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      });

      if (Array.isArray(response.data)) {
        setCompanies(response.data);
      } else {
        setCompanies([]);
        dispatch(
          setAlert({
            message: "No companies found",
            type: "info",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Error fetching companies",
          type: "error",
        })
      );
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCompanies();
    }
  }, [open]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredCompanies = companies?.length
    ? companies.filter((company) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          company.name?.toLowerCase().includes(searchLower) ||
          company.location?.toLowerCase().includes(searchLower) ||
          company.description?.toLowerCase().includes(searchLower)
        );
      })
    : [];

  const paginatedCompanies = filteredCompanies.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleDownload = async (companyId, companyName) => {
    if (!companyId) {
      dispatch(
        setAlert({
          message: "Invalid company selected",
          type: "error",
        })
      );
      return;
    }

    try {
      setDownloadingBackdrop(true);
      setDownloadingId(companyId);

      const response = await axios.get(`${baseUrl}${apiV1}/tasks/${companyId}/generate-word`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      if (blob.size < 1024) {
        dispatch(
          setAlert({
            message: "The generated document is empty. Downloading anyway.",
            type: "warning",
          })
        );
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const formattedCompanyName = companyName
        .replace(/[^a-zA-Z0-9]/g, "-")
        .replace(/-+/g, "-")
        .replace(/-$/, "")
        .toLowerCase();

      link.setAttribute("download", `${formattedCompanyName}-BRSR.docx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      dispatch(
        setAlert({
          message: "Document downloaded successfully",
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error downloading document:", error);
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Error downloading document",
          type: "error",
        })
      );
    } finally {
      setDownloadingId(null);
      setDownloadingBackdrop(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxHeight: "80vh",
          },
        }}
      >
        <DialogTitle sx={{ p: 3 }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox display="flex" alignItems="center" gap={1}>
              <BusinessIcon sx={{ fontSize: 24, color: "info.main" }} />
              <MDTypography variant="h6" fontWeight="medium">
                Download Company Reports
              </MDTypography>
            </MDBox>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </MDBox>
        </DialogTitle>

        <DialogContent sx={{ p: 3 }}>
          <MDBox mb={3}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search companies..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "text.secondary" }} />
                  </InputAdornment>
                ),
              }}
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: 2 } }}
            />
          </MDBox>

          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Table>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center" sx={{ py: 6 }}>
                      <CircularProgress size={32} />
                    </StyledTableCell>
                  </TableRow>
                ) : paginatedCompanies.length === 0 ? (
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center" sx={{ py: 6 }}>
                      <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
                        <BusinessIcon
                          sx={{ fontSize: 48, color: "text.secondary", opacity: 0.5 }}
                        />
                        <MDTypography variant="body2" color="text.secondary">
                          No companies found
                        </MDTypography>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  paginatedCompanies.map((company) => (
                    <TableRow
                      key={company._id}
                      sx={{ "&:hover": { backgroundColor: "action.hover" } }}
                    >
                      <StyledTableCell>
                        <MDTypography variant="body2" fontWeight="medium">
                          {company.name}
                        </MDTypography>
                        <MDTypography variant="caption" color="text.secondary">
                          {company.description}
                        </MDTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={company.location}
                          size="small"
                          sx={{
                            backgroundColor: "grey.100",
                            "& .MuiChip-label": { px: 1 },
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <MDBox display="flex" gap={0.5} flexWrap="wrap">
                          {company.units?.slice(0, 2).map((unit) => (
                            <Chip
                              key={unit.id}
                              label={unit.name}
                              size="small"
                              sx={{
                                backgroundColor: "primary.lighter",
                                color: "primary.main",
                                "& .MuiChip-label": { px: 1 },
                              }}
                            />
                          ))}
                          {company.units?.length > 2 && (
                            <Chip
                              label={`+${company.units.length - 2}`}
                              size="small"
                              sx={{
                                backgroundColor: "grey.100",
                                "& .MuiChip-label": { px: 1 },
                              }}
                            />
                          )}
                        </MDBox>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <MDButton
                          variant="contained"
                          color="info"
                          size="small"
                          onClick={() => handleDownload(company._id, company.name)}
                          disabled={downloadingId === company._id}
                          startIcon={<Icon>download</Icon>}
                          sx={{
                            minWidth: "auto",
                            px: 2,
                            py: 1,
                            borderRadius: 1.5,
                          }}
                        >
                          {downloadingId === company._id ? "Downloading..." : "Download"}
                        </MDButton>
                      </StyledTableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredCompanies.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            sx={{
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          />
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
          flexDirection: "column",
          gap: 2,
        })}
        open={downloadingBackdrop}
      >
        <CircularProgress color="inherit" />
        <MDTypography color="white" variant="h6">
          Downloading document...
        </MDTypography>
      </Backdrop>
    </>
  );
};

export default DownloadWordModal;
