import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";

function AddCompany({
  open,
  setOpen,
  saveData,
  setSaveData,
  onSuccessPost,
  modalType,
  selectedCompany,
}) {
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    description: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const cookies = new Cookies();

  useEffect(() => {
    if (modalType === "Edit" && selectedCompany) {
      setFormData({
        name: selectedCompany.name || "",
        location: selectedCompany.location || "",
        description: selectedCompany.description || "",
      });
    } else {
      resetForm();
    }
  }, [modalType, selectedCompany, open]);

  const resetForm = () => {
    setFormData({
      name: "",
      location: "",
      description: "",
    });
    setErrors({});
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Company name is required";
    }
    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const submitData = {
      ...formData,
      description: formData.description || undefined,
    };

    try {
      if (modalType === "Edit") {
        const res = await axios.put(
          `${baseUrl}${apiV1}/companies/${selectedCompany.id}`,
          submitData,
          { headers }
        );
        if (res.status === 200) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `Company updated successfully!`, color: "success" }));
        }
      } else {
        const res = await axios.post(`${baseUrl}${apiV1}/companies`, submitData, { headers });
        if (res.status === 201) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `Company created successfully!`, color: "success" }));
        }
      }
    } catch (e) {
      console.error("Error saving company:", e);
      if (e.response && e.response.data) {
        const backendErrors = e.response.data.errors;
        if (backendErrors) {
          const formattedErrors = {};
          backendErrors.forEach((err) => {
            formattedErrors[err.field] = err.message;
          });
          setErrors(formattedErrors);
          dispatch(setAlert({ message: e.response.data.message, color: "error" }));
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {modalType === "Edit" ? "Edit Company" : "Add Company"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Company Name"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="location"
              label="Location"
              fullWidth
              variant="outlined"
              value={formData.location}
              onChange={handleChange}
              error={Boolean(errors.location)}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleSubmit} color="info">
          {modalType === "Edit" ? "Update" : "Save"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddCompany;
