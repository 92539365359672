import { useState } from "react";
import { ReasonPhrases, StatusCodes } from "http-status-codes";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl, apiV1 } from "utils/constants";
import axios from "axios";
import Cookies from "universal-cookie";

const useGetQuestionAttributes = () => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };

  const handleGetAttributes = async ({
    attributeType,
    onSuccess = null,
    onError = null,
  }) => {
    const validAttributes = ["sections", "parts", "departments", "groups"];
    if (!validAttributes.includes(attributeType)) {
      dispatch(
        setAlert({
          message: "Invalid attribute type",
          color: "error",
        })
      );
      return;
    }

    try {
      setLoading(true);
      const res = await axios.get(
        `${baseUrl}${apiV1}/questions/${attributeType}`,
        { headers }
      );

      if (res.status === StatusCodes.OK) {
        onSuccess && onSuccess(res.data);
      } else {
        setLoading(false);
        dispatch(
          setAlert({
            message: res?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        setAlert({
          message: e.response?.data?.error?.message || ReasonPhrases.INTERNAL_SERVER_ERROR,
          color: "error",
        })
      );
      onError && onError();
    } finally {
      setLoading(false);
    }
  };

  return { loading, handleGetAttributes };
};

export default useGetQuestionAttributes;