import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { setUser } from "reduxToolkit/user/userSlice";
import Cookies from "universal-cookie";
import { apiV1, baseUrl } from "utils/constants";
import { ReasonPhrases, StatusCodes } from "http-status-codes";

const useLogin = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleLogin = async ({ email, password, onSuccess = null, onError = null }) => {
    try {
      console.log({ email, password });
      setLoading(true);
      const res = await axios.post(baseUrl + apiV1 + "/auth/login", {
        email: email,
        password: password,
      });
      
      console.log("response", res);
      
      if (res.status === 200 || res.data.statusCode === 200) {
        // Handle the updated response format
        const { user, tokens } = res.data;
        
        // Set access token
        cookies.set("token", tokens.access.token, {
          path: "/",
          expires: new Date(tokens.access.expires),
        });
        
        // Set refresh token (optional)
        cookies.set("refreshToken", tokens.refresh.token, {
          path: "/",
          expires: new Date(tokens.refresh.expires),
        });
        
        // Set access level
        cookies.set("accessLevel", user.role.accessLevel, {
          path: "/",
          expires: new Date(tokens.access.expires),
        });

        dispatch(setAlert({ message: "Login Success", color: "success" }));
        dispatch(setUser(user));
        onSuccess && onSuccess(user.role.accessLevel);
      } else {
        dispatch(
          setAlert({
            message: res?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        onError && onError();
      }
    } catch (e) {
      console.log("error", e);
      dispatch(
        setAlert({
          message: e.response?.data?.message || "Failed to login",
          color: "error",
        })
      );
      onError && onError();
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleLogin,
  };
};

export default useLogin;