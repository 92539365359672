import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  OutlinedInput,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { useEffect, useState, useMemo } from "react";
import Cookies from "universal-cookie";
import MDTypography from "components/MDTypography";
import { useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedItems, theme) {
  if (!selectedItems) return { fontWeight: theme.typography.fontWeightRegular };
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelectTable({
  saveData,
  setSelectedQuestion,
  setTask,
  setIsUnassignModalOpen,
  customquestion,
  setCustomquestion,
  task,
  selectedQuestion,
  onViewResponses,
}) {
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    companies: [],
    units: [],
    departments: [],
  });

  // Transform the data structure for the table
  const transformedData = useMemo(() => {
    if (!Array.isArray(saveData)) return [];

    return saveData.flatMap((company) =>
      (company.questions || []).map((questionData) => ({
        _id: `${company._id}-${questionData.questionId}`,
        companyName: company.companyName,
        company: company.companyDetails,
        question: questionData.question,
        tasks: questionData.tasks,
        unit: {
          ...questionData.tasks?.[0]?.unit?.[0],
          company: company.companyDetails
        },
        department: {
          ...questionData.tasks?.[0]?.department?.[0],
          unit: questionData.tasks?.[0]?.unit?.[0]
        },
        assignee: questionData.tasks?.map((task) => task.assignee) || [],
      }))
    );
  }, [saveData]);

  // Get unique companies, units, and departments for filters
  const filterOptions = useMemo(() => {
    const uniqueCompanies = [];
    const uniqueUnits = [];
    const uniqueDepartments = [];

    transformedData.forEach((row) => {
      // Add company if not already present
      if (row.company && !uniqueCompanies.find(c => c._id === row.company._id)) {
        uniqueCompanies.push(row.company);
      }
      
      // Add unit if not already present and has required data
      if (row.unit?._id && !uniqueUnits.find(u => u._id === row.unit._id)) {
        uniqueUnits.push({
          ...row.unit,
          company: row.company // Ensure company reference is maintained
        });
      }
      
      // Add department if not already present and has required data
      if (row.department?._id && !uniqueDepartments.find(d => d._id === row.department._id)) {
        uniqueDepartments.push({
          ...row.department,
          unit: row.unit // Ensure unit reference is maintained
        });
      }
    });

    return {
      companies: uniqueCompanies,
      units: uniqueUnits,
      departments: uniqueDepartments,
    };
  }, [transformedData]);

  // Filter the data based on search and filters
  const filteredData = useMemo(() => {
    return transformedData.filter((row) => {
      // Search filter
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        const matchesSearch =
          row.question?.questionText?.toLowerCase().includes(searchLower) ||
          row.company?.name?.toLowerCase().includes(searchLower) ||
          row.unit?.name?.toLowerCase().includes(searchLower) ||
          row.department?.name?.toLowerCase().includes(searchLower);

        if (!matchesSearch) return false;
      }

      // Company filter
      if (filters.companies.length > 0) {
        if (!row.company || !filters.companies.includes(row.company._id)) {
          return false;
        }
      }

      // Unit filter
      if (filters.units.length > 0) {
        if (!row.unit || !filters.units.includes(row.unit._id)) {
          return false;
        }
      }

      // Department filter
      if (filters.departments.length > 0) {
        if (!row.department || !filters.departments.includes(row.department._id)) {
          return false;
        }
      }

      return true;
    });
  }, [transformedData, searchTerm, filters]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      newFilters[filterName] = value;

      // Clear dependent filters when parent filter changes
      if (filterName === "companies") {
        newFilters.units = [];
        newFilters.departments = [];
      } else if (filterName === "units") {
        newFilters.departments = [];
      }

      return newFilters;
    });
  };

  const handleClearFilters = () => {
    setFilters({
      companies: [],
      units: [],
      departments: [],
    });
    setSearchTerm("");
  };

  const isAssigneeView = useMemo(() => {
    return transformedData.some(
      (row) =>
        Array.isArray(row.assignee) && row.assignee.some((assignee) => assignee?._id === userId)
    );
  }, [transformedData, userId]);

  const handleQuestionClick = async (question, tasks) => {
    if (!question || !tasks) return;

    console.log("MultiSelectTable - handleQuestionClick:", {
      question,
      tasks,
    });

    setSelectedQuestion(question);
    setTask(tasks[0]); // Set first task by default
  };

  const columns = isAssigneeView
    ? [
        {
          field: "question",
          headerName: "Question",
          minWidth: 700,
          flex: 1,
          renderCell: (params) => (
            <Button
              // onClick={() => handleQuestionClick(params.row.question, params.row.tasks)}
              // color="primary"
              sx={{
                textAlign: "left",
                justifyContent: "flex-start",
                width: "100%",
                textTransform: "none",
                padding: "8px",
                // "&:hover": {
                //   backgroundColor: "rgba(0, 0, 0, 0.04)",
                // },
              }}
            >
              {params.row.question?.questionText || "No question text"}
            </Button>
          ),
        },
      ]
    : [
        {
          field: "question",
          headerName: "Question",
          minWidth: 700,
          flex: 1,
          renderCell: (params) => (
            <Button
              // onClick={() => handleQuestionClick(params.row.question, params.row.tasks)}
              // color="primary"
              sx={{
                textAlign: "left",
                justifyContent: "flex-start",
                width: "100%",
                textTransform: "none",
                padding: "8px",
                // "&:hover": {
                //   backgroundColor: "rgba(0, 0, 0, 0.04)",
                // },
              }}
            >
              {params.row.question?.questionText || "No question text"}
            </Button>
          ),
        },

        {
          field: "companyName",
          headerName: "Company",
          width: 150,
          renderCell: (params) => <div>{params.row.companyName || "N/A"}</div>,
        },
        {
          field: "unit",
          headerName: "Unit",
          width: 150,
          renderCell: (params) => <div>{params.row.unit?.name || "N/A"}</div>,
        },
        {
          field: "department",
          headerName: "Department",
          width: 150,
          renderCell: (params) => <div>{params.row.department?.name || "N/A"}</div>,
        },
        {
          field: "assignees",
          headerName: "Assigned To",
          width: 200,
          renderCell: (params) => (
            <MDBox display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <MDBox 
                onClick={() => {
                  console.log("View responses clicked for row:", params.row);
                  onViewResponses(params.row);
                }} 
                display="flex" 
                gap={1}
              >
                {Array.isArray(params.row.assignee) &&
                  params.row.assignee.map((assignee) => (
                    <Tooltip key={assignee._id} title={assignee.name}>
                      <Avatar
                        sx={{
                          bgcolor: "#1976d2",
                          width: 25,
                          height: 25,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {assignee.name.charAt(0)}
                      </Avatar>
                    </Tooltip>
                  ))}
              </MDBox>
            </MDBox>
          ),
        },
      ];

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const fetchTasks = async () => {
      const res = await axios.get(
        baseUrl + apiV1 + `/custom?type=${task?.question?.parentQuestion}`,
        {
          headers,
        }
      );

      setCustomquestion(res.data?.tasks[0]);
    };

    if (task?.question?.parentQuestion) {
      fetchTasks();
    }
  }, [task]);

  return (
    <>
      <MDBox p={3}>
        <MDBox display="flex" flexWrap="wrap" gap={2} alignItems="center">
          {/* Search Bar */}
          <TextField
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchTerm("")}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: 270 }}
          />

          {/* Company Filter */}
          <FormControl sx={{ width: 270 }}>
            <InputLabel id="company-multiple-label">Company</InputLabel>
            <Select
              labelId="company-multiple-label"
              id="company-multiple"
              multiple
              value={filters.companies}
              onChange={(e) => handleFilterChange("companies", e.target.value)}
              input={<OutlinedInput label="Company" />}
              MenuProps={MenuProps}
              sx={{ height: "40px" }}
              renderValue={(selected) => {
                const selectedNames = selected
                  .map((id) => filterOptions.companies.find((c) => c._id === id)?.name || id)
                  .join(", ");
                return selectedNames;
              }}
            >
              {filterOptions.companies.map((company) => (
                <MenuItem
                  key={company._id}
                  value={company._id}
                  style={getStyles(company._id, filters.companies, theme)}
                >
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Unit Filter */}
          <FormControl sx={{ width: 270 }}>
            <InputLabel>Unit</InputLabel>
            <Select
              labelId="unit-multiple-label"
              id="unit-multiple"
              multiple
              value={filters.units}
              onChange={(e) => handleFilterChange("units", e.target.value)}
              input={<OutlinedInput label="Unit" />}
              MenuProps={MenuProps}
              disabled={!filters.companies.length && !filterOptions.units.length}
              sx={{ height: "40px" }}
              renderValue={(selected) => selected.map(id => 
                filterOptions.units.find(u => u._id === id)?.name || id
              ).join(', ')}
            >
              {filters.companies.length > 0 ? (
                // Show units for selected companies
                filters.companies.map(companyId => {
                  const company = filterOptions.companies.find(c => c._id === companyId);
                  const companyUnits = filterOptions.units.filter(u => u.company?._id === companyId);
                  
                  return companyUnits.length > 0 ? (
                    <React.Fragment key={companyId}>
                      <MenuItem
                        disabled
                        sx={{
                          opacity: 1,
                          fontWeight: 'bold',
                          backgroundColor: 'background.default',
                          pointerEvents: 'none'
                        }}
                      >
                        {company?.name}
                      </MenuItem>
                      {companyUnits.map(unit => (
                        <MenuItem
                          key={unit._id}
                          value={unit._id}
                          style={getStyles(unit._id, filters.units, theme)}
                          sx={{ pl: 4 }}
                        >
                          {unit.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              ) : (
                // Show all units grouped by companies
                filterOptions.companies.map(company => {
                  const companyUnits = filterOptions.units.filter(u => u.company?._id === company._id);
                  
                  return companyUnits.length > 0 ? (
                    <React.Fragment key={company._id}>
                      <MenuItem
                        disabled
                        sx={{
                          opacity: 1,
                          fontWeight: 'bold',
                          backgroundColor: 'background.default',
                          pointerEvents: 'none'
                        }}
                      >
                        {company.name}
                      </MenuItem>
                      {companyUnits.map(unit => (
                        <MenuItem
                          key={unit._id}
                          value={unit._id}
                          style={getStyles(unit._id, filters.units, theme)}
                          sx={{ pl: 4 }}
                        >
                          {unit.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              )}
            </Select>
          </FormControl>

          {/* Department Filter */}
          <FormControl sx={{ width: 270 }}>
            <InputLabel>Department</InputLabel>
            <Select
              labelId="department-multiple-label"
              id="department-multiple"
              multiple
              value={filters.departments}
              onChange={(e) => handleFilterChange("departments", e.target.value)}
              input={<OutlinedInput label="Department" />}
              MenuProps={MenuProps}
              disabled={!filters.units.length && !filterOptions.departments.length}
              sx={{ height: "40px" }}
              renderValue={(selected) => selected.map(id => 
                filterOptions.departments.find(d => d._id === id)?.name || id
              ).join(', ')}
            >
              {filters.units.length > 0 ? (
                // Show departments for selected units
                filters.units.map(unitId => {
                  const unit = filterOptions.units.find(u => u._id === unitId);
                  const unitDepartments = filterOptions.departments.filter(d => d.unit?._id === unitId);
                  
                  return unitDepartments.length > 0 ? (
                    <React.Fragment key={unitId}>
                      <MenuItem
                        disabled
                        sx={{
                          opacity: 1,
                          fontWeight: 'bold',
                          backgroundColor: 'background.default',
                          pointerEvents: 'none'
                        }}
                      >
                        {unit?.name}
                      </MenuItem>
                      {unitDepartments.map(dept => (
                        <MenuItem
                          key={dept._id}
                          value={dept._id}
                          style={getStyles(dept._id, filters.departments, theme)}
                          sx={{ pl: 4 }}
                        >
                          {dept.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              ) : (
                // Show all departments grouped by units
                filterOptions.units.map(unit => {
                  const unitDepartments = filterOptions.departments.filter(d => d.unit?._id === unit._id);
                  
                  return unitDepartments.length > 0 ? (
                    <React.Fragment key={unit._id}>
                      <MenuItem
                        disabled
                        sx={{
                          opacity: 1,
                          fontWeight: 'bold',
                          backgroundColor: 'background.default',
                          pointerEvents: 'none'
                        }}
                      >
                        {unit.name}
                      </MenuItem>
                      {unitDepartments.map(dept => (
                        <MenuItem
                          key={dept._id}
                          value={dept._id}
                          style={getStyles(dept._id, filters.departments, theme)}
                          sx={{ pl: 4 }}
                        >
                          {dept.name}
                        </MenuItem>
                      ))}
                    </React.Fragment>
                  ) : null;
                })
              )}
            </Select>
          </FormControl>

          <MDButton
            variant="outlined"
            color="error"
            onClick={handleClearFilters}
            startIcon={<ClearIcon />}
            sx={{
              height: "40px",
              marginLeft: "auto",
            }}
            disabled={!Object.values(filters).some((arr) => arr.length > 0) && !searchTerm}
          >
            Clear Filters
          </MDButton>
        </MDBox>
      </MDBox>

      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 310px)",
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "rgba(0, 0, 0, 0.02)",
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <Box sx={{ p: 4, textAlign: "center", color: "#546E7A" }}>
                <MDTypography variant="body2">No tasks available</MDTypography>
              </Box>
            ),
          }}
        />
      </Box>
    </>
  );
}
