import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Box,
  TextField,
  Typography,
  CircularProgress,
  IconButton
} from '@mui/material';
import MDButton from "components/MDButton";
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

const AttachmentUploadModal = ({ open, onClose, onUpload, loading, currentAttachmentCount = 0 }) => {
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    }
  });

  const handleSubmit = () => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    });
    formData.append('description', description);
    onUpload(formData);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            Upload Attachments
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
              {currentAttachmentCount}/25 attachments used
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box 
          {...getRootProps()} 
          sx={{
            border: '2px dashed #ccc',
            borderRadius: 2,
            p: 3,
            mb: 2,
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
              borderColor: 'primary.main'
            }
          }}
        >
          <input {...getInputProps()} />
          <CloudUploadIcon sx={{ fontSize: 40, color: 'text.secondary', mb: 1 }} />
          <Typography>
            Drag and drop files here, or click to select files
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Supported formats: PDF, DOC, DOCX
          </Typography>
        </Box>

        {files.length > 0 && (
          <Box mb={2}>
            <Typography variant="subtitle2" gutterBottom>
              Selected files:
            </Typography>
            {files.map((file, index) => (
              <Typography key={index} variant="body2" color="text.secondary">
                {file.name}
              </Typography>
            ))}
          </Box>
        )}

        <TextField
          fullWidth
          multiline
          rows={3}
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="secondary">
          Cancel
        </MDButton>
        <MDButton 
          onClick={handleSubmit} 
          color="info" 
          disabled={files.length === 0 || loading || currentAttachmentCount >= 25}
        >
          {loading ? <CircularProgress size={24} /> : 'Upload'}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentUploadModal; 