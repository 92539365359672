import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextArea from "layouts/questionsList/textarea";
import TableComponent from "layouts/questionsList/table";
import { Button, IconButton, Input } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";
import AttachmentUploadModal from './AttachmentUploadModal';
import { Chip, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DocumentViewer from 'layouts/documentLibrary/components/DocumentViewer';
import { getFileType } from 'layouts/documentLibrary/utils/documentUtils';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const dialogStyle = {
  // minHeight: "80vh",
  width: "100%",
  // padding: "40px",
  margin: "0 auto",
};

const dialogActionsStyle = {
  display: "flex",
  justifyContent: "space-between",  
  margin: "20px 0",
};

const AddAnswer = ({
  setCustomquestion,
  setSelectedQuestion,
  task,
  selectedQuestion,
  updateAnswer,
  isFields = false,
  setTask,
  onEnterData,
}) => {
  
  const [answers, setAnswers] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [documentUrl, setDocumentUrl] = useState("");
  const [viewerError, setViewerError] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const fetchCustomTasks = async () => {
    if (!selectedQuestion?.parentQuestion) {
      console.error('No parent question ID found');
      return;
    }

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const res = await axios.get(
        baseUrl + apiV1 + `/custom?type=${selectedQuestion.parentQuestion}`,
        { headers }
      );

      if (res.data?.tasks?.[0]) {
        setCustomquestion(res.data.tasks[0]);
      } else {
        dispatch(setAlert({ message: "No custom question found", color: "error" }));
      }
    } catch (error) {
      console.error("Error fetching custom tasks:", error);
      dispatch(setAlert({ 
        message: error.response?.data?.message || "Failed to fetch custom question", 
        color: "error" 
      }));
    }
  };

  useEffect(() => {

    
    if (task?.customanswer) {
      setAnswers(task.customanswer?.answerText);
    } else if (task?.answer) {
      setAnswers(task.answer?.answerText);
    } else {
      setAnswers([]);
    }
    setSelectedFiles([]);
  }, [task]);

  const handleSubmit = async (bypassEnterData = false) => {
    if (!selectedQuestion?.parentQuestion || bypassEnterData) {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      try {
        const questionId = selectedQuestion?._id || selectedQuestion?.id;
        if (!questionId) {
          console.error('No question ID found:', selectedQuestion);
          return;
        }

        const res = await axios.post(
          `${baseUrl}${apiV1}/answers/${questionId}`,
          {
            taskId: task._id,
            answerText: answers,
          },
          { headers }
        );

        if (res.status === 201) {
          dispatch(setAlert({ message: `Answer recorded successfully!`, color: "success" }));
          setSelectedQuestion(null);
          updateAnswer(res.data);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        dispatch(setAlert({ 
          message: error.response?.data?.message || "Failed to submit answer", 
          color: "error" 
        }));
      }
    } else {
      onEnterData?.();
    }
  };

  const handleDraft = async () => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(
        baseUrl +
          apiV1 +
          `/answers/${selectedQuestion._id ? selectedQuestion._id : selectedQuestion.id}`,
        {
          taskId: task._id,
          answerText: answers,
          isDraft: true,
        },
        { headers }
      );

      if (res.status === 201) {
        dispatch(setAlert({ message: `Answer drafted successfully!`, color: "success" }));
        updateAnswer(res.data);
        setSelectedQuestion(null);
      }
    } catch (e) {
      console.log({ message: e });
    }
  };

  const handleUpload = async (formData) => {
    try {
      if (!checkAttachmentLimit()) {
        return;
      }
      
      setUploadLoading(true);
      const res = await axios.post(
        `${baseUrl}${apiV1}/attachments/${task._id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      if (res.status === 201) {
        dispatch(setAlert({ message: "Attachment uploaded successfully", color: "success" }));
        setUploadModalOpen(false);
        // Refresh task data
        const updatedTask = await fetchTaskDetails(task._id);
        updateAnswer(updatedTask);
      }
    } catch (error) {
      dispatch(setAlert({ 
        message: error.response?.data?.message || "Failed to upload attachment", 
        color: "error" 
      }));
    } finally {
      setUploadLoading(false);
    }
  };

  const handleDownload = (attachment) => {
    window.open(`${baseUrl}${apiV1}/attachments/${attachment._id}/download`);
  };

  const handleView = async (attachment) => {
    setSelectedAttachment(attachment);
    setViewerError(false);
    setViewerOpen(true);

    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
      };
      const response = await fetch(
        `${baseUrl}${apiV1}/attachments/${attachment._id}/view`,
        { headers }
      );
      
      if (!response.ok) throw new Error('Failed to fetch document');
      
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setDocumentUrl(url);
    } catch (error) {
      console.error("Error viewing document:", error);
      setViewerError(true);
    }
  };

  const getFileIcon = (filename) => {
    const fileType = getFileType(filename);
    switch (fileType) {
      case 'pdf':
        return <PictureAsPdfIcon sx={{ color: '#F40F02' }} />;
      case 'image':
        return <ImageIcon sx={{ color: '#00C853' }} />;
      default:
        return <InsertDriveFileIcon sx={{ color: '#1565C0' }} />;
    }
  };

  const fetchTaskDetails = async (taskId) => {
    const res = await axios.get(
      `${baseUrl}${apiV1}/tasks/${taskId}/details`,
      {
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        }
      }
    );
    return res.data;
  };

  const handleDelete = async (attachmentId) => {
    try {
      const res = await axios.delete(
        `${baseUrl}${apiV1}/attachments/${attachmentId}`,
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          }
        }
      );

      if (res.status === 200) {
        dispatch(setAlert({ message: "Attachment deleted successfully", color: "success" }));
        // Refresh task data
        const updatedTask = await fetchTaskDetails(task._id);
        updateAnswer(updatedTask);
      }
    } catch (error) {
      dispatch(setAlert({ 
        message: error.response?.data?.message || "Failed to delete attachment", 
        color: "error" 
      }));
    }
  };

  const checkAttachmentLimit = () => {
    const currentAttachmentCount = task.attachments?.length || 0;
    if (currentAttachmentCount >= 25) {
      dispatch(setAlert({ 
        message: "Maximum limit of 25 attachments per task reached", 
        color: "warning" 
      }));
      return false;
    }
    return true;
  };

  useEffect(() => { 
    console.log({answers})
  }, [answers])

  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      open={selectedQuestion ? true : false}
      onClose={() => {
        setSelectedQuestion(null);
      }}
    >
      <Box style={dialogStyle}>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
            {selectedQuestion?.questionText}
            <Icon
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedQuestion(null);
              }}
            >
              close
            </Icon>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedQuestion?.questionType === "text" && (
            <TextArea answers={answers} setAnswers={setAnswers} />
          )}

          {(selectedQuestion?.questionType === "table" ||
            selectedQuestion?.questionType === "formulae") && (
            <TableComponent
              questionId={selectedQuestion?.key}
              columns={selectedQuestion?.columns}
              rows={selectedQuestion?.rows}
              tableSections={selectedQuestion?.table_sections}
              formulae={selectedQuestion?.formulae}
              questionType={selectedQuestion?.questionType}
              answers={answers}
              setAnswers={setAnswers}
            />
          )}
          {/* <div>
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: ".pdf, .doc, .docx", multiple: true }} // Specify accepted file types and allow multiple files
              style={{ display: "none" }} // Hide the input visually
              id="file-upload-input"
            />
            <label htmlFor="file-upload-input">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
            {selectedFiles.length > 0 ? (
              <div style={{ marginLeft: "10px" }}>
                {selectedFiles.map((file, index) => (
                  <div key={index}>{file.name}</div>
                ))}
              </div>
            ) : (
              "No files"
            )}
          </div> */}
        </DialogContent>

        {!isFields && (
          <DialogActions style={dialogActionsStyle}>
            <MDBox
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
                alignItems: "end",
                width: "100%",
              }}
            >
              {selectedQuestion?.isAttachmentRequired && (
                <MDButton 
                  onClick={() => {
                    if (checkAttachmentLimit()) {
                      setUploadModalOpen(true);
                    }
                  }}
                  variant="outlined" 
                  color="primary"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Attachments
                </MDButton>
              )}
              <MDButton onClick={handleDraft} variant="outlined" color="secondary">
                Save as draft
              </MDButton>
              <MDButton
                onClick={() => {
                  handleSubmit();
                }}
                variant="gradient"
                color="info"
              >
                {selectedQuestion?.parentQuestion
                  ? "Enter Data"
                  : task.answer
                  ? "Update"
                  : "Submit"}
              </MDButton>
              {selectedQuestion?.parentQuestion && (
                <MDButton
                  onClick={() => {
                    handleSubmit(true);
                  }}
                  variant="gradient"
                  color="info"
                >
                  {task.answer ? "Update" : "Submit"}
                </MDButton>
              )}
            </MDBox>
          </DialogActions>
        )}

        {task.attachments?.length > 0 && (
          <Box sx={{ 
            p: 2, 
            borderTop: 1, 
            borderColor: 'divider',
            backgroundColor: 'grey.50'
          }}>
            <Typography variant="subtitle2" gutterBottom color="primary.main">
              Attachments
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {task.attachments.map((attachment) => (
                <Box
                  key={attachment._id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'primary.light',
                    p: 0.5,
                    pl: 1.5,
                    gap: 1
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getFileIcon(attachment.filename)}
                    <Typography variant="body2" sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {attachment.filename}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 0.5, ml: 1 }}>
                    <IconButton
                      size="small"
                      onClick={() => handleView(attachment)}
                      sx={{ 
                        color: 'primary.main',
                        '&:hover': { backgroundColor: 'primary.lighter' }
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDownload(attachment)}
                      sx={{ 
                        color: 'success.main',
                        '&:hover': { backgroundColor: 'success.lighter' }
                      }}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(attachment._id)}
                      sx={{ 
                        color: 'error.main',
                        '&:hover': { backgroundColor: 'error.lighter' }
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <AttachmentUploadModal
          open={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
          onUpload={handleUpload}
          loading={uploadLoading}
          currentAttachmentCount={task.attachments?.length || 0}
        />

        <DocumentViewer 
          open={viewerOpen}
          onClose={() => {
            setViewerOpen(false);
            if (documentUrl) URL.revokeObjectURL(documentUrl);
          }}
          document={selectedAttachment}
          documentUrl={documentUrl}
          viewerError={viewerError}
          onDownload={handleDownload}
          getFileIcon={getFileIcon}
          setViewerError={setViewerError}
        />
      </Box>
    </Dialog>
  );
};

export default AddAnswer;
