import { useEffect, useState, useMemo } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Components
import AddUser from "./AddUser";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import CommonTable from "../CommonTable";

function UserTable({
  saveData,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  count,
  setPage,
  page,
  companies,
  units,
  departments,
  setCount
}) {
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedItemsEdit, setSelectedItemsEdit] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [filters, setFilters] = useState({
    companies: [],
    units: [],
    departments: []
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // Add state for units and departments
  const [allUnits, setAllUnits] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);

  const cookies = new Cookies();

  const columns = [
    { 
      field: 'srNo',
      headerName: 'SR NO',
      flex: 0.5,
      sortable: false
    },
    { 
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: true
    },
    { 
      field: 'email',
      headerName: 'Email',
      flex: 1.5,
      sortable: true
    },
    { 
      field: 'companyName',
      headerName: 'Company',
      flex: 1,
      sortable: true
    },
    { 
      field: 'unitName',
      headerName: 'Unit',
      flex: 1,
      sortable: true
    },
    { 
      field: 'departmentName',
      headerName: 'Department',
      flex: 1,
      sortable: true
    },
    { 
      field: 'roleName',
      headerName: 'Role',
      flex: 0.8,
      sortable: true
    },
    // { 
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 0.8,
    //   sortable: true,
    //   renderCell: (params) => (
    //     <MDTypography 
    //       variant="caption" 
    //       color={params.row.isActive ? "success" : "error"} 
    //       fontWeight="medium"
    //     >
    //       {params.row.isActive ? "Active" : "Inactive"}
    //     </MDTypography>
    //   )
    // },
   {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      sortable: false,
      renderCell: (params) => (
        <MDBox display="flex" gap={1}>
          <Icon
            sx={{ cursor: "pointer", color: "info.main" }}
            onClick={() => {
              setSelectedItemsEdit(params.row.originalData);
              setModalType("Edit");
              setOpen(true);
            }}
          >
            edit
          </Icon>
          <Icon
            sx={{ cursor: "pointer", color: "error.main" }}
            onClick={() => setSelectedItems(params.row.originalData)}
          >
            delete
          </Icon>
        </MDBox>
      ),
    },
  ];

  const filteredRows = useMemo(() => {
    let filtered = Array.isArray(saveData) ? saveData : saveData?.data || [];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.company?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.unit?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.department?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply company filter
    if (filters.companies.length > 0) {
      filtered = filtered.filter(item => {
        return item.company && filters.companies.includes(item.company._id);
      });
    }

    // Apply unit filter
    if (filters.units.length > 0) {
      filtered = filtered.filter(item => {
        return item.unit && filters.units.includes(item.unit._id);
      });
    }

    // Apply department filter
    if (filters.departments.length > 0) {
      filtered = filtered.filter(item => {
        return item.department && filters.departments.includes(item.department._id);
      });
    }

    return filtered.map((item, index) => ({
      id: item._id || item.id,
      srNo: index + 1,
      name: item.name || '',
      email: item.email || '',
      companyName: item.company?.name || '-',
      unitName: item.unit?.name || '-',
      departmentName: item.department?.name || '-',
      roleName: item.role?.name || '-',
      statusValue: item.isActive,
      originalData: item,
      _id: item._id || item.id
    }));
  }, [saveData, searchTerm, filters]);

  // Group the filter options
  const groupedFilterOptions = useMemo(() => {
    const grouped = {
      companies: companies || [],
      units: [],
      departments: []
    };

    // Group units by company
    if (units) {
      grouped.units = units.map(unit => ({
        ...unit,
        groupBy: unit.company?.name || 'Ungrouped'
      }));
    }

    // Group departments by unit
    if (departments) {
      grouped.departments = departments.map(dept => ({
        ...dept,
        groupBy: dept.unit?.name || 'Ungrouped'
      }));
    }

    return grouped;
  }, [companies, units, departments]);

  // Fetch units and departments when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${cookies.get("token")}`,
        };

        // Fetch units
        const unitsResponse = await axios.get(`${baseUrl}${apiV1}/units`, { headers });
        setAllUnits(unitsResponse.data);

        // Fetch departments
        const departmentsResponse = await axios.get(`${baseUrl}${apiV1}/departments`, { headers });
        setAllDepartments(departmentsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      const res = await axios.get(`${baseUrl}${apiV1}/users`, { headers });
      if (res.status === 200) {
        setSaveData(res.data || []);
      }
    } catch (e) {
      console.error("Error fetching users:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      await axios.delete(`${baseUrl}${apiV1}/users/${selectedItems.id}`, { headers });
      setConfirmOpen(false);
      setSelectedItems(null);
      fetchUsers();
    } catch (e) {
      console.error("Error deleting user:", e);
    }
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h6" gutterBottom>
            Users
          </MDTypography>
        </MDBox>

        <MDBox display="flex" alignItems="center">
          <MDBox color="text" px={2}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setOpen(true);
                setModalType("Add");
              }}
              style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
              startIcon={<AddIcon />}
            >
              User
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox>
        <CommonTable
          columns={columns}
          rows={filteredRows}
          loading={loading}
          filters={filters}
          setFilters={setFilters}
          onSearchChange={setSearchTerm}
          setSelectedRows={setSelectedRows}
          filterOptions={groupedFilterOptions}
          showCompanyFilter 
          showUnitFilter
          showDepartmentFilter
        />
      </MDBox>

      <AddUser
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={() => {
          onSuccessPost();
          fetchUsers();
        }}
        modalType={modalType}
        selectedUser={selectedItemsEdit}
        companies={companies}
        units={allUnits}
        departments={allDepartments}
      />

      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete user "{selectedItems?.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setConfirmOpen(false)} color="primary">
            Cancel
          </MDButton>
          <MDButton onClick={handleDelete} color="error" autoFocus>
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default UserTable;
