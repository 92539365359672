import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";

function AddUnit({
  open,
  setOpen,
  saveData,
  setSaveData,
  onSuccessPost,
  modalType,
  selectedUnit,
  companies,
}) {
  const [formData, setFormData] = useState({
    name: "",
    companyId: "",
    location: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (modalType === "Edit" && selectedUnit) {
      setFormData({
        name: selectedUnit.name || "",
        companyId: selectedUnit.companyId || selectedUnit.company?.id || "",
        location: selectedUnit.location || "",
        description: selectedUnit.description || "",
      });
    } else {
      resetForm();
    }
  }, [modalType, selectedUnit, open]);

  const resetForm = () => {
    setFormData({
      name: "",
      companyId: "",
      location: "",
      description: "",
    });
    setErrors({});
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Unit name is required";
    }
    if (!formData.companyId) {
      newErrors.companyId = "Company is required";
    }
    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    // Transform the data to match backend expectations
    const submitData = {
      name: formData.name,
      company: formData.companyId, // Change companyId to company
      location: formData.location,
      description: formData.description || undefined,
    };

    try {
      if (modalType === "Edit") {
        const res = await axios.put(`${baseUrl}${apiV1}/units/${selectedUnit.id}`, submitData, {
          headers,
        });
        if (res.status === 200) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `Unit updated successfully!`, color: "success" }));
        }
      } else {
        const res = await axios.post(`${baseUrl}${apiV1}/units`, submitData, { headers });
        if (res.status === 201) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `Unit created successfully!`, color: "success" }));
        }
      }
    } catch (e) {
      console.error("Error saving unit:", e);
      dispatch(setAlert({ message: e.response.data.message, color: "error" }));
      if (e.response && e.response.data) {
        const backendErrors = e.response.data.errors;
        if (backendErrors) {
          const formattedErrors = {};
          backendErrors.forEach((err) => {
            formattedErrors[err.field] = err.message;
          });
          setErrors(formattedErrors);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {modalType === "Edit" ? "Edit Unit" : "Add Unit"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth error={Boolean(errors.companyId)}>
              <InputLabel>Select Company</InputLabel>
              <Select
                name="companyId"
                value={formData.companyId}
                onChange={handleChange}
                label="Select Company"
                sx={{height: '40px'}}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.companyId && <FormHelperText>{errors.companyId}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Unit Name"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="location"
              label="Location"
              fullWidth
              variant="outlined"
              value={formData.location}
              onChange={handleChange}
              error={Boolean(errors.location)}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleSubmit} color="info">
          {modalType === "Edit" ? "Update" : "Save"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddUnit;
