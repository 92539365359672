import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

function DocumentFilters({ 
  searchTerm, 
  setSearchTerm, 
  filters, 
  setFilters, 
  companies, 
  handleClearFilters 
}) {
  return (
    <MDBox p={3} display="flex" flexWrap="wrap" gap={2} alignItems="center" mb={3}>
      <TextField
        placeholder="Search documents..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={() => setSearchTerm("")}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControl sx={{ width: 270 }} size="small">
        <InputLabel>Company</InputLabel>
        <Select
          value={filters.company}
          onChange={(e) => setFilters({ ...filters, company: e.target.value })}
          label="Company"
          sx={{ height: "40px" }}
        >
          <MenuItem value="">All Companies</MenuItem>
          {companies.map((company) => (
            <MenuItem key={company._id} value={company.name}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <MDButton
        variant="outlined"
        color="error"
        onClick={handleClearFilters}
        startIcon={<ClearIcon />}
        sx={{ height: "40px", marginLeft: "auto" }}
        disabled={!filters.company && !searchTerm}
      >
        Clear Filters
      </MDButton>
    </MDBox>
  );
}

export default DocumentFilters; 