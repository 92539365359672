import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./table.css";
import { Box, Typography } from "@mui/material";
import MDButton from "components/MDButton";

export const extractFieldInfo = (field) => {
  const [row, column] = field?.split("*") || [];
  const [columnName, columnIndex, subColumnIndex] = column?.replaceAll(".", "")?.split("-") || [];

  return {
    row: Number(row) - 1,
    column: Number(columnIndex) - 1,
    columnName,
    subColumnIndex: Number(subColumnIndex) - 1,
  };
};

export const calculate = (value1, value2, operand) => {
  let res = "";
  const num1 = Number(value1) || 0;
  const num2 = Number(value2) || 0;

  if (operand === "+") {
    res = (num1 + num2).toFixed(2);
  } else if (operand === "%") {
    if (num2 === 0) {
      res = num1 === 0 ? "0.00" : "0.00";
    } else {
      res = ((num1 / num2) * 100).toFixed(2);
    }
  } else if (operand === "rev%") {
    if (num1 === 0) {
      res = num2 === 0 ? "0.00" : "0.00";
    } else {
      res = ((num2 * 100) / num1).toFixed(2);
    }
  } else if (operand === "/") {
    if (num2 === 0) {
      res = num1 === 0 ? "0.00" : "0.00";
    } else {
      res = (num1 / num2).toFixed(2);
    }
  } else if (operand === "*") {
    res = (num1 * num2).toFixed(2);
  }

  return isFinite(res) && res !== "" ? res : "0.00";
};

export function calculateCellData(updatedAnswers, formula, value, formulae, rowIndex) {
  formula?.map((fm) => {
    if (fm.length > 4) {
      const operands = [...new Set(fm.filter((ele) => ["+", "%", "rev%", "/", "*"].includes(ele)))];
      let result = 0;

      if (operands.length === 1 && operands[0] === "+") {
        for (let i = 0; i < fm.length - 1; i++) {
          const cellReference = fm[i];
          const operator = fm[fm.length - 3];
          const fieldInfo = extractFieldInfo(cellReference);

          if (fieldInfo.row !== undefined && fieldInfo.columnName) {
            let cellValue;

            if (!isNaN(fieldInfo.subColumnIndex)) {
              cellValue =
                updatedAnswers[fieldInfo.row]?.[fieldInfo.columnName]?.[fieldInfo.column]?.[
                  fieldInfo.subColumnIndex
                ];
            } else {
              cellValue = updatedAnswers[fieldInfo.row]?.[fieldInfo.columnName];
            }

            if (cellValue && operator.length < 5) {
              const sum = calculate(cellValue, result, operator);
              result = sum;
            }
          }
        }
      } else {
        for (let i = 0; i < fm.length - 1; i++) {
          const cellReference = fm[i];
          const operator = fm[i + 1];
          const fieldInfo = extractFieldInfo(cellReference);
          const secFieldInfo = extractFieldInfo(fm[i + 2]);

          let cellValue,
            secCellValue = fm[i + 2]?.includes("number_") ? fm[i + 2].split("_")[1] : 0;

          if (cellReference.includes("number_")) {
            cellValue = cellReference.split("_")[1];
          }

          if (fieldInfo.row !== undefined && fieldInfo.columnName) {
            if (!isNaN(fieldInfo.subColumnIndex)) {
              cellValue =
                updatedAnswers[fieldInfo.row]?.[fieldInfo.columnName]?.[fieldInfo.column]?.[
                  fieldInfo.subColumnIndex
                ];
            } else {
              if (!isNaN(fieldInfo.column)) {
                cellValue =
                  updatedAnswers[fieldInfo.row]?.[fieldInfo.columnName]?.[fieldInfo.column];
              } else {
                cellValue = updatedAnswers[fieldInfo.row]?.[fieldInfo.columnName];
              }
            }
          }

          if (secFieldInfo.row !== undefined && secFieldInfo.columnName) {
            if (!isNaN(secFieldInfo.subColumnIndex)) {
              secCellValue =
                updatedAnswers[secFieldInfo.row]?.[secFieldInfo.columnName]?.[
                  secFieldInfo.column
                ]?.[secFieldInfo.subColumnIndex];
            } else {
              if (!isNaN(secFieldInfo.column)) {
                secCellValue =
                  updatedAnswers[secFieldInfo.row]?.[secFieldInfo.columnName]?.[
                    secFieldInfo.column
                  ];
              } else {
                secCellValue = updatedAnswers[secFieldInfo.row]?.[secFieldInfo.columnName];
              }
            }
          }

          if (cellValue && operator.length < 5) {
            const sum = calculate(i < 1 ? cellValue : result, secCellValue, operator);
            result = sum;
          }
          // }
        }
      }

      const finalResult = extractFieldInfo(fm[fm.length - 1]);

      if (finalResult.columnName && updatedAnswers[rowIndex]) {
        if (!updatedAnswers[finalResult.row]) {
          updatedAnswers[finalResult.row] = {};
        }
        if (!updatedAnswers[finalResult.row][finalResult.columnName]) {
          updatedAnswers[finalResult.row][finalResult.columnName] = [];
        }
        if (updatedAnswers[finalResult.row]?.[finalResult.columnName]) {
          if (
            Array.isArray(updatedAnswers[finalResult.row][finalResult.columnName]) &&
            !isNaN(finalResult.column)
          ) {
            if (isNaN(finalResult.subColumnIndex)) {
              updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column] =
                Number(result).toFixed(2);
            } else {
              updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column][
                finalResult.subColumnIndex
              ] = Number(result).toFixed(2);
            }
          } else {
            updatedAnswers[finalResult.row][finalResult.columnName] = Number(result).toFixed(2);
          }
        }
      }

      const subFormulae = isNaN(finalResult.column)
        ? formulae?.[`${finalResult.row + 1}*${finalResult.columnName}`]
        : isNaN(finalResult.subColumnIndex)
        ? formulae?.[`${finalResult.row + 1}*${finalResult.columnName}-${finalResult.column + 1}`]
        : formulae?.[
            `${finalResult.row + 1}*${finalResult.columnName}-${finalResult.column + 1}-${
              finalResult.subColumnIndex + 1
            }`
          ];

      const finalResultValue = isNaN(finalResult.column)
        ? updatedAnswers[finalResult.row][finalResult.columnName]
        : isNaN(finalResult.subColumnIndex)
        ? updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column]
        : updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column][
            finalResult.subColumnIndex
          ];
      if (subFormulae) {
        updatedAnswers = calculateCellData(
          updatedAnswers,
          subFormulae,
          finalResultValue,
          formulae,
          rowIndex
        );
      }
    } else {
      const secondField = extractFieldInfo(fm[2]);
      const finalResult = extractFieldInfo(fm[fm.length - 1]);
      const operand = fm[fm.length - 3];

      if (finalResult.columnName && updatedAnswers[rowIndex]) {
        if (!updatedAnswers[finalResult.row]) {
          updatedAnswers[finalResult.row] = {};
        }
        if (!updatedAnswers[finalResult.row][finalResult.columnName]) {
          updatedAnswers[finalResult.row][finalResult.columnName] = [];
        }
        if (updatedAnswers[rowIndex][secondField.columnName] && secondField.column >= 0) {
          if (
            updatedAnswers[finalResult.row]?.[finalResult.columnName] &&
            updatedAnswers[secondField.row]?.[secondField.columnName]?.[secondField.column]
          ) {
            if (
              Array.isArray(updatedAnswers[finalResult.row][finalResult.columnName]) &&
              !isNaN(finalResult.column)
            ) {
              if (!isNaN(finalResult.subColumnIndex)) {
                if (!updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column]) {
                  updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column] = [];
                }
                updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column][
                  finalResult.subColumnIndex
                ] = calculate(
                  value,
                  !isNaN(secondField.subColumnIndex)
                    ? updatedAnswers[secondField.row][secondField.columnName][secondField.column][
                        secondField.subColumnIndex
                      ]
                    : updatedAnswers[secondField.row][secondField.columnName][secondField.column],
                  operand
                );
              } else {
                updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column] =
                  calculate(
                    value,
                    !isNaN(secondField.subColumnIndex)
                      ? updatedAnswers[secondField.row][secondField.columnName][secondField.column][
                          secondField.subColumnIndex
                        ]
                      : updatedAnswers[secondField.row][secondField.columnName][secondField.column],
                    operand
                  );
              }
            } else {
              updatedAnswers[finalResult.row][finalResult.columnName] = calculate(
                value,
                !isNaN(secondField.subColumnIndex)
                  ? updatedAnswers[secondField.row][secondField.columnName][secondField.column][
                      secondField.subColumnIndex
                    ]
                  : updatedAnswers[secondField.row][secondField.columnName][secondField.column],
                operand
              );
            }

            const subFormulae = isNaN(finalResult.column)
              ? formulae?.[`${finalResult.row + 1}*${finalResult.columnName}`]
              : isNaN(finalResult.subColumnIndex)
              ? formulae?.[
                  `${finalResult.row + 1}*${finalResult.columnName}-${finalResult.column + 1}`
                ]
              : formulae?.[
                  `${finalResult.row + 1}*${finalResult.columnName}-${finalResult.column + 1}-${
                    finalResult.subColumnIndex + 1
                  }`
                ];

            const finalResultValue = isNaN(finalResult.column)
              ? updatedAnswers[finalResult.row][finalResult.columnName]
              : isNaN(finalResult.subColumnIndex)
              ? updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column]
              : updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column][
                  finalResult.subColumnIndex
                ];
            if (subFormulae) {
              updatedAnswers = calculateCellData(
                updatedAnswers,
                subFormulae,
                finalResultValue,
                formulae,
                rowIndex
              );
            }
          }
        } else {
          if (
            updatedAnswers[finalResult.row]?.[finalResult.columnName] &&
            updatedAnswers[secondField.row]?.[secondField.columnName]
          ) {
            if (
              Array.isArray(updatedAnswers[finalResult.row][finalResult.columnName]) &&
              !isNaN(finalResult.column)
            ) {
              if (!isNaN(finalResult.subColumnIndex)) {
                updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column][
                  finalResult.subColumnIndex
                ] = calculate(
                  value,
                  isNaN(secondField.subColumnIndex)
                    ? updatedAnswers[secondField.row][secondField.columnName]
                    : updatedAnswers[secondField.row][secondField.columnName][
                        secondField.subColumnIndex
                      ],
                  operand
                );
              } else {
                updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column] =
                  calculate(
                    value,
                    isNaN(secondField.subColumnIndex)
                      ? updatedAnswers[secondField.row][secondField.columnName]
                      : updatedAnswers[secondField.row][secondField.columnName][
                          secondField.subColumnIndex
                        ],
                    operand
                  );
              }
            } else {
              updatedAnswers[finalResult.row][finalResult.columnName] = calculate(
                value,
                isNaN(secondField.subColumnIndex)
                  ? updatedAnswers[secondField.row][secondField.columnName]
                  : updatedAnswers[secondField.row][secondField.columnName][
                      secondField.subColumnIndex
                    ],
                operand
              );
            }

            const subFormulae = isNaN(finalResult.column)
              ? formulae?.[`${finalResult.row + 1}*${finalResult.columnName}`]
              : isNaN(finalResult.subColumnIndex)
              ? formulae?.[
                  `${finalResult.row + 1}*${finalResult.columnName}-${finalResult.column + 1}`
                ]
              : formulae?.[
                  `${finalResult.row + 1}*${finalResult.columnName}-${finalResult.column + 1}-${
                    finalResult.subColumnIndex + 1
                  }`
                ];

            if (subFormulae) {
              updatedAnswers = calculateCellData(
                updatedAnswers,
                subFormulae,
                isNaN(finalResult.column)
                  ? isNaN(finalResult.subColumnIndex)
                    ? updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column]
                    : updatedAnswers[finalResult.row][finalResult.columnName][finalResult.column][
                        finalResult.subColumnIndex
                      ]
                  : updatedAnswers[finalResult.row][finalResult.columnName],
                formulae,
                rowIndex
              );
            }
          }
        }
        // }
      }
    }
  });

  return updatedAnswers;
}

export const TableComponent = ({
  columns,
  rows,
  answers,
  setAnswers,
  tableSections,
  formulae,
  questionType,
  questionId,
}) => {
  
  const [tableData, setTableData] = useState(rows);
  let uniqueCounter = 0;

  const renderColumnHeaders = (columns) => {
    return columns.map((column, index) => (
      <TableCell
        style={{ border: "1px solid #eee" }}
        key={index}
        className="parent-column"
        align="left"
      >
        <div style={{ textAlign: "center" }}> 
          {Array.isArray(column) ? column[0] : column}
        </div>
        {Array.isArray(column) && Array.isArray(column[1]) && (
          <div style={{ display: "flex", gap: "8px" }}>
            {column[1].map((subColumn, subIndex) => (
              <TableCell
                key={`${index}-${subIndex}`}
                className="sub-column"
                align="center"
                sx={{ width: "100%", borderBottom: "none" }}
              >
                {Array.isArray(subColumn) && Array.isArray(subColumn[1]) ? (
                  <>
                    {subColumn[0]}
                    {subColumn[1].map((subNestColumn, subNestIndex) => (
                      <TableCell
                        key={`${index}-${subIndex}-${subNestIndex}`}
                        className="sub-nested-column"
                        align="center"
                        sx={{ width: "100%", borderBottom: "none" }}
                      >
                        {subNestColumn}
                      </TableCell>
                    ))}
                  </>
                ) : (
                  subColumn
                )}
              </TableCell>
            ))}
          </div>
        )}
      </TableCell>
    ));
  };

  const renderRowCells = (row, rowIndex, columns, rowData, cellIndex) => {
    // Validate inputs
    if (!columns || !Array.isArray(columns)) {
      return null;
    }

    // Ensure answers is an array
    const currentAnswers = Array.isArray(answers) ? answers : [];

    return columns.map((column, index) => {
      if (index === 0 && rows.length > 0) {
        // Render first column with default value from rows
        return <TableCell key={index}>{row}</TableCell>;
      } else if (index === 1 && rowData?.length > 1) {
        // Render first column with default value from rows
        return <TableCell key={index}>{rowData?.[1]?.[cellIndex]}</TableCell>;
      } else if (Array.isArray(column) && Array.isArray(column[1])) {
        return (
          <TableCell key={index} align="left">
            {column[1]?.map((subColumn, subIndex) => {
              if (Array.isArray(subColumn) && Array.isArray(subColumn[1])) {
                return (
                  <>
                    {subColumn[1]?.map((subNestCol, subNestedIndex) => {
                      return (
                        <TextField
                          key={`${rowIndex}-${index}-${subIndex}-${subNestedIndex}`}
                          sx={{
                            width: `calc(${100 / column[1]?.length / subColumn[1]?.length}% - 8px)`,
                            mr: 1,
                          }}
                          value={
                            currentAnswers[rowIndex] &&
                            currentAnswers[rowIndex][column[0]?.replaceAll(".", "")] &&
                            currentAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex]
                              ? Array.isArray(
                                  currentAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex]
                                )
                                ? currentAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex][
                                    subNestedIndex
                                  ]
                                  ? currentAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex][
                                      subNestedIndex
                                    ]
                                  : ""
                                : currentAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex]
                              : ""
                          }
                          onChange={(e) => {
                            let updatedAnswers = Array.isArray(currentAnswers) ? 
                              [...currentAnswers] : 
                              [];
                            if (!updatedAnswers[rowIndex]) {
                              updatedAnswers[rowIndex] = {};
                            }
                            if (!updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")]) {
                              updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")] = [];
                            }
                            if (
                              !updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex]
                            ) {
                              updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex] =
                                [];
                            }

                            // If the value is an array, set the value at subIndex
                            if (
                              Array.isArray(
                                updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")]
                              )
                            ) {
                              if (
                                Array.isArray(
                                  updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex]
                                )
                              ) {
                                updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex][
                                  subNestedIndex
                                ] = e.target.value;
                              } else {
                                updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex] =
                                  e.target.value;
                              }
                            } else {
                              // If not an array, set the value directly
                              updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")] =
                                e.target.value;
                            }

                            // Retrieve the formula corresponding to the given rowIndex, column[0], and subIndex
                            const formula = isNaN(subIndex)
                              ? formulae?.[`${rowIndex + 1}*${column[0]}`]
                              : isNaN(subNestedIndex)
                              ? formulae?.[`${rowIndex + 1}*${column[0]}-${subIndex + 1}`]
                              : formulae?.[
                                  `${rowIndex + 1}*${column[0]}-${subIndex + 1}-${
                                    subNestedIndex + 1
                                  }`
                                ];
                            if (questionType === "formulae" && formula) {
                              updatedAnswers = calculateCellData(
                                updatedAnswers,
                                formula,
                                e.target.value,
                                formulae,
                                rowIndex
                              );
                            }

                            setAnswers(updatedAnswers);
                          }}
                          variant="outlined"
                        />
                      );
                    })}
                  </>
                );
              }

              return (
                <TextField
                  key={`${rowIndex}-${index}-${subIndex}`}
                  sx={{ width: `calc(${100 / column[1]?.length}% - 8px)`, mr: 1 }}
                  value={
                    currentAnswers[rowIndex] &&
                    currentAnswers[rowIndex]?.[column[0]?.replaceAll(".", "")] &&
                    currentAnswers[rowIndex]?.[column[0]?.replaceAll(".", "")]?.[subIndex]
                      ? currentAnswers[rowIndex]?.[column[0]?.replaceAll(".", "")][subIndex]
                      : ""
                  }
                  onChange={(e) => {
                    let updatedAnswers = Array.isArray(currentAnswers) ? 
                      [...currentAnswers] : 
                      [];
                    if (!updatedAnswers[rowIndex]) {
                      updatedAnswers[rowIndex] = {};
                    }
                    if (!updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")]) {
                      updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")] = [];
                    }

                    // If the value is an array, set the value at subIndex
                    if (Array.isArray(updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")])) {
                      updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")][subIndex] =
                        e.target.value;
                    } else {
                      // If not an array, set the value directly
                      updatedAnswers[rowIndex][column[0]?.replaceAll(".", "")] = e.target.value;
                    }

                    // Retrieve the formula corresponding to the given rowIndex, column[0], and subIndex
                    const formula = isNaN(subIndex)
                      ? formulae?.[`${rowIndex + 1}*${column[0]}`]
                      : formulae?.[`${rowIndex + 1}*${column[0]}-${subIndex + 1}`];
                    if (questionType === "formulae" && formula) {
                      updatedAnswers = calculateCellData(
                        updatedAnswers,
                        formula,
                        e.target.value,
                        formulae,
                        rowIndex
                      );
                    }

                    setAnswers(updatedAnswers);
                  }}
                  variant="outlined"
                />
              );
            })}
          </TableCell>
        );
      } else {
        // Render regular text field
        const columnKey = typeof column === 'object' && column !== null ? 
          (column[0] ? column[0]?.replaceAll(".", "") : index) : column;
          
        return (
          <TableCell key={index} align="left">
            <TextField
              sx={{ width: "100%" }}
              value={
                currentAnswers[rowIndex] && 
                columnKey && 
                currentAnswers[rowIndex][columnKey] !== undefined ? 
                currentAnswers[rowIndex][columnKey] : 
                ''
              }
              onChange={(e) => {
                let updatedAnswers = Array.isArray(currentAnswers) ? 
                  [...currentAnswers] : 
                  [];
                if (!updatedAnswers[rowIndex]) {
                  updatedAnswers[rowIndex] = {};
                }
                
                if (columnKey) {
                  updatedAnswers[rowIndex][columnKey] = e.target.value;

                  const formula = formulae?.[`${rowIndex + 1}*${columnKey}`];
                  if (formula) {
                    updatedAnswers = calculateCellData(
                      updatedAnswers,
                      formula,
                      e.target.value,
                      formulae,
                      rowIndex
                    );
                  }
                }
                
                setAnswers(updatedAnswers);
              }}
              variant="outlined"
            />
          </TableCell>
        );
      }
    });
  };

  const safeRenderRow = (row, rowIndex) => {
    if (!row) {
      return null;
    }

    try {
      if (Array.isArray(row)) {
        return row.map((rw, i) => {
          if (!Array.isArray(rw)) {
            const key = uniqueCounter++;
            return (
              <TableRow key={`${rowIndex}-${i}-single`}>
                {renderRowCells(rw, key, columns)}
              </TableRow>
            );
          }
          
          return rw.map((r, idx) => {
            if (i === 0) {
              const key = uniqueCounter++;
              return (
                <TableRow key={`${rowIndex}-${i}-${idx}`}>
                  {renderRowCells(r, key, columns, row, idx)}
                </TableRow>
              );
            }
            return null;
          });
        });
      }

      return (
        <TableRow key={rowIndex}>
          {renderRowCells(row, rowIndex, columns)}
        </TableRow>
      );
    } catch (error) {
      console.error('Error rendering row:', error);
      return null;
    }
  };

  return (
    <>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650, width: "100%" }}>
          <TableHead className="quetionTableHead">
            <TableRow>{renderColumnHeaders(columns)}</TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => (
              <React.Fragment key={`fragment-${rowIndex}`}>
                {tableSections && tableSections?.length > 0 && (
                  <TableRow key={`${rowIndex}_Div`}>
                    <TableCell 
                      align="left" 
                      colSpan={columns && Array.isArray(columns) ? 
                        columns.length : 1}
                    >
                      <Typography style={{ textAlign: "center", fontSize: 16, fontWeight: "600" }}>
                        {tableSections?.[rowIndex]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {safeRenderRow(row, rowIndex)}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && (
        <MDButton
          sx={{ mt: 2, float: "right" }}
          onClick={() => {
            setTableData((prevState) => [...prevState, ""]);
          }}
          variant="gradient"
          color="info"
        >
          Add Row
        </MDButton>
      )}
    </>
  );
};

export default TableComponent;