import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";

function AddDepartment({
  open,
  setOpen,
  saveData,
  setSaveData,
  onSuccessPost,
  modalType,
  selectedDepartment,
  companies,
  units,
}) {
  const [formData, setFormData] = useState({
    name: "",
    unitId: "",
    companyId: "",
    location: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [filteredUnits, setFilteredUnits] = useState([]);
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (modalType === "Edit" && selectedDepartment) {
      setFormData({
        name: selectedDepartment.name || "",
        unitId: selectedDepartment.unit?.id || "",
        companyId: selectedDepartment.unit?.company?.id || "",
        location: selectedDepartment.location || "",
        description: selectedDepartment.description || "",
      });
    } else {
      resetForm();
    }
  }, [modalType, selectedDepartment, open]);

  useEffect(() => {
    if (formData.companyId) {
      setFilteredUnits(units.filter((unit) => unit.company.id === formData.companyId));
    } else {
      setFilteredUnits([]);
    }
  }, [formData.companyId, units]);

  const resetForm = () => {
    setFormData({
      name: "",
      unitId: "",
      companyId: "",
      location: "",
      description: "",
    });
    setErrors({});
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Department name is required";
    }
    if (!formData.companyId) {
      newErrors.companyId = "Company is required";
    }
    if (!formData.unitId) {
      newErrors.unitId = "Unit is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const submitData = {
      name: formData.name,
      unit: formData.unitId,
      location: formData.location,
      description: formData.description || undefined,
    };

    try {
      if (modalType === "Edit") {
        const res = await axios.patch(
          `${baseUrl}${apiV1}/departments/${selectedDepartment.id}`,
          submitData,
          { headers }
        );
        if (res.status === 200) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `Department updated successfully!`, color: "success" }));
        }
      } else {
        const res = await axios.post(`${baseUrl}${apiV1}/departments`, submitData, { headers });
        if (res.status === 201) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `Department created successfully!`, color: "success" }));
        }
      }
    } catch (e) {
      console.error("Error saving department:", e);
      if (e.response && e.response.data) {
        const backendErrors = e.response.data.errors;
        if (backendErrors) {
          const formattedErrors = {};
          backendErrors.forEach((err) => {
            formattedErrors[err.field] = err.message;
          });
          setErrors(formattedErrors);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updates = { [name]: value };

      // Reset dependent fields when company changes
      if (name === "companyId") {
        updates.unitId = "";
      }

      return { ...prev, ...updates };
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {modalType === "Edit" ? "Edit Department" : "Add Department"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth error={Boolean(errors.companyId)}>
              <InputLabel>Select Company</InputLabel>
              <Select
                name="companyId"
                value={formData.companyId}
                onChange={handleChange}
                label="Select Company"
                sx={{height: '40px'}}
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.companyId && <FormHelperText>{errors.companyId}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={Boolean(errors.unitId)}>
              <InputLabel>Select Unit</InputLabel>
              <Select
                name="unitId"
                value={formData.unitId}
                onChange={handleChange}
                label="Select Unit"
                disabled={!formData.companyId}
                sx={{height: '40px'}}
              >
                {filteredUnits.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.unitId && <FormHelperText>{errors.unitId}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Department Name"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="location"
              label="Location"
              fullWidth
              variant="outlined"
              value={formData.location}
              onChange={handleChange}
              error={Boolean(errors.location)}
              helperText={errors.location}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              label="Description"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleSubmit} color="info">
          {modalType === "Edit" ? "Update" : "Save"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddDepartment;
