import React from 'react';
import {
  Box,
  DialogTitle,
  DialogContent,
  IconButton,
  Dialog,
} from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import { getFileType } from '../../utils/documentUtils';

function DocumentViewer({ 
  open, 
  onClose, 
  document, 
  documentUrl, 
  viewerError, 
  onDownload,
  getFileIcon,
  setViewerError
}) {
  const handleClose = () => {
    if (documentUrl && documentUrl.startsWith('blob:')) {
      URL.revokeObjectURL(documentUrl);
    }
    onClose();
  };

  const renderDocumentViewer = () => {
    if (!document) return null;
    if (viewerError) {
      return (
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          height="100%"
          padding={4}
        >
          <DescriptionIcon sx={{ fontSize: 60, color: "grey.500", mb: 2 }} />
          <MDTypography variant="h6" color="error" gutterBottom>
            Unable to display document
          </MDTypography>
          <MDTypography variant="body2" color="text" textAlign="center" mb={3}>
            The document could not be displayed in the viewer. Please try downloading it instead.
          </MDTypography>
          <MDButton 
            variant="contained" 
            color="info" 
            onClick={() => onDownload(document)}
            startIcon={<GetAppIcon />}
          >
            Download Document
          </MDButton>
        </Box>
      );
    }

    const fileType = getFileType(document.originalName);

    switch (fileType) {
      case "pdf":
        return (
          <iframe
            src={documentUrl}
            width="100%"
            height="100%"
            title="PDF Viewer"
            style={{ border: "none" }}
            onError={() => setViewerError(true)}
          />
        );
      case "image":
        return (
          <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            height="100%" 
            bgcolor="grey.100"
          >
            <img 
              src={documentUrl}
              alt={document.originalName}
              style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
              onError={() => setViewerError(true)}
            />
          </Box>
        );
      default:
        return (
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            height="100%"
            padding={4}
          >
            <DescriptionIcon sx={{ fontSize: 60, color: "grey.500", mb: 2 }} />
            <MDTypography variant="h6" gutterBottom>
              Preview not available
            </MDTypography>
            <MDTypography variant="body2" color="text" textAlign="center" mb={3}>
              This file type cannot be previewed directly in the browser. Please download it to view its contents.
            </MDTypography>
            <MDButton 
              variant="contained" 
              color="info" 
              onClick={() => onDownload(document)}
              startIcon={<GetAppIcon />}
            >
              Download Document
            </MDButton>
          </Box>
        );
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="lg" 
      fullWidth
      PaperProps={{
        sx: { 
          height: '90vh', 
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            {document && getFileIcon(document.originalName)}
            <MDTypography variant="h6">{document?.originalName}</MDTypography>
          </Box>
          <Box display="flex" gap={1}>
            <MDButton
              variant="outlined"
              color="info"
              onClick={() => document && onDownload(document)}
              startIcon={<GetAppIcon />}
              size="small"
            >
              Download
            </MDButton>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </MDBox>
      </DialogTitle>
      <DialogContent sx={{ flexGrow: 1, padding: 0, overflow: 'hidden' }}>
        <Box sx={{ height: '100%', width: '100%' }}>
          {renderDocumentViewer()}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DocumentViewer; 