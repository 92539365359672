const userRights = ["documentLibrary", "assignedTasks", "survey", "settings"];
const l4Rights = [...userRights, "dashboard", "master"];
const l3Rights = [...l4Rights];
const l2Rights = [...l3Rights];
const l1Rights = [...l2Rights, "modules", "tasks", "settings"];
const superAdminRights = [
  "dashboard",
  "tasks",
  "documentLibrary",
  "master",
  "modules",
  "assignedTasks",
  "survey",
  "settings",
];

const allRoles = {
  SUPER_ADMIN: superAdminRights,
  L0: superAdminRights,
  L1: l1Rights,
  L2: l2Rights,
  L3: l3Rights,
  L4: l4Rights,
  USER: userRights,
};

export const roles = Object.keys(allRoles);
export const roleRights = new Map(Object.entries(allRoles));

export const hasPermission = (accessLevel, requiredKey) => {
  const userRights = roleRights.get(accessLevel);
  return userRights ? userRights.includes(requiredKey) : false;
};

export default {
  roles,
  roleRights,
  hasPermission,
};
