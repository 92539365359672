/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// import filterIcon

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Fields/data";
import MDButton from "components/MDButton";
import AddFile from "./AddFile";
import ViewLogs from "./viewLogs";
import userData from "./data/userData";
import { useLocation, useNavigate } from "react-router-dom";
import projectsList from "./data/partData";
import AddTask from "./AddTask";
import MultiSelectTable from "./MultiSelectTable";
import partData from "./data/partData";
import AddAnswer from "./AddAnswer";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddQuestion from "./AddQuestion";

function Fields({
  saveData,
  dataType = null,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  setSelectedRows,
  selectedRows,
  count,
  setPage,
  page,
  isDashboard = false,
  isAdmin = false,
  title = "Questions",
  showDirectQuestions = false,
}) {
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [searchText, setSearchText] = useState("");

  const cookies = new Cookies();
  const accessLevel = cookies.get("accessLevel");

  const handleSearch = (searchText) => {
    handleSearchChange(searchText);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  const handleEditQuestion = (question) => {
    setEditQuestion(question);
    setOpenQuestionModal(true);
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {title}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" alignItems="center">
          {accessLevel === "L0" && (
            <MDBox color="text" px={2}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => setOpenQuestionModal(true)}
                style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
                startIcon={<AddIcon />}
              >
                Question
              </MDButton>
            </MDBox>
          )}

          {selectedRows?.length > 0 && (
            <MDBox color="text" px={2}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => setOpen(true)}
                style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
                startIcon={<AddIcon />}
              >
                Assign to User(s)
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>

      <MDBox>
        <MultiSelectTable
          saveData={[{ details: saveData }]}
          setSelectedRows={setSelectedRows}
          onSuccessPost={onSuccessPost}
          setOpenQuestionModal={setOpenQuestionModal}
          setEditQuestion={setEditQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      </MDBox>

      <AddQuestion
        open={openQuestionModal}
        setOpen={setOpenQuestionModal}
        editQuestion={editQuestion}
        setEditQuestion={setEditQuestion}
      />
      <AddTask
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={onSuccessPost}
        selectedRows={selectedRows}
      />
      <AddAnswer
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        task={{}}
        isFields={true}
      />
    </Card>
  );
}

export default Fields;
