import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  ListItemText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 140,
    },
  },
};

function getStyles(name, selectedItems, theme) {
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddTask = ({ open, setOpen, selectedRows, onSuccessPost }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dueDate, setDueDate] = useState(null);

  const [filters, setFilters] = useState({
    companies: [],
    units: [],
    departments: [],
    roles: [],
  });

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
    return () => {
      if (!open) {
        setSelectedUsers([]);
        setSearchQuery("");
      }
    };
  }, [open]);

  const fetchUsers = async () => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const res = await axios.get(`${baseUrl}${apiV1}/users`, { headers });
      if (Array.isArray(res.data)) {
        setUsers(res.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Failed to fetch users",
          color: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const filterOptions = useMemo(() => {
    const companies = [...new Set(users.map((user) => user.company?.name).filter(Boolean))];

    const units = [...new Set(users.map((user) => user.unit?.name).filter(Boolean))];

    const departments = [...new Set(users.map((user) => user.department?.name).filter(Boolean))];

    const roles = [...new Set(users.map((user) => user.role?.name).filter(Boolean))];

    return {
      companies,
      units,
      departments,
      roles,
    };
  }, [users]);

  const filteredAndSearchedUsers = useMemo(() => {
    let filtered = [...users];

    if (filters.companies.length > 0) {
      filtered = filtered.filter(
        (user) => user.company?.name && filters.companies.includes(user.company.name)
      );
    }

    if (filters.units.length > 0) {
      filtered = filtered.filter(
        (user) => user.unit?.name && filters.units.includes(user.unit.name)
      );
    }

    if (filters.departments.length > 0) {
      filtered = filtered.filter(
        (user) => user.department?.name && filters.departments.includes(user.department.name)
      );
    }

    if (filters.roles.length > 0) {
      filtered = filtered.filter(
        (user) => user.role?.name && filters.roles.includes(user.role.name)
      );
    }

    if (searchQuery.trim() !== "") {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (user) =>
          (user.name && user.name.toLowerCase().includes(query)) ||
          (user.email && user.email.toLowerCase().includes(query))
      );
    }

    return filtered;
  }, [users, filters, searchQuery]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [filterName]: value,
      };

      if (filterName === "companies") {
        newFilters.units = [];
        newFilters.departments = [];
      }

      return newFilters;
    });
  };

  const handleClearFilters = () => {
    setFilters({
      companies: [],
      units: [],
      departments: [],
      roles: [],
    });
    setSearchQuery("");
  };

  const handleAssign = async () => {
    if (selectedUsers.length === 0) {
      dispatch(setAlert({ message: "Please select at least one user", color: "error" }));
      return;
    }

    if (selectedRows.length === 0) {
      dispatch(setAlert({ message: "No questions selected", color: "error" }));
      return;
    }

    if (!dueDate) {
      dispatch(setAlert({ message: "Please select a due date", color: "error" }));
      return;
    }

    setLoading(true);
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const res = await axios.post(
        baseUrl + apiV1 + "/questions/assign",
        {
          users: selectedUsers.map((user) => user._id),
          questions: selectedRows,
          dueDate: dueDate.toISOString(),
        },
        { headers }
      );

      if (res.status === 200) {
        dispatch(setAlert({ message: `Tasks assigned successfully!`, color: "success" }));
        setOpen(false);
        onSuccessPost();
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Failed to assign questions",
          color: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid",
          borderColor: theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
          py: 2,
        }}
      >
        <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
          Assign Questions to Users
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 3,
            mt: 1,
            backgroundColor:
              theme.palette.mode === "dark" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
            borderRadius: 2,
          }}
        >
          <MDBox display="flex" flexWrap="wrap" gap={2} alignItems="center">
            {filterOptions.companies.length > 0 && (
              <FormControl sx={{ width: 200 }}>
                <InputLabel id="section-multiple-label">Company</InputLabel>
                <Select
                  labelId="section-multiple-label"
                  id="section-multiple"
                  multiple
                  value={filters.companies}
                  onChange={(e) => handleFilterChange("companies", e.target.value)}
                  input={<OutlinedInput label="Company" />}
                  MenuProps={MenuProps}
                  sx={{ height: "40px" }}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {filterOptions.companies.map((company) => (
                    <MenuItem
                      key={company}
                      value={company}
                      style={getStyles(company, filters.companies, theme)}
                      onClick={(e) => {
                        e.stopPropagation();
                        const newValue = filters.companies.includes(company)
                          ? filters.companies.filter((c) => c !== company)
                          : [...filters.companies, company];
                        handleFilterChange("companies", newValue);
                      }}
                    >
                      <ListItemText primary={company} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {filterOptions.units.length > 0 && (
              <FormControl sx={{ width: 200 }}>
                <InputLabel id="unit-multiple-label">Unit</InputLabel>
                <Select
                  labelId="unit-multiple-label"
                  id="unit-multiple"
                  multiple
                  value={filters.units}
                  onChange={(e) => handleFilterChange("units", e.target.value)}
                  input={<OutlinedInput label="Unit" />}
                  MenuProps={MenuProps}
                  sx={{ height: "40px" }}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {filters.companies.length > 0
                    ? filters.companies.map((company) => (
                        <React.Fragment key={company}>
                          <MenuItem
                            disabled
                            sx={{
                              opacity: 1,
                              fontWeight: "bold",
                              backgroundColor: "background.default",
                              pointerEvents: "none",
                            }}
                          >
                            {company}
                          </MenuItem>
                          {filterOptions.units
                            .filter((unit) => {
                              const userWithUnit = users.find(
                                (user) => user.unit?.name === unit && user.company?.name === company
                              );
                              return !!userWithUnit;
                            })
                            .map((unit) => (
                              <MenuItem
                                key={unit}
                                value={unit}
                                style={getStyles(unit, filters.units, theme)}
                                sx={{ pl: 4 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const newValue = filters.units.includes(unit)
                                    ? filters.units.filter((u) => u !== unit)
                                    : [...filters.units, unit];
                                  handleFilterChange("units", newValue);
                                }}
                              >
                                <ListItemText primary={unit} />
                              </MenuItem>
                            ))}
                        </React.Fragment>
                      ))
                    : filterOptions.units.map((unit) => (
                        <MenuItem
                          key={unit}
                          value={unit}
                          style={getStyles(unit, filters.units, theme)}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newValue = filters.units.includes(unit)
                              ? filters.units.filter((u) => u !== unit)
                              : [...filters.units, unit];
                            handleFilterChange("units", newValue);
                          }}
                        >
                          <ListItemText primary={unit} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            )}

            {filterOptions.departments.length > 0 && (
              <FormControl sx={{ width: 200 }}>
                <InputLabel id="department-multiple-label">Department</InputLabel>
                <Select
                  labelId="department-multiple-label"
                  id="department-multiple"
                  multiple
                  value={filters.departments}
                  onChange={(e) => handleFilterChange("departments", e.target.value)}
                  input={<OutlinedInput label="Department" />}
                  MenuProps={MenuProps}
                  sx={{ height: "40px" }}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {filters.companies.length > 0
                    ? filters.companies.map((company) => (
                        <React.Fragment key={company}>
                          <MenuItem
                            disabled
                            sx={{
                              opacity: 1,
                              fontWeight: "bold",
                              backgroundColor: "background.default",
                              pointerEvents: "none",
                            }}
                          >
                            {company}
                          </MenuItem>
                          {filterOptions.departments
                            .filter((department) => {
                              const userWithDepartment = users.find(
                                (user) =>
                                  user.department?.name === department &&
                                  user.company?.name === company
                              );
                              return !!userWithDepartment;
                            })
                            .map((department) => (
                              <MenuItem
                                key={department}
                                value={department}
                                style={getStyles(department, filters.departments, theme)}
                                sx={{ pl: 4 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const newValue = filters.departments.includes(department)
                                    ? filters.departments.filter((d) => d !== department)
                                    : [...filters.departments, department];
                                  handleFilterChange("departments", newValue);
                                }}
                              >
                                <ListItemText primary={department} />
                              </MenuItem>
                            ))}
                        </React.Fragment>
                      ))
                    : filterOptions.departments.map((department) => (
                        <MenuItem
                          key={department}
                          value={department}
                          style={getStyles(department, filters.departments, theme)}
                          onClick={(e) => {
                            e.stopPropagation();
                            const newValue = filters.departments.includes(department)
                              ? filters.departments.filter((d) => d !== department)
                              : [...filters.departments, department];
                            handleFilterChange("departments", newValue);
                          }}
                        >
                          <ListItemText primary={department} />
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            )}

            {filterOptions.roles.length > 0 && (
              <FormControl sx={{ width: 200 }}>
                <InputLabel id="role-multiple-label">Role</InputLabel>
                <Select
                  labelId="role-multiple-label"
                  id="role-multiple"
                  multiple
                  value={filters.roles}
                  onChange={(e) => handleFilterChange("roles", e.target.value)}
                  input={<OutlinedInput label="Role" />}
                  MenuProps={MenuProps}
                  sx={{ height: "40px" }}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {filterOptions.roles.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={getStyles(role, filters.roles, theme)}
                      onClick={(e) => {
                        e.stopPropagation();
                        const newValue = filters.roles.includes(role)
                          ? filters.roles.filter((r) => r !== role)
                          : [...filters.roles, role];
                        handleFilterChange("roles", newValue);
                      }}
                    >
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <MDButton
              variant="outlined"
              color="error"
              onClick={handleClearFilters}
              startIcon={<ClearIcon />}
              sx={{
                m: 1,
                height: "40px",
                marginLeft: "auto",
                minWidth: "22%",
              }}
              disabled={!Object.values(filters).some((array) => array.length > 0) && !searchQuery}
            >
              Clear All Filters
            </MDButton>
            <MDBox display="flex" width="100%" gap={2} mb={2}>
              <TextField
                fullWidth
                placeholder="Search by name or email"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchQuery ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchQuery("")} size="small">
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                  sx: { borderRadius: 2 },
                }}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Due Date"
                type="datetime-local"
                value={dueDate ? dueDate.toISOString().slice(0, 16) : ""}
                onChange={(e) => setDueDate(new Date(e.target.value))}
                sx={{ width: "30%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={!dueDate}
                helperText={!dueDate ? "Due date is required" : ""}
              />
            </MDBox>
          </MDBox>
        </Paper>

        <Box sx={{ width: "100%", height: "400px", mb: 2 }}>
          <DataGrid
            getRowId={(row) => row._id}
            rows={filteredAndSearchedUsers}
            columns={[
              {
                field: "serialNumber",
                headerName: "#",
                width: 60,
                valueGetter: (params) => filteredAndSearchedUsers.indexOf(params.row) + 1,
              },
              {
                field: "name",
                headerName: "Name",
                flex: 1,
                minWidth: 150,
              },
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                minWidth: 200,
              },
              {
                field: "company",
                headerName: "Company",
                flex: 1,
                minWidth: 150,
                valueGetter: (params) => params.row.company?.name || "-",
              },
              {
                field: "unit",
                headerName: "Unit",
                flex: 1,
                minWidth: 150,
                valueGetter: (params) => params.row.unit?.name || "-",
              },
              {
                field: "department",
                headerName: "Department",
                flex: 1,
                minWidth: 150,
                valueGetter: (params) => params.row.department?.name || "-",
              },
              {
                field: "role",
                headerName: "Role",
                flex: 1,
                minWidth: 200,
                valueGetter: (params) =>
                  params.row.role
                    ? `${params.row.role.name} (${params.row.role.accessLevel || "N/A"})`
                    : "-",
              },
            ]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newSelectionModel) => {
              const selectedUsers = filteredAndSearchedUsers.filter((user) =>
                newSelectionModel.includes(user._id)
              );
              setSelectedUsers(selectedUsers);
            }}
            rowSelectionModel={selectedUsers.map((user) => user._id)}
            loading={loading}
            sx={{
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
                borderRadius: 1,
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid",
                borderColor:
                  theme.palette.mode === "dark" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.05)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
            }}
          />
        </Box>

        {selectedUsers.length > 0 && (
          <Paper
            elevation={0}
            sx={{
              p: 2,
              backgroundColor:
                theme.palette.mode === "dark" ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
              borderRadius: 2,
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Selected Users ({selectedUsers.length})
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selectedUsers.map((user) => (
                <Chip
                  key={user._id}
                  label={user.name}
                  onDelete={() =>
                    setSelectedUsers((prev) => prev.filter((u) => u._id !== user._id))
                  }
                  color="primary"
                  variant="outlined"
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Paper>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: "1px solid",
          borderColor: theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)",
        }}
      >
        <MDButton
          onClick={() => setOpen(false)}
          color="secondary"
          variant="outlined"
          sx={{ borderRadius: 1, mr: 1 }}
          style={{ borderRadius: "5px", padding: "0.7rem" }}
        >
          Cancel
        </MDButton>
        <MDButton
          onClick={handleAssign}
          color="info"
          variant="gradient"
          disabled={loading || selectedUsers.length === 0}
          style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "primary.main" }} /> : null}
          Assign to {selectedUsers.length} user{selectedUsers.length !== 1 ? "s" : ""}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddTask;
