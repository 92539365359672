import { useState, useEffect } from "react";
import { Card, Switch, FormControlLabel, Grid, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDButton from "components/MDButton";
import ChangePasswordModal from "components/ChangePasswordModal";
import Cookies from "universal-cookie";
import { baseUrl, apiV1 } from "utils/constants";
import axios from "axios";
import Icon from "@mui/material/Icon";
import LogoutModal from "components/LogoutModal";
import useLogout from "hook/useLogout";
import LogoutIcon from "@mui/icons-material/Logout";

function Settings() {
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const cookies = new Cookies();
  const userRole = cookies.get("role");
  const { handleLogout, loading } = useLogout();

  const fetchUserInfo = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      const userId = cookies.get("userId");
      const response = await axios.get(`${baseUrl}${apiV1}/users/${userId}`, { headers });

      if (response.status === 200) {
        setUserInfo(response.data);

        if (response.data.role?.name === "ENTITY_ADMIN") {
          const settingsResponse = await axios.get(`${baseUrl}${apiV1}/companies/notifications`, {
            headers,
          });
          setEmailNotifications(settingsResponse.data.enabled);
        }
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleNotificationToggle = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      await axios.post(
        `${baseUrl}${apiV1}/companies/notifications`,
        { isEmailNotificationsEnabled: !emailNotifications },
        { headers }
      );
      setEmailNotifications(!emailNotifications);
    } catch (error) {
      console.error("Error updating notification settings:", error);
    }
  };

  const onLogout = async () => {
    await handleLogout();
    setLogoutModalOpen(false);
  };

  const InfoCard = ({ icon, title, value }) => (
    <Card sx={{ height: "100%" }}>
      <MDBox p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              borderRadius="lg"
              color="white"
              bgColor="info"
              variant="gradient"
            >
              <Icon fontSize="medium">{icon}</Icon>
            </MDBox>
          </Grid>
          <Grid item xs>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {title}
            </MDTypography>
            <MDTypography variant="h6">{value}</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );

  return (
    <DashboardLayout>
      <MDBox mt={3} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h4" fontWeight="medium">
                  Profile Settings
                </MDTypography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={3}>
                  {userInfo?.name && (
                    <Grid item xs={12} md={4}>
                      <InfoCard icon="person" title="Name" value={userInfo.name} />
                    </Grid>
                  )}
                  {userInfo?.email && (
                    <Grid item xs={12} md={4}>
                      <InfoCard icon="email" title="Email" value={userInfo.email} />
                    </Grid>
                  )}
                  {userInfo?.role?.name && (
                    <Grid item xs={12} md={4}>
                      <InfoCard icon="badge" title="Role" value={userInfo.role.name} />
                    </Grid>
                  )}
                  {userInfo?.company?.name && (
                    <Grid item xs={12} md={4}>
                      <InfoCard icon="business" title="Company" value={userInfo.company.name} />
                    </Grid>
                  )}
                  {userInfo?.unit?.name && (
                    <Grid item xs={12} md={4}>
                      <InfoCard icon="apartment" title="Unit" value={userInfo.unit.name} />
                    </Grid>
                  )}
                  {userInfo?.department?.name && (
                    <Grid item xs={12} md={4}>
                      <InfoCard
                        icon="group_work"
                        title="Department"
                        value={userInfo.department.name}
                      />
                    </Grid>
                  )}
                </Grid>
              </MDBox>
            </Card>
          </Grid>

          {userRole === "ENTITY_ADMIN" && (
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Notification Settings
                  </MDTypography>
                  <Divider sx={{ my: 2 }} />
                  <MDBox display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={emailNotifications}
                          onChange={handleNotificationToggle}
                          color="info"
                        />
                      }
                      label={
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          Enable email notifications for task completions
                        </MDTypography>
                      }
                    />
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          )}

          <Grid item xs={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Security Settings
                </MDTypography>
                <Divider sx={{ my: 2 }} />
                <MDBox display="flex" gap={2}>
                  <MDButton
                    onClick={() => setIsPasswordModalOpen(true)}
                    variant="gradient"
                    color="info"
                    startIcon={<Icon>lock</Icon>}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 'medium',
                      fontSize: '1rem',
                      py: 1
                    }}
                  >
                    Change Password
                  </MDButton>
                  <MDButton
                    onClick={() => setLogoutModalOpen(true)}
                    variant="gradient"
                    color="error"
                    startIcon={<LogoutIcon />}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 'medium',
                      fontSize: '1rem',
                      py: 1
                    }}
                  >
                    Logout
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />
      <LogoutModal
        open={logoutModalOpen}
        handleClose={() => setLogoutModalOpen(false)}
        handleLogout={onLogout}
        loading={loading}
      />
    </DashboardLayout>
  );
}

export default Settings;
