import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Chip,
  IconButton,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Badge,
  Tooltip,
  Paper,
  CircularProgress,
  Checkbox,
  Button,
  DialogActions,
  DialogContentText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ErrorIcon from "@mui/icons-material/Error";
import TextArea from "layouts/questionsList/textarea";
import TableComponent from "layouts/questionsList/table";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseUrl, apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import axios from "axios";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Custom Styled Components
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    maxWidth: 700,
    height: "100%",
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    boxShadow: theme.shadows[10],
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledCard = styled(Card)(({ theme, selected }) => ({
  borderRadius: 8,
  boxShadow: selected
    ? `0 0 0 1px ${theme.palette.primary.main}, ${theme.shadows[1]}`
    : theme.shadows[1],
  transition: "all 0.2s ease-in-out",
  overflow: "hidden",
  border: "none",
  backgroundColor: selected ? "rgba(25, 118, 210, 0.02)" : theme.palette.background.paper,
  "&:hover": {
    boxShadow: selected
      ? `0 0 0 1px ${theme.palette.primary.main}, ${theme.shadows[2]}`
      : theme.shadows[2],
    backgroundColor: selected ? "rgba(25, 118, 210, 0.04)" : "rgba(0, 0, 0, 0.01)",
  },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
  padding: 6,
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 12,
    boxShadow: theme.shadows[5],
  },
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[50],
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const TaskPreviewCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 6,
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  border: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    marginBottom: 0,
  },
}));

const UnassignButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.error.main} 30%, ${theme.palette.error.dark} 90%)`,
  color: theme.palette.error.contrastText,
  padding: theme.spacing(0.75, 2),
  borderRadius: 6,
  textTransform: "none",
  fontWeight: 500,
  "&:hover": {
    background: `linear-gradient(45deg, ${theme.palette.error.dark} 30%, ${theme.palette.error.dark} 90%)`,
  },
}));

const HeaderBox = styled(MDBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
}));

const DetailGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(1),
}));

const getStatusColor = (status) => {
  switch (status) {
    case "SUBMITTED":
      return { bg: "#E8F5E9", text: "#2E7D32", icon: <CheckCircleIcon /> }; // Green
    case "DRAFTED":
      return { bg: "#E3F2FD", text: "#1976D2", icon: <ScheduleIcon /> }; // Blue
    case "PENDING":
      return { bg: "#FFF8E1", text: "#FF8F00", icon: <ScheduleIcon /> }; // Amber
    case "OVERDUE":
      return { bg: "#FFEBEE", text: "#C62828", icon: <ErrorIcon /> }; // Red
    default:
      return { bg: "#ECEFF1", text: "#546E7A", icon: <ScheduleIcon /> }; // Blue Grey
  }
};

const StatusChip = styled(Chip)(({ theme, status }) => {
  const statusColors = getStatusColor(status);
  return {
    fontWeight: 600,
    backgroundColor: statusColors.bg,
    color: statusColors.text,
    "& .MuiChip-icon": {
      color: statusColors.text,
    },
  };
});

const AttachmentChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  "& .MuiChip-deleteIcon": {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const InfoItem = ({ icon, label, value }) => (
  <MDBox display="flex" alignItems="flex-start" mb={1}>
    <MDBox color="text.secondary" mr={1} mt={0.5}>
      {icon}
    </MDBox>
    <MDBox>
      <MDTypography variant="caption" color="text.secondary" fontWeight="regular" display="block">
        {label}
      </MDTypography>
      <MDTypography variant="body2" fontWeight="medium">
        {value}
      </MDTypography>
    </MDBox>
  </MDBox>
);

const ResponsesDrawer = ({ open, onClose, task, onTasksUpdate, onEnterData }) => {
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [unassignModalOpen, setUnassignModalOpen] = useState(false);
  const [updateDueDateModalOpen, setUpdateDueDateModalOpen] = useState(false);
  const [newDueDate, setNewDueDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState("");

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  // Reset state when drawer closes
  useEffect(() => {
    if (!open) {
      setSelectedResponse(null);
      setSelectedTasks([]);
      setUnassignModalOpen(false);
      setUpdateDueDateModalOpen(false);
      setNewDueDate(null);
      setAnswers("");
      setSelectedQuestion(null);
    }
  }, [open]);

  // Set answers when response is selected
  useEffect(() => {
    if (selectedResponse) {
      if (selectedResponse.customanswer) {
        setAnswers(selectedResponse.customanswer?.answerText);
      } else if (selectedResponse.answer) {
        setAnswers(selectedResponse.answer?.answerText);
      } else {
        setAnswers([]);
      }

      // Set selected question for form submission
      setSelectedQuestion(task?.question);
    }
  }, [selectedResponse, task]);

  const handleViewDetails = (taskItem) => {
    const formattedResponse = {
      _id: taskItem._id,
      assigner: taskItem.assigner,
      assignee: taskItem.assignee,
      company: task?.company,
      taskStatus: taskItem.taskStatus,
      question: task?.question,
      isAttachmentRequired: taskItem.isAttachmentRequired,
      dueDate: taskItem.dueDate,
      createdAt: taskItem.createdAt,
      updatedAt: taskItem.updatedAt,
      answer: taskItem.answer?.[0] || taskItem.answer,
      customanswer: taskItem.customanswer,
      attachments: taskItem.attachments || [],
    };
    setSelectedResponse(formattedResponse);
  };

  // Function to handle draft save
  const handleDraft = async () => {
    if (!selectedResponse || !selectedQuestion) return;

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      setIsLoading(true);
      const questionId = selectedQuestion._id || selectedQuestion.id;

      const res = await axios.post(
        baseUrl + apiV1 + `/answers/${questionId}`,
        {
          taskId: selectedResponse._id,
          answerText: answers,
          isDraft: true,
        },
        { headers }
      );

      if (res.status === 201) {
        dispatch(setAlert({ message: `Answer drafted successfully!`, color: "success" }));

        // Refresh tasks data
        if (onTasksUpdate) {
          await onTasksUpdate();
        }

        setSelectedResponse({
          ...selectedResponse,
          answer: res.data,
        });
      }
      onClose();
    } catch (e) {
      console.error("Error saving draft:", e);
      dispatch(
        setAlert({
          message: e.response?.data?.message || "Failed to save draft",
          color: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle submission
  const handleSubmit = async (bypassEnterData = false) => {
    // if (!selectedResponse || !selectedQuestion) return;

    if (!selectedQuestion?.parentQuestion || bypassEnterData) {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      try {
        setIsLoading(true);
        const questionId = selectedQuestion?._id || selectedQuestion?.id;

        if (!questionId) {
          console.error("No question ID found:", selectedQuestion);
          return;
        }

        const res = await axios.post(
          `${baseUrl}${apiV1}/answers/${questionId}`,
          {
            taskId: selectedResponse._id,
            answerText: answers,
          },
          { headers }
        );

        if (res.status === 201) {
          dispatch(setAlert({ message: `Answer recorded successfully!`, color: "success" }));

          // Refresh tasks data
          if (onTasksUpdate) {
            await onTasksUpdate();
          }

          // Update the selected response with new data
          setSelectedResponse({
            ...selectedResponse,
            answer: res.data,
          });
        }
        onClose();
      } catch (error) {
        console.error("Error submitting data:", error);
        dispatch(
          setAlert({
            message: error.response?.data?.message || "Failed to submit answer",
            color: "error",
          })
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      onEnterData?.();
    }
  };

  const handleDownload = async (attachment) => {
    try {
      setDownloading(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
      };

      const response = await axios.get(`${baseUrl}${apiV1}/attachments/${attachment._id}`, {
        headers,
        responseType: "blob",
      });

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", attachment.originalName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setDownloading(false);
    }
  };

  // Calculate days from now
  const getDaysFromNow = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffTime = date - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return `${Math.abs(diffDays)} days ago`;
    } else if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Tomorrow";
    } else {
      return `In ${diffDays} days`;
    }
  };

  // Get file icon based on extension
  const getFileIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "📄";
      case "doc":
      case "docx":
        return "📝";
      case "xls":
      case "xlsx":
        return "📊";
      case "ppt":
      case "pptx":
        return "📑";
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "🖼️";
      default:
        return "📎";
    }
  };

  // Function to handle task selection
  const handleTaskSelection = (taskId) => {
    setSelectedTasks((prev) => {
      if (prev.includes(taskId)) {
        return prev.filter((id) => id !== taskId);
      } else {
        return [...prev, taskId];
      }
    });
  };

  // Function to handle unassign tasks
  const handleUnassignTasks = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseUrl}${apiV1}/tasks/unassign`,
        { taskIds: selectedTasks },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Tasks unassigned successfully",
            type: "success",
          })
        );

        // Reset states and close drawer
        setSelectedTasks([]);
        setUnassignModalOpen(false);
        onClose();

        // Refresh tasks data
        if (onTasksUpdate) {
          await onTasksUpdate();
        }
      }
    } catch (error) {
      console.error("Error unassigning tasks:", error);
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Error unassigning tasks",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle due date update
  const handleUpdateDueDate = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseUrl}${apiV1}/tasks/update-due-date`,
        {
          taskIds: selectedTasks,
          dueDate: newDueDate,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Tasks due dates updated successfully",
            type: "success",
          })
        );

        // Reset states and close drawer
        setSelectedTasks([]);
        setUpdateDueDateModalOpen(false);
        setNewDueDate(null);
        onClose();

        // Refresh tasks data
        if (onTasksUpdate) {
          await onTasksUpdate();
        }
      }
    } catch (error) {
      console.error("Error updating due dates:", error);
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Error updating due dates",
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedResponse) {
      const answerText =
        selectedResponse.customanswer?.answerText || selectedResponse.answer?.answerText || "";
      setAnswers(answerText);
    } else {
      setAnswers("");
    }
  }, [selectedResponse]);

  return (
    <>
      <StyledDrawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: "50%" },
            minWidth: { xs: "100%", sm: 400 },
            maxWidth: "100%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            margin: 0,
            padding: 0,
          },
        }}
      >
        <MDBox
          height="100%"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: "#f8f9fa",
          }}
        >
          {/* Header */}
          <HeaderBox>
            <MDBox>
              <MDTypography variant="h5" fontWeight="bold">
                Responses
              </MDTypography>
              <MDTypography variant="caption" color="text.secondary">
                {task?.tasks?.length || 0} responses for this question
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" gap={2}>
              {selectedTasks.length > 0 && (
                <>
                  <MDButton
                    variant="contained"
                    color="info"
                    startIcon={<Icon>event</Icon>}
                    onClick={() => setUpdateDueDateModalOpen(true)}
                  >
                    Update Due Date ({selectedTasks.length})
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="info"
                    startIcon={<Icon>delete</Icon>}
                    onClick={() => setUnassignModalOpen(true)}
                  >
                    Unassign ({selectedTasks.length})
                  </MDButton>
                </>
              )}
              <IconButton
                onClick={onClose}
                size="small"
                sx={{
                  color: "text.secondary",
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.02)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </MDBox>
          </HeaderBox>

          {/* Question Details */}
          <MDBox bgcolor="background.paper" mb={2} borderRadius={1} mx={3} mt={3}>
            <MDTypography variant="subtitle1" fontWeight="bold" mb={1} color="primary.main">
              Question
            </MDTypography>
            <MDTypography variant="body2">{task?.question?.questionText}</MDTypography>
          </MDBox>

          {/* Responses List */}
          <MDBox px={3} py={2} flex={1} overflow="auto">
            {task?.tasks?.map((taskItem, index) => {
              const statusColors = getStatusColor(taskItem.taskStatus);
              const dueText = getDaysFromNow(taskItem.dueDate);
              const isOverdue =
                new Date(taskItem.dueDate) < new Date() && taskItem.taskStatus !== "COMPLETED";
              const isSelected = selectedTasks.includes(taskItem._id);

              return (
                <StyledCard key={taskItem._id} selected={isSelected} sx={{ mb: 2 }}>
                  <CardContent sx={{ p: 0 }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      p={2}
                      sx={{
                        backgroundColor: "transparent",
                        borderBottom: `1px solid ${(theme) => theme.palette.divider}`,
                      }}
                    >
                      <MDBox display="flex" alignItems="center" flex={1}>
                        <Avatar
                          sx={{
                            mr: 2,
                            width: 40,
                            height: 40,
                            bgcolor: taskItem.taskStatus === "COMPLETED" ? "#4caf50" : "#1976d2",
                          }}
                        >
                          {taskItem.assignee?.name?.charAt(0).toUpperCase()}
                        </Avatar>
                        <MDBox flex={1}>
                          <MDTypography variant="subtitle1" fontWeight="medium">
                            {taskItem.assignee?.name}
                          </MDTypography>
                          <MDTypography variant="caption" color="text.secondary">
                            {taskItem.assignee?.email}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" gap={2}>
                        <StatusChip
                          label={taskItem.taskStatus.replace("_", " ")}
                          status={taskItem.taskStatus}
                          size="small"
                          icon={statusColors.icon}
                        />
                        <StyledCheckbox
                          checked={isSelected}
                          onChange={() => handleTaskSelection(taskItem._id)}
                          icon={<Icon>check_box_outline_blank</Icon>}
                          checkedIcon={<Icon>check_box</Icon>}
                        />
                      </MDBox>
                    </MDBox>

                    {/* Task Details */}
                    <MDBox p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <InfoItem
                            icon={
                              <CalendarTodayIcon
                                fontSize="small"
                                color={isOverdue ? "error" : "inherit"}
                              />
                            }
                            label="Due Date"
                            value={
                              <>
                                {new Date(taskItem.dueDate).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                                <MDTypography
                                  variant="caption"
                                  color={isOverdue ? "error" : "text.secondary"}
                                  ml={1}
                                >
                                  ({dueText})
                                </MDTypography>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <InfoItem
                            icon={<EventNoteIcon fontSize="small" />}
                            label="Created At"
                            value={new Date(taskItem.createdAt).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          />
                        </Grid>
                      </Grid>

                      {/* Assigner Information */}
                      <Grid container spacing={2} mt={0.5}>
                        <Grid item xs={12}>
                          <InfoItem
                            icon={<PersonIcon fontSize="small" />}
                            label="Assigned By"
                            value={`${taskItem.assigner?.name} (${taskItem.assigner?.email})`}
                          />
                        </Grid>
                      </Grid>

                      {/* Attachments */}
                      {taskItem.isAttachmentRequired && (
                        <MDBox mt={1}>
                          <MDTypography
                            variant="caption"
                            color="text.secondary"
                            fontWeight="regular"
                          >
                            Attachments
                          </MDTypography>
                          {taskItem.attachments?.length > 0 ? (
                            <MDBox
                              mt={1}
                              display="flex"
                              flexWrap="wrap"
                              gap={0.5}
                              alignItems="center"
                            >
                              {taskItem.attachments.map((attachment, i) => (
                                <Chip
                                  key={i}
                                  size="small"
                                  label={attachment.originalName || `File ${i + 1}`}
                                  icon={<AttachmentIcon fontSize="small" />}
                                  sx={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    maxWidth: 200,
                                    "& .MuiChip-label": {
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    },
                                  }}
                                />
                              ))}
                            </MDBox>
                          ) : (
                            <MDTypography variant="body2" color="text.secondary">
                              No attachments uploaded
                            </MDTypography>
                          )}
                        </MDBox>
                      )}
                    </MDBox>

                    {/* View action - Always visible now */}
                    <MDBox
                      p={1}
                      display="flex"
                      justifyContent="flex-end"
                      bgcolor="rgba(0, 0, 0, 0.02)"
                      borderTop={`1px solid ${(theme) => theme.palette.divider}`}
                    >
                      <Chip
                        icon={<VisibilityIcon fontSize="small" />}
                        label="View Details"
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={() => handleViewDetails(taskItem)}
                        sx={{
                          fontSize: "0.75rem",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.08)",
                          },
                        }}
                      />
                    </MDBox>
                  </CardContent>
                </StyledCard>
              );
            })}
          </MDBox>
        </MDBox>
      </StyledDrawer>

      {/* Unassign Tasks Confirmation Modal */}
      <StyledDialog
        open={unassignModalOpen}
        onClose={() => !isLoading && setUnassignModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ p: 3 }}>
          <MDTypography variant="h6" fontWeight="bold">
            Confirm Unassign Tasks
          </MDTypography>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <MDBox mb={4}>
            <MDTypography sx={{ py: 2 }} variant="body2" color="text.secondary">
              Are you sure you want to unassign {selectedTasks.length} task(s)? This action cannot
              be undone.
            </MDTypography>
          </MDBox>
          <MDBox sx={{ "& > div": { mb: 2 } }}>
            {selectedTasks.map((taskId) => {
              const taskItem = task?.tasks?.find((t) => t._id === taskId);
              return taskItem ? (
                <TaskPreviewCard key={taskId}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                    <MDTypography variant="subtitle2" fontWeight="medium">
                      {taskItem.assignee?.name}
                    </MDTypography>
                    <Chip
                      label={getDaysFromNow(taskItem.dueDate)}
                      size="small"
                      icon={<CalendarTodayIcon fontSize="small" />}
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        "& .MuiChip-icon": {
                          color: "inherit",
                        },
                      }}
                    />
                  </MDBox>
                  <MDTypography variant="body2" color="text.secondary">
                    {task?.question?.questionText}
                  </MDTypography>
                </TaskPreviewCard>
              ) : null;
            })}
          </MDBox>
        </DialogContent>
        <DialogActions sx={{ p: 3, bgcolor: "background.default" }}>
          <Button
            onClick={() => setUnassignModalOpen(false)}
            sx={{
              textTransform: "none",
              color: "text.secondary",
              px: 3,
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleUnassignTasks}
            startIcon={
              isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Icon>delete_forever</Icon>
              )
            }
            disabled={isLoading}
            sx={{ px: 3 }}
          >
            {isLoading ? "Unassigning..." : "Confirm Unassign"}
          </MDButton>
        </DialogActions>
      </StyledDialog>

      {/* Update Due Date Modal */}
      <StyledDialog
        open={updateDueDateModalOpen}
        onClose={() => !isLoading && setUpdateDueDateModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ p: 3 }}>
          <MDTypography variant="h6" fontWeight="bold">
            Update Due Date
          </MDTypography>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <MDBox mb={4}>
            <MDTypography sx={{ py: 2 }} variant="body2" color="text.secondary">
              Select a new due date for {selectedTasks.length} task(s).
            </MDTypography>
          </MDBox>

          <MDBox mb={4}>
            <TextField
              type="datetime-local"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 1,
                  height: "48px",
                },
              }}
              value={newDueDate}
              onChange={(e) => setNewDueDate(e.target.value)}
              fullWidth
            />
          </MDBox>

          <MDBox sx={{ "& > div": { mb: 2 } }}>
            {selectedTasks.map((taskId) => {
              const taskItem = task?.tasks?.find((t) => t._id === taskId);
              return taskItem ? (
                <TaskPreviewCard key={taskId}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                    <MDTypography variant="subtitle2" fontWeight="medium">
                      {taskItem.assignee?.name}
                    </MDTypography>
                    <Chip
                      label={`Current: ${new Date(taskItem.dueDate).toLocaleDateString()}`}
                      size="small"
                      icon={<CalendarTodayIcon fontSize="small" />}
                      sx={{
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        "& .MuiChip-icon": {
                          color: "inherit",
                        },
                      }}
                    />
                  </MDBox>
                </TaskPreviewCard>
              ) : null;
            })}
          </MDBox>
        </DialogContent>
        <DialogActions sx={{ p: 3, bgcolor: "background.default" }}>
          <Button
            onClick={() => setUpdateDueDateModalOpen(false)}
            sx={{
              textTransform: "none",
              color: "text.secondary",
              px: 3,
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleUpdateDueDate}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : <Icon>update</Icon>
            }
            disabled={isLoading || !newDueDate}
            sx={{ px: 3 }}
          >
            {isLoading ? "Updating..." : "Update Due Date"}
          </MDButton>
        </DialogActions>
      </StyledDialog>

      {/* Response Details Modal */}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={Boolean(selectedResponse)}
        onClose={() => setSelectedResponse(null)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogTitle sx={{ px: 3, py: 2, bgcolor: "background.paper" }}>
          <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
              <MDTypography variant="h5" fontWeight="bold">
                Response Details
              </MDTypography>
              <MDTypography variant="caption" color="text.secondary">
                {selectedResponse?.assignee?.name} •{" "}
                {new Date(selectedResponse?.createdAt).toLocaleString()}
              </MDTypography>
            </MDBox>
            <IconButton onClick={() => setSelectedResponse(null)} size="small">
              <CloseIcon />
            </IconButton>
          </MDBox>
        </DialogTitle>
        <DialogContent sx={{ px: 3, py: 3 }}>
          {selectedResponse && (
            <>
              <div>
                <MDTypography variant="subtitle1" fontWeight="bold" mb={1}>
                  Question
                </MDTypography>
                <MDTypography variant="body2" mb={3} color="text.secondary">
                  {task?.question?.questionText}
                </MDTypography>

                {task?.question?.questionType === "text" && (
                  <TextArea answers={answers} setAnswers={setAnswers} readOnly={false} />
                )}

                {(task?.question?.questionType === "table" ||
                  task?.question?.questionType === "formulae") && (
                  <TableComponent
                    questionId={task?.question?.key}
                    columns={task?.question?.columns}
                    rows={task?.question?.rows}
                    tableSections={task?.question?.table_sections}
                    formulae={task?.question?.formulae}
                    questionType={task?.question?.questionType}
                    answers={answers}
                    setAnswers={setAnswers}
                    readOnly={false}
                  />
                )}

                {/* Action buttons for updating answers */}
                <MDBox display="flex" justifyContent="flex-end" mt={3} gap={2}>
                  <MDButton
                    onClick={handleDraft}
                    variant="outlined"
                    color="secondary"
                    disabled={isLoading}
                  >
                    {isLoading ? "Saving..." : "Save as draft"}
                  </MDButton>

                  <MDButton
                    onClick={() => {
                      handleSubmit();
                    }}
                    variant="gradient"
                    color="info"
                  >
                    {selectedQuestion?.parentQuestion
                      ? "Enter Data"
                      : selectedResponse.answer
                      ? "Update"
                      : "Submit"}
                  </MDButton>
                  {selectedQuestion?.parentQuestion && (
                    <MDButton
                      onClick={() => handleSubmit(true)}
                      variant="gradient"
                      color="info"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : selectedResponse.answer ? "Update" : "Submit"}
                    </MDButton>
                  )}
                </MDBox>
              </div>

              {/* Attachments Section */}
              {selectedResponse.attachments?.length > 0 && (
                <MDBox mt={4}>
                  <MDTypography variant="subtitle1" fontWeight="bold" mb={2}>
                    Attachments
                  </MDTypography>
                  <MDBox display="flex" gap={1} flexWrap="wrap">
                    {selectedResponse.attachments.map((attachment, index) => (
                      <AttachmentChip
                        key={attachment._id}
                        icon={
                          <Box component="span" mr={0.5}>
                            {getFileIcon(attachment.originalName)}
                          </Box>
                        }
                        label={attachment.originalName}
                        onClick={() => handleDownload(attachment)}
                        onDelete={() => handleDownload(attachment)}
                        deleteIcon={
                          downloading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <Tooltip title="Download">
                              <GetAppIcon fontSize="small" />
                            </Tooltip>
                          )
                        }
                        disabled={downloading}
                      />
                    ))}
                  </MDBox>
                </MDBox>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResponsesDrawer;
