import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Redux and authentication
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import { getSidebarItems } from "sidebar";

// Authentication and Layout Components
import AuthValidator from "layouts/AuthValidator";
import AlertProvider from "components/AlertProvider";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Import specific route components
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ResetPassword from "layouts/authentication/reset-password/cover";
import NotFound from "layouts/notfound";
import VerifyEmail from "layouts/authentication/verify-email";

import { useMaterialUIController } from "context";
import { getAuthorizedRoutes } from "routes";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const cookies = new Cookies();
  const accessLevel = cookies.get("accessLevel");

  // Dynamically generate sidebar items based on access level
  const sidebarItems = useMemo(() => getSidebarItems(accessLevel || "USER"), [accessLevel]);

  // Dynamically generate authorized routes based on access level
  const authorizedRoutes = useMemo(() => getAuthorizedRoutes(accessLevel), [accessLevel]);

  // Sidenav mouse enter/leave handlers
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting direction and scroll effects
  useEffect(() => {
    document.body.setAttribute("dir", direction);
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [direction, pathname]);

  // Route generation helper
  const getRoutes = (routes) =>
    routes.map((route) =>
      route.route ? <Route key={route.key} path={route.route} element={route.component} /> : null
    );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <AlertProvider>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="BrsrTool"
              routes={sidebarItems}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}

        <Routes>
          {/* Dynamically generated authorized routes */}
          {getRoutes(authorizedRoutes)}

          {/* Authentication Routes */}
          <Route path="/authentication/sign-in" element={<SignIn />} />
          <Route path="/authentication/sign-up" element={<SignUp />} />
          <Route path="/authentication/forgot-password" element={<ResetPassword />} />
          <Route path="/authentication/reset-password" element={<ResetPassword />} />
          <Route path="/authentication/verify-email" element={<VerifyEmail />} />

          {/* Default and 404 Routes */}
          <Route
            path="/"
            element={
              accessLevel ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/authentication/sign-in" />
              )
            }
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </AlertProvider>
    </ThemeProvider>
  );
}
