import { useEffect, useState, useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";
import { FormControlLabel, Switch } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Components
import ConfirmDialog from "components/MDDialog/ConfirmDialog";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import AddCompany from "./AddCompany";
import CommonTable from "../CommonTable";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";

function CompanyTable({
  saveData,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  count,
  setCount,
  setPage,
  page,
}) {
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedItemsEdit, setSelectedItemsEdit] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    company: [],
    unit: [],
    department: [],
  });

  const cookies = new Cookies();

  const columns = [
    { field: "srNo", headerName: "Sr No", flex: 0.1, pinnable: true },
    { field: "name", headerName: "Company", flex: 0.2 },
    { field: "location", headerName: "Location", flex: 0.1 },
    { field: "description", headerName: "Description", flex: 0.3 },
    { 
      field: "notifications", 
      headerName: "Enable Notifications", 
      flex: 0.2,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Switch
              checked={params.row.isEmailNotificationsEnabled ?? false}
              onChange={() => handleNotificationToggle(params.row)}
              color="info"
            />
          }
          label=""
        />
      )
    },
    { field: "status", headerName: "Status", flex: 0.15, renderCell: (params) => params.value },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.15,
      sortable: false,
      renderCell: (params) => (
        <MDBox display="flex" gap={1}>
          <Icon
            sx={{ cursor: "pointer", color: "info.main" }}
            onClick={() => {
              setSelectedItemsEdit(params.row.originalData);
              setModalType("Edit");
              setOpen(true);
            }}
          >
            edit
          </Icon>
          <Icon
            sx={{ cursor: "pointer", color: "error.main" }}
            onClick={() => setSelectedItems(params.row.originalData)}
          >
            delete
          </Icon>
        </MDBox>
      ),
    },
  ];

  const handleNotificationToggle = async (company) => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      await axios.patch(
        `${baseUrl}${apiV1}/companies/${company.id}`,
        { 
          isEmailNotificationsEnabled: !company.isEmailNotificationsEnabled 
        },
        { headers }
      );

      // Refresh the companies data
      fetchCompanies();
    } catch (error) {
      console.error("Error updating notification settings:", error);
    }
  };

  const filteredRows = useMemo(() => {
    let filtered = Array.isArray(saveData) ? saveData : saveData?.data || [];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered.map((item, index) => ({
      id: item._id || item.id,
      srNo: (page - 1) * 10 + index + 1,
      name: item.name,
      location: item.location,
      description: item.description,
      isEmailNotificationsEnabled: item.isEmailNotificationsEnabled ?? false,
      status: (
        <MDTypography
          variant="caption"
          color={item.isActive ? "success" : "error"}
          fontWeight="medium"
        >
          {item.isActive ? "Active" : "Inactive"}
        </MDTypography>
      ),
      actions: (
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="text" color="info" onClick={() => handleEdit(item)}>
            <Icon>edit</Icon>
          </MDButton>
          <MDButton variant="text" color="error" onClick={() => handleDelete(item)}>
            <Icon>delete</Icon>
          </MDButton>
        </MDBox>
      ),
      originalData: {
        ...item,
        id: item._id || item.id  
      },
    }));
  }, [saveData, searchTerm, page]);

  // Remove server-side search effect
  useEffect(() => {
    fetchCompanies();
  }, [page]); // Only fetch on page change

  const fetchCompanies = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      const res = await axios.get(
        `${baseUrl}${apiV1}/companies?limit=10&page=${page}${
          searchTerm ? `&search=${searchTerm}` : ""
        }`,
        { headers }
      );

      if (res.status === 200) {
        setSaveData(res.data || []);
        setCount(res.data.totalPages || 1);
      }
    } catch (e) {
      console.error("Error fetching companies:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (item) => {
    setSelectedItemsEdit(item);
    setModalType("Edit");
    setOpen(true);
  };

  const handleDelete = (item) => {
    setSelectedItems(item);
  };

  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6">Companies</MDTypography>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setOpen(true);
              setModalType("Add");
              setSelectedItemsEdit(null);
            }}
            startIcon={<AddIcon />}
          >
            Company
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox>
        <CommonTable
          columns={columns}
          rows={filteredRows}
          loading={loading}
          filters={filters}
          setFilters={setFilters}
          onSearchChange={setSearchTerm}
          setSelectedRows={setSelectedRows}
          filterOptions={{
            companies: [],
            units: [],
            departments: [],
          }}
        />
      </MDBox>

      <AddCompany
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={() => {
          if (onSuccessPost) onSuccessPost();
          fetchCompanies();
        }}
        modalType={modalType}
        selectedCompany={selectedItemsEdit}
      />

      <ConfirmDialog
        isOpen={selectedItems !== null}
        onClose={() => setSelectedItems(null)}
        onDeleteSuccess={() => {
          console.log(`Company ${selectedItems?.name} deleted successfully.`);
          fetchCompanies();
        }}
        name={selectedItems?.name}
        userId={selectedItems?._id}
        saveData={saveData}
        setSaveData={setSaveData}
        entityType="company"
      />
    </Card>
  );
}

export default CompanyTable;