import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import Cookies from "universal-cookie";
import { baseUrl, apiV1 } from "utils/constants";
import LockIcon from '@mui/icons-material/Lock';

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const cookies = new Cookies();
      
      const response = await fetch(baseUrl + apiV1 + "/auth/change-password", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currentPassword,
          newPassword,
        }),
      });

      if (response.ok) {
        dispatch(setAlert({ message: "Password changed successfully", color: "success" }));
        onClose();
      } else {
        const error = await response.json();
        dispatch(setAlert({ message: error.message || "Failed to change password", color: "error" }));
      }
    } catch (error) {
      dispatch(setAlert({ message: "An error occurred", color: "error" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={isOpen}
      onClose={onClose}
      aria-labelledby="change-password-dialog"
      PaperProps={{
        style: {
          borderRadius: "10px",
        }
      }}
    >
      <div className="modal-dialog modal-confirm">
        <div
          className="modal-content"
          style={{
            padding: "20px",
            borderRadius: "10px",
            border: "none",
            textAlign: "center",
            fontSize: "14px",
            minWidth: "400px",
          }}
        >
          <div className="modal-header flex-column">
           
            <h4
              className="modal-title w-100"
              style={{ 
                textAlign: "center", 
                fontSize: "24px", 
                margin: "35px 0 15px",
                color: "#333",
                fontWeight: "500",
              }}
            >
              Change Password
            </h4>
          </div>

          <MDBox p={3} pt={0}>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Current Password"
                fullWidth
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                sx={{ mt: 2 }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="New Password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </MDBox>
          </MDBox>

          <div
            className="modal-footer justify-content-center"
            style={{
              border: "none",
              textAlign: "center",
              borderRadius: "10px",
              fontSize: "13px",
              padding: "10px 15px 25px",
            }}
          >
            <Button
              style={{
                borderRadius: "8px",
                backgroundColor: "#e0e0e0",
                padding: "8px 25px",
                color: "#666",
                fontSize: "14px",
                marginRight: "12px",
                textTransform: "none",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "#d5d5d5",
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                borderRadius: "8px",
                backgroundColor: "#1A73E8",
                padding: "8px 25px",
                color: "white",
                fontSize: "14px",
                marginLeft: "12px",
                textTransform: "none",
                fontWeight: "500",
                boxShadow: "0 2px 6px rgba(26, 115, 232, 0.3)",
                "&:hover": {
                  backgroundColor: "#1557b0",
                },
              }}
              onClick={handleSubmit}
              disabled={loading || !currentPassword || !newPassword}
            >
              Change Password
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangePasswordModal; 