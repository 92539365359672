/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm } from "react-hook-form";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import Cookies from "universal-cookie";
import { setUser } from "reduxToolkit/user/userSlice";
import useLogin from "hook/useLogin";

function Basic() {
  const cookies = new Cookies();
  const [rememberMe, setRememberMe] = useState(false);
  const { handleLogin, loading } = useLogin();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = (data) => {
    handleLogin({
      email: data.email,
      password: data.password,
      onSuccess: (role) => (role === "admin" ? navigate("/users") : navigate("/tasks")),
    });
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                fullWidth
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors?.email ? (
                <MDTypography variant="button" color="error">
                  {errors?.email?.message}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                fullWidth
                {...register("password", {
                  required: "Password is required", // Add required validation
                })}
              />
              {errors?.password ? (
                <MDTypography variant="button" color="error">
                  {errors?.password?.message}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={1.5}>
              <MDTypography
                component={Link}
                to="/authentication/forgot-password"
                variant="button"
                fontWeight="regular"
                color="info"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                Forgot Password?
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={handleSubmit(submit)}
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
