import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Typography,
  Card,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TableChartIcon from "@mui/icons-material/TableChart";
import FunctionsIcon from "@mui/icons-material/Functions";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import TableComponent from "layouts/questionsList/table";
import axios from "axios";
import Cookies from "universal-cookie";
import { baseUrl, apiV1 } from "utils/constants";
import CreatableSelect from 'react-select/creatable';
import useGetQuestionAttributes from "hook/useGetQuestionAttributes";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

const AddQuestion = ({ open, setOpen, editQuestion, setEditQuestion }) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [currentView, setCurrentView] = useState("basic");
  const [activeTab, setActiveTab] = useState(0);
  const [jsonInput, setJsonInput] = useState("");
  const [previewData, setPreviewData] = useState(null);
  const [error, setError] = useState("");
  const [tableAnswers, setTableAnswers] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});

  const [formData, setFormData] = useState({
    section: "",
    part: "",
    dept: "",
    group: "",
    questionText: "",
    questionDesc: "",
    questionType: "",
    isCustomField: false,
    columns: [],
    rows: [],
    table_sections: [],
    answers: {},
    formulae: {},
    repetative_cell_data: {},
    parentQuestion: '',
  });

  const { loading: attributesLoading, handleGetAttributes } = useGetQuestionAttributes();
  const [sections, setSections] = useState([]);
  const [parts, setParts] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    // Fetch sections
    handleGetAttributes({
      attributeType: 'sections',
      onSuccess: (data) => setSections(data.data || []),
    });

    // Fetch parts
    handleGetAttributes({
      attributeType: 'parts',
      onSuccess: (data) => setParts(data.data || []),
    });

    // Fetch departments
    handleGetAttributes({
      attributeType: 'departments',
      onSuccess: (data) => setDepartments(data.data || []),
    });

    // Fetch groups
    handleGetAttributes({
      attributeType: 'groups',
      onSuccess: (data) => setGroups(data.data || []),
    });

    if (editQuestion) {
      // Set form data for basic details
      setFormData({
        section: editQuestion.section,
        part: editQuestion.part,
        dept: editQuestion.dept,
        group: editQuestion.group || '',
        questionText: editQuestion.questionText,
        questionDesc: editQuestion.questionDesc || '',
        questionType: editQuestion.questionType,
        isCustomField: editQuestion.isCustomField || false,
        parentQuestion: editQuestion.parentQuestion || '',
      });

      // For table/formulae questions, prepare the JSON input
      if (editQuestion.questionType === 'table' || editQuestion.questionType === 'formulae') {
        const jsonData = {
          columns: editQuestion.columns || [],
          rows: editQuestion.rows || [],
          table_sections: editQuestion.table_sections || [],
          formulae: editQuestion.formulae || {},
          repetative_cell_data: editQuestion.repetative_cell_data || {},
        };
        setJsonInput(JSON.stringify(jsonData, null, 2));
        setPreviewData(jsonData);
      }

      // Start with basic details view
      setCurrentView('basic');
    }
  }, [editQuestion]);

  const requiredStar = {
    color: 'red',
    marginLeft: '4px',
  };

  const validateBasicDetails = () => {
    let isValid = true;
    const newErrors = {
      section: !formData.section,
      part: !formData.part,
      dept: !formData.dept,
      questionText: !formData.questionText
    };

    setFieldErrors(newErrors);

    if (Object.values(newErrors).some(error => error)) {
      dispatch(setAlert({ message: "Please fill in all required fields", color: "error" }));
      return false;
    }

    return true;
  };

  const handleQuestionTypeSelect = (type) => {
    setFormData({ ...formData, questionType: type });
    setCurrentView("details");
    setJsonInput("");
    setPreviewData(null);
    setError("");
    setTableAnswers([]);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentView("basic");
    setActiveTab(0);
    setJsonInput("");
    setPreviewData(null);
    setError("");
    setTableAnswers([]);
    setFieldErrors({});
    setFormData({
      section: '',
      part: '',
      dept: '',
      group: '',
      questionText: '',
      questionDesc: '',
      questionType: '',
      isCustomField: false,
      parentQuestion: '',
    });
    setEditQuestion(null); // Reset edit state
  };

  const handleJsonChange = (e) => {
    setJsonInput(e.target.value);
    setError("");
  };

  const generatePreview = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      

      if (formData.questionType === "table") {
        if (!parsedData.columns || !parsedData.rows) {
          throw new Error("Table data must include 'columns' and 'rows'");
        }
      } else if (formData.questionType === "formulae") {
        if (!parsedData.columns || !parsedData.rows || !parsedData.formulae) {
          throw new Error("Formulae data must include 'columns', 'rows', and 'formulae'");
        }
      }


      const answers = Array(parsedData.rows.length).fill({});

      setPreviewData(parsedData);
      setTableAnswers(answers);
      setFormData((prev) => ({
        ...prev,
        columns: parsedData.columns,
        rows: parsedData.rows,
        table_sections: parsedData.table_sections || [],
        answers: answers,
        formulae: parsedData.formulae || {},
        repetative_cell_data: parsedData.repetative_cell_data || {},
      }));
      setError("");
      setActiveTab(1);
    } catch (err) {
      setError(err.message);
      setPreviewData(null);
    }
  };

  const handleSubmit = async () => {
    if (!validateBasicDetails()) return;

    const finalData = {
      section: formData.section,
      part: formData.part,
      dept: formData.dept,
      questionText: formData.questionText,
      questionType: formData.questionType,
    };

    // Only add optional fields if they have values
    if (formData.group) finalData.group = formData.group;
    if (formData.questionDesc) finalData.questionDesc = formData.questionDesc;
    if (formData.parentQuestion) finalData.parentQuestion = Number(formData.parentQuestion);
    if (formData.isCustomField) finalData.isCustomField = formData.isCustomField;

    if (formData.questionType === "table") {
      if (previewData?.columns?.length) finalData.columns = previewData.columns;
      if (previewData?.rows?.length) finalData.rows = previewData.rows;
      if (previewData?.table_sections?.length) finalData.table_sections = previewData.table_sections;
    } else if (formData.questionType === "formulae") {
      if (previewData?.columns?.length) finalData.columns = previewData.columns;
      if (previewData?.rows?.length) finalData.rows = previewData.rows;
      if (previewData?.table_sections?.length) finalData.table_sections = previewData.table_sections;
      if (Object.keys(previewData?.formulae || {}).length) finalData.formulae = previewData.formulae;
      if (Object.keys(previewData?.repetative_cell_data || {}).length) {
        finalData.repetative_cell_data = previewData.repetative_cell_data;
      }
    }

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    try {
      let res;
      
      if (editQuestion) {
        res = await axios.patch(
          `${baseUrl}${apiV1}/questions/${editQuestion._id}`,
          finalData,
          { headers }
        );
      } else {
        res = await axios.post(
          `${baseUrl}${apiV1}/questions`,
          {
            data: [finalData], 
          },
          { headers }
        );
      }

      if (res.status === 200 || res.status === 201) {
        dispatch(setAlert({ 
          message: editQuestion 
            ? "Question updated successfully!" 
            : "Question added successfully!", 
          color: "success" 
        }));
        handleClose();
      }
    } catch (error) {
      console.error("Error saving question:", error);
      dispatch(
        setAlert({
          message: error.response?.data?.message || `Error ${editQuestion ? 'updating' : 'adding'} question`,
          color: "error",
        })
      );
    }
  };

  const renderContent = () => {
    switch (currentView) {
      case "basic":
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Enter Basic Question Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Section<span style={requiredStar}>*</span>
                </Typography>
                {editQuestion ? (
                  <TextField
                    fullWidth
                    value={formData.section}
                    onChange={(e) => {
                      setFormData({ ...formData, section: e.target.value });
                      setFieldErrors(prev => ({ ...prev, section: false }));
                    }}
                    error={fieldErrors.section}
                  />
                ) : (
                  <CreatableSelect
                    isClearable
                    options={sections.map(section => ({ label: section, value: section }))}
                    value={formData.section ? { label: formData.section, value: formData.section } : null}
                    onChange={(newValue) => {
                      setFormData({ ...formData, section: newValue?.value || '' });
                      setFieldErrors(prev => ({ ...prev, section: false }));
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Part<span style={requiredStar}>*</span>
                </Typography>
                {editQuestion ? (
                  <TextField
                    fullWidth
                    value={formData.part}
                    onChange={(e) => {
                      setFormData({ ...formData, part: e.target.value });
                      setFieldErrors(prev => ({ ...prev, part: false }));
                    }}
                    error={fieldErrors.part}
                  />
                ) : (
                  <CreatableSelect
                    isClearable
                    options={parts.map(part => ({ label: part, value: part }))}
                    value={formData.part ? { label: formData.part, value: formData.part } : null}
                    onChange={(newValue) => {
                      setFormData({ ...formData, part: newValue?.value || '' });
                      setFieldErrors(prev => ({ ...prev, part: false }));
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Department<span style={requiredStar}>*</span>
                </Typography>
                {editQuestion ? (
                  <TextField
                    fullWidth
                    value={formData.dept}
                    onChange={(e) => {
                      setFormData({ ...formData, dept: e.target.value });
                      setFieldErrors(prev => ({ ...prev, dept: false }));
                    }}
                    error={fieldErrors.dept}
                  />
                ) : (
                  <CreatableSelect
                    isClearable
                    options={departments.map(dept => ({ label: dept, value: dept }))}
                    value={formData.dept ? { label: formData.dept, value: formData.dept } : null}
                    onChange={(newValue) => {
                      setFormData({ ...formData, dept: newValue?.value || '' });
                      setFieldErrors(prev => ({ ...prev, dept: false }));
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Group (Optional)
                </Typography>
                {editQuestion ? (
                  <TextField
                    fullWidth
                    value={formData.group}
                    onChange={(e) => setFormData({ ...formData, group: e.target.value })}
                  />
                ) : (
                  <CreatableSelect
                    isClearable
                    options={groups.map(group => ({ label: group, value: group }))}
                    value={formData.group ? { label: formData.group, value: formData.group } : null}
                    onChange={(newValue) => setFormData({ ...formData, group: newValue?.value || '' })}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Parent Question (Optional)
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  value={formData.parentQuestion}
                  onChange={(e) => setFormData({ ...formData, parentQuestion: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Custom Field
                </Typography>
                {editQuestion ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.isCustomField || false}
                        onChange={(e) => setFormData({ ...formData, isCustomField: e.target.checked })}
                      />
                    }
                    label={formData.isCustomField ? "Yes" : "No"}
                  />
                ) : (
                  <CreatableSelect
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    styles={{
                      container: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: 14,
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 1000000,
                        fontSize: 15,
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: fieldErrors.isCustomField ? 'red' : baseStyles.borderColor,
                        '&:hover': {
                          borderColor: fieldErrors.isCustomField ? 'red' : baseStyles.borderColor,
                        }
                      }),
                    }}
                    isClearable
                    onChange={(newValue) => {
                      setFormData({ ...formData, isCustomField: newValue?.value === 'true' });
                      setFieldErrors(prev => ({ ...prev, isCustomField: false }));
                    }}
                    options={[
                      { value: 'true', label: 'True' },
                      { value: 'false', label: 'False' }
                    ]}
                    value={
                      formData.isCustomField !== undefined
                        ? { 
                            value: String(formData.isCustomField), 
                            label: String(formData.isCustomField) 
                          }
                        : null
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Question Title<span style={requiredStar}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  error={fieldErrors.questionText}
                  value={formData.questionText}
                  onChange={(e) => {
                    setFormData({ ...formData, questionText: e.target.value });
                    setFieldErrors(prev => ({ ...prev, questionText: false }));
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: fieldErrors.questionText ? 'red' : 'inherit',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" mb={1}>
                  Question Description (Optional)
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.questionDesc}
                  onChange={(e) => setFormData({ ...formData, questionDesc: e.target.value })}
                />
              </Grid>
            </Grid>
          </Box>
        );

      case "type":
        return !editQuestion ? (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Select Question Type
            </Typography>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={4}>
                <Card
                  sx={{
                    p: 2,
                    cursor: "pointer",
                    "&:hover": { bgcolor: "action.hover" },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleQuestionTypeSelect("text")}
                >
                  <TextFieldsIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography>Text Question</Typography>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  sx={{
                    p: 2,
                    cursor: "pointer",
                    "&:hover": { bgcolor: "action.hover" },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleQuestionTypeSelect("table")}
                >
                  <TableChartIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography>Table Question</Typography>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card
                  sx={{
                    p: 2,
                    cursor: "pointer",
                    "&:hover": { bgcolor: "action.hover" },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleQuestionTypeSelect("formulae")}
                >
                  <FunctionsIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography>Formulae Question</Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : null;

      case "details":
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              {formData.questionType === "text" && "Text Question Details"}
              {formData.questionType === "table" && "Configure Table Question"}
              {formData.questionType === "formulae" && "Define Formula Question"}
            </Typography>

            {formData.questionType === "text" ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Question Details:</Typography>
                  <Box sx={{ pl: 2, mt: 1 }}>
                    <Typography>Section: {formData.section}</Typography>
                    <Typography>Part: {formData.part}</Typography>
                    <Typography>Department: {formData.dept}</Typography>
                    {formData.group && <Typography>Group: {formData.group}</Typography>}
                    <Typography>Question Title: {formData.questionText}</Typography>
                    <Typography>Description: {formData.questionDesc}</Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box>
                <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                  sx={{ mb: 3 }}
                >
                  <Tab label="JSON Input" />
                  <Tab label="Preview" />
                  <Tab label="Final Data" />
                </Tabs>

                <TabPanel value={activeTab} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={20}
                        label="Enter JSON"
                        value={jsonInput}
                        onChange={handleJsonChange}
                        error={!!error}
                        helperText={error}
                        sx={{ fontFamily: "monospace" }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDButton onClick={generatePreview} color="info" sx={{ float: "right" }}>
                        Generate Preview
                      </MDButton>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={activeTab} index={1}>
                  {previewData && (
                    <TableComponent
                      columns={previewData.columns}
                      rows={previewData.rows}
                      answers={tableAnswers}
                      setAnswers={setTableAnswers}
                      tableSections={previewData.table_sections}
                      formulae={previewData.formulae}
                      repetative_cell_data={previewData.repetative_cell_data}
                      questionType={formData.questionType}
                    />
                  )}
                </TabPanel>

                <TabPanel value={activeTab} index={2}>
                  <Typography variant="subtitle1">Basic Details:</Typography>
                  <Box sx={{ pl: 2, mb: 2 }}>
                    <Typography>Section: {formData.section}</Typography>
                    <Typography>Part: {formData.part}</Typography>
                    <Typography>Department: {formData.dept}</Typography>
                    <Typography> Group: {formData.group}</Typography>
                    <Typography>Question Title: {formData.questionText}</Typography>
                    <Typography>Description: {formData.questionDesc}</Typography>
                  </Box>
                  
                  <Typography variant="subtitle1">Table Data:</Typography>
                  <Box sx={{ pl: 2 }}>
                    {previewData && (
                      <pre style={{ overflow: 'auto' }}>
                        {JSON.stringify(previewData, null, 2)}
                      </pre>
                    )}
                  </Box>
                </TabPanel>
              </Box>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  // Modify navigation logic for edit mode
  const handleBasicDetailsNext = () => {
    if (validateBasicDetails()) {
      if (editQuestion) {
        // When editing, go straight to details
        setCurrentView('details');
        // For table/formulae questions, show the JSON tab
        if (editQuestion.questionType !== 'text') {
          setActiveTab(0); // Set to JSON input tab
        }
      } else {
        // For new questions, go to type selection
        setCurrentView('type');
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Typography variant="h6">
          {editQuestion ? 'Edit Question' : 'Add New Question'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", mt: 2 }}>{renderContent()}</Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <MDButton onClick={handleClose} color="info">
          Cancel
        </MDButton>
        {currentView === "basic" && (
          <MDButton onClick={handleBasicDetailsNext} color="info">
            Next
          </MDButton>
        )}
        {currentView === "type" && !editQuestion && (
          <MDButton variant="outlined" color="info" onClick={() => setCurrentView("basic")}>
            Back
          </MDButton>
        )}
        {currentView === "details" && (
          <>
            <MDButton variant="outlined" color="info" onClick={() => setCurrentView("basic")}>
              Back
            </MDButton>
            <MDButton onClick={handleSubmit} color="success">
              {editQuestion ? 'Update' : 'Submit'}
            </MDButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddQuestion;