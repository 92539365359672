import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "components/MDBox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useState, useMemo } from "react";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedItems, theme) {
  return {
    fontWeight:
      selectedItems.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelectTable({
  saveData,
  setSelectedRows,
  onSuccessPost,
  setOpenQuestionModal,
  setEditQuestion,
  setSelectedQuestion,
}) {
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedQuestionForDelete, setSelectedQuestionForDelete] = useState(null);
  const cookies = new Cookies();
  const theme = useTheme();

  // Add this line to get access level
  const accessLevel = cookies.get("accessLevel");

  // Filter states
  const [filters, setFilters] = useState({
    sections: [],
    parts: [],
    questionTypes: [],
    attachmentRequired: [],
  });

  // Enhanced filterOptions to be dynamic based on current selections
  const filterOptions = useMemo(() => {
    const data = saveData?.[0]?.details || [];

    // Get unique sections
    const sections = [...new Set(data.map((item) => item.section))];

    // Create a map of sections to their parts
    const partsBySection = sections.reduce((acc, section) => {
      acc[section] = [
        ...new Set(data.filter((item) => item.section === section).map((item) => item.part)),
      ];
      return acc;
    }, {});

    // Filter data based on selected sections and parts
    let filteredData = data;
    if (filters.sections.length > 0) {
      filteredData = filteredData.filter((item) => filters.sections.includes(item.section));
    }
    if (filters.parts.length > 0) {
      filteredData = filteredData.filter((item) => filters.parts.includes(item.part));
    }

    // Get question types from filtered data
    const questionTypes = [...new Set(filteredData.map((item) => item.questionType))];

    // Get attachment required options from filtered data
    const attachmentRequired = [
      ...new Set(filteredData.map((item) => (item.isAttachmentRequired ? "Yes" : "No"))),
    ].sort();

    return {
      sections,
      partsBySection,
      questionTypes,
      attachmentRequired,
    };
  }, [saveData, filters.sections, filters.parts]);

  // Get available parts based on selected sections
  const availableParts = useMemo(() => {
    if (filters.sections.length === 0) {
      // If no sections selected, show all parts
      return Object.values(filterOptions.partsBySection).flat();
    }
    // Only show parts from selected sections
    return filters.sections.map((section) => filterOptions.partsBySection[section]).flat();
  }, [filters.sections, filterOptions.partsBySection]);

  // Handle filter changes
  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  // Clear all filters
  const handleClearFilters = () => {
    setFilters({
      sections: [],
      parts: [],
      questionTypes: [],
      attachmentRequired: [],
    });
  };

  // Filter the data
  const filteredRows = useMemo(() => {
    let rows = saveData?.[0]?.details || [];

    if (filters.sections.length > 0) {
      rows = rows.filter((row) => filters.sections.includes(row.section));
    }
    if (filters.parts.length > 0) {
      rows = rows.filter((row) => filters.parts.includes(row.part));
    }
    if (filters.questionTypes.length > 0) {
      rows = rows.filter((row) => filters.questionTypes.includes(row.questionType));
    }
    if (filters.attachmentRequired.length > 0) {
      rows = rows.filter((row) => {
        const isRequired = row.isAttachmentRequired ? "Yes" : "No";
        return filters.attachmentRequired.includes(isRequired);
      });
    }

    return rows;
  }, [saveData, filters]);

  const handleDelete = (question) => {
    setSelectedQuestionForDelete(question);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const token = cookies.get("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.delete(
        `${baseUrl}${apiV1}/questions/${selectedQuestionForDelete._id}`,
        config
      );

      if (response.status === 200) {
        dispatch(
          setAlert({
            color: "success",
            message: "Question deleted successfully",
          })
        );
        onSuccessPost();
        setDeleteModalOpen(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          type: "error",
          message: error.response?.data?.message || "Failed to delete question",
        })
      );
    }
  };

  const handleEdit = (question) => {
    setEditQuestion(question);
    setOpenQuestionModal(true);
  };

  const columns = [
    {
      field: "questionText",
      headerName: "Question",
      flex: 1,
      minWidth: 350,
      renderCell: (params) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setSelectedQuestion(params.row);
          }}
          color="primary"
          sx={{
            display: "block",
            textAlign: "left",
            justifyContent: "flex-start",
            textTransform: "none",
            width: "100%",
            padding: "8px 0",
          }}
        >
          {params.row.questionText}
        </Button>
      ),
    },
    {
      field: "section",
      headerName: "Section",
      width: 200,
    },
    {
      field: "part",
      headerName: "Part",
      width: 150,
    },
    { field: "dept", headerName: "Department", width: 150 },
    {
      field: "questionType",
      headerName: "Question Type",
      width: 150,
      renderCell: (params) => (
        <p style={{ textTransform: "capitalize" }}>{params.row.questionType}</p>
      ),
    },
    {
      field: "isAttachmentRequired",
      headerName: "Attachment Required",
      width: 160,
      renderCell: (params) => (
        <Chip
          label={params.row.isAttachmentRequired ? "Required" : "Not Required"}
          color={params.row.isAttachmentRequired ? "primary" : "default"}
          size="small"
        />
      ),
    },
    {
      field: "key",
      headerName: "Key",
      width: 100,
    },
    // Modify the actions column to only show if accessLevel is L0
    ...(accessLevel === "L0"
      ? [
          {
            field: "actions",
            headerName: "Action",
            width: 120,
            renderCell: (params) => (
              <MDBox display="flex" gap={1}>
                <IconButton
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(params.row);
                  }}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(params.row);
                  }}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <MDBox p={2} display="flex" flexWrap="wrap" gap={2} alignItems="center" position="relative">
        <FormControl sx={{ m: 1, width: 270 }}>
          <InputLabel id="section-multiple-label">Section</InputLabel>
          <Select
            labelId="section-multiple-label"
            id="section-multiple"
            multiple
            value={filters.sections}
            onChange={(e) => handleFilterChange("sections", e.target.value)}
            input={<OutlinedInput label="Section" />}
            MenuProps={MenuProps}
            sx={{ height: "40px" }}
          >
            {filterOptions.sections.map((section) => (
              <MenuItem
                key={section}
                value={section}
                style={getStyles(section, filters.sections, theme)}
              >
                {section}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 270 }}>
          <InputLabel id="part-multiple-label">Part</InputLabel>
          <Select
            labelId="part-multiple-label"
            id="part-multiple"
            multiple
            value={filters.parts}
            onChange={(e) => handleFilterChange("parts", e.target.value)}
            input={<OutlinedInput label="Part" />}
            MenuProps={MenuProps}
            sx={{ height: "40px" }}
            renderValue={(selected) => selected.join(", ")}
          >
            {filters.sections.length > 0
              ? // Show parts grouped by selected sections
                filters.sections.map((section) => (
                  <React.Fragment key={section}>
                    <MenuItem
                      disabled
                      sx={{
                        opacity: 1,
                        fontWeight: "bold",
                        backgroundColor: "background.default",
                        pointerEvents: "none",
                      }}
                    >
                      {section}
                    </MenuItem>
                    {filterOptions.partsBySection[section].map((part) => (
                      <MenuItem
                        key={part}
                        value={part}
                        style={getStyles(part, filters.parts, theme)}
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newValue = filters.parts.includes(part)
                            ? filters.parts.filter((p) => p !== part)
                            : [...filters.parts, part];
                          handleFilterChange("parts", newValue);
                        }}
                      >
                        {part}
                      </MenuItem>
                    ))}
                  </React.Fragment>
                ))
              : // Show all parts grouped by sections when no section is selected
                Object.entries(filterOptions.partsBySection).map(([section, parts]) => (
                  <React.Fragment key={section}>
                    <MenuItem
                      disabled
                      sx={{
                        opacity: 1,
                        fontWeight: "bold",
                        backgroundColor: "background.default",
                        pointerEvents: "none",
                      }}
                    >
                      {section}
                    </MenuItem>
                    {parts.map((part) => (
                      <MenuItem
                        key={part}
                        value={part}
                        style={getStyles(part, filters.parts, theme)}
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          const newValue = filters.parts.includes(part)
                            ? filters.parts.filter((p) => p !== part)
                            : [...filters.parts, part];
                          handleFilterChange("parts", newValue);
                        }}
                      >
                        {part}
                      </MenuItem>
                    ))}
                  </React.Fragment>
                ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 270 }}>
          <InputLabel id="type-multiple-label">Question Type</InputLabel>
          <Select
            labelId="type-multiple-label"
            id="type-multiple"
            multiple
            value={filters.questionTypes}
            onChange={(e) => handleFilterChange("questionTypes", e.target.value)}
            input={<OutlinedInput label="Question Type" />}
            MenuProps={MenuProps}
            sx={{ height: "40px" }}
            renderValue={(selected) => selected.join(", ")}
          >
            {filterOptions.questionTypes.map((type) => (
              <MenuItem
                key={type}
                value={type}
                style={getStyles(type, filters.questionTypes, theme)}
                onClick={(e) => {
                  e.stopPropagation();
                  const newValue = filters.questionTypes.includes(type)
                    ? filters.questionTypes.filter((t) => t !== type)
                    : [...filters.questionTypes, type];
                  handleFilterChange("questionTypes", newValue);
                }}
              >
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 270 }}>
          <InputLabel id="attachment-multiple-label">Attachment Required</InputLabel>
          <Select
            labelId="attachment-multiple-label"
            id="attachment-multiple"
            multiple
            value={filters.attachmentRequired}
            onChange={(e) => handleFilterChange("attachmentRequired", e.target.value)}
            input={<OutlinedInput label="Attachment Required" />}
            MenuProps={MenuProps}
            sx={{ height: "40px" }}
            renderValue={(selected) => selected.join(", ")}
          >
            {filterOptions.attachmentRequired.map((option) => (
              <MenuItem
                key={option}
                value={option}
                style={getStyles(option, filters.attachmentRequired, theme)}
                onClick={(e) => {
                  e.stopPropagation();
                  const newValue = filters.attachmentRequired.includes(option)
                    ? filters.attachmentRequired.filter((o) => o !== option)
                    : [...filters.attachmentRequired, option];
                  handleFilterChange("attachmentRequired", newValue);
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Clear Filters Button */}
        <MDButton
          variant="outlined"
          color="error"
          onClick={handleClearFilters}
          startIcon={<ClearIcon />}
          sx={{
            m: 1,
            height: "40px",
            marginLeft: "auto", // Push to the right
          }}
          disabled={!Object.values(filters).some((array) => array.length > 0)}
        >
          Clear All Filters
        </MDButton>
      </MDBox>

      <div
        style={{
          width: "100%",
          height: "calc(100vh - 300px)",
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <DataGrid
          getRowId={(item) => item?._id}
          rows={[...filteredRows].sort((a, b) => a.key - b.key)}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={(rows) => setSelectedRows(rows)}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflow: "auto !important",
            },
          }}
        />
      </div>

      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this question?</DialogContent>
        <DialogActions>
          <MDButton onClick={() => setDeleteModalOpen(false)} color="info">
            Cancel
          </MDButton>
          <MDButton onClick={handleConfirmDelete} color="error">
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
