import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useForm } from "react-hook-form";
import useConfirmNewPassword from "hook/useConfirmNewPassword";
import { useNavigate, Link } from "react-router-dom";

const ConfirmNewPasswordFields = ({ page, setPage }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { handleConfirmNewPassword, loading } = useConfirmNewPassword();
  
  const submit = (data) => {
    handleConfirmNewPassword({
      email: data.email,
      otp: data.otp,
      newPassword: data.newPassword,
      onSuccess: () => navigate("/authentication/sign-in"),
    });
  };

  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Reset Password
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={4}>
            <MDInput
              type="email"
              label="Email"
              variant="standard"
              fullWidth
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            {errors?.email && (
              <MDTypography variant="button" color="error">
                {errors.email.message}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mb={4}>
            <MDInput
              type="text"
              label="OTP"
              variant="standard"
              fullWidth
              {...register("otp", {
                required: "OTP is required",
                pattern: {
                  value: /^\d{6}$/,
                  message: "Enter a valid 6-digit OTP",
                },
              })}
            />
            {errors?.otp && (
              <MDTypography variant="button" color="error">
                {errors.otp.message}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mb={4}>
            <MDInput
              type="password"
              label="New Password"
              variant="standard"
              fullWidth
              {...register("newPassword", {
                required: "Password is required",
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                  message: "Password must meet complexity requirements",
                },
              })}
            />
            {errors?.newPassword && (
              <MDTypography variant="button" color="error">
                {errors.newPassword.message}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mt={6} mb={1}>
            <MDButton 
              variant="gradient" 
              color="info" 
              fullWidth 
              onClick={handleSubmit(submit)}
              disabled={loading}
            >
              Reset Password
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Back to Login
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ConfirmNewPasswordFields;
