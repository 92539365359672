export const getFileType = (filename) => {
  if (!filename) return "unknown";
  
  const extension = filename.split('.').pop().toLowerCase();
  
  if (['pdf'].includes(extension)) return "pdf";
  if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension)) return "image";
  if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'].includes(extension)) return "document";
  
  return "unknown";
}; 