import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Cookies from "universal-cookie";

// Import components
import CompanyTable from "./components/Company/CompanyTable";
import UnitTable from "./components/Unit/UnitTable";
import DepartmentTable from "./components/Department/DepartmentTable";
import UserTable from "./components/User/UserTable";
import AddUser from "./components/User/AddUser";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`master-tabpanel-${index}`}
      aria-labelledby={`master-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox>{children}</MDBox>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `master-tab-${index}`,
    "aria-controls": `master-tabpanel-${index}`,
  };
}

const Master = () => {
  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const cookies = new Cookies();

  // Get both role and accessLevel
  const userRole = cookies.get("role");
  const accessLevel = cookies.get("accessLevel");

  // States for individual tabs
  const [companiesData, setCompaniesData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  // Modal states
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [unitModalOpen, setUnitModalOpen] = useState(false);
  const [departmentModalOpen, setDepartmentModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  // Count states for pagination
  const [companyCount, setCompanyCount] = useState(1);
  const [unitCount, setUnitCount] = useState(1);
  const [departmentCount, setDepartmentCount] = useState(1);
  const [userCount, setUserCount] = useState(1);

  // When fetching units and departments, ensure they include their parent references
  const [units, setUnits] = useState([]);
  const [departments, setDepartments] = useState([]);

  // Define available tabs based on access level
  const getAvailableTabs = () => {
    switch (accessLevel) {
      case "L0":
        return ["Company", "Unit", "Department", "User"];
      case "L1":
        return ["Unit", "Department", "User"];
      case "L2":
        return ["Department", "User"];
      case "L3":
        return ["User"];
      default:
        return []; // Return empty array if no valid access level
    }
  };

  // Replace the static availableTabs with the dynamic function
  const availableTabs = getAvailableTabs();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
    setSearchQuery("");
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1);
  };

  // Reset page when tab changes
  useEffect(() => {
    setPage(1);
  }, [tabValue]);

  // Update these states to use the data from tables
  useEffect(() => {
    setUnits(unitsData); // Use the data from UnitTable
    setDepartments(departmentsData); // Use the data from DepartmentTable
  }, [unitsData, departmentsData]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        <MDBox mb={3}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="master data tabs"
            textColor="primary"
            indicatorColor="primary"
          >
            {availableTabs.map((tab, index) => (
              <Tab key={tab} label={tab} {...a11yProps(index)} />
            ))}
          </Tabs>
        </MDBox>
        {availableTabs.includes("Company") && (
          <TabPanel value={tabValue} index={availableTabs.indexOf("Company")}>
            <CompanyTable
              saveData={companiesData}
              setSaveData={setCompaniesData}
              handleSearchChange={handleSearchChange}
              open={companyModalOpen}
              setOpen={setCompanyModalOpen}
              onSuccessPost={() => console.log("Company added successfully")}
              count={companyCount}
              setCount={setCompanyCount}
              setPage={setPage}
              page={page}
            />
          </TabPanel>
        )}

        {availableTabs.includes("Unit") && (
          <TabPanel value={tabValue} index={availableTabs.indexOf("Unit")}>
            <UnitTable
              saveData={unitsData}
              setSaveData={setUnitsData}
              handleSearchChange={handleSearchChange}
              open={unitModalOpen}
              setOpen={setUnitModalOpen}
              onSuccessPost={() => console.log("Unit added successfully")}
              count={unitCount}
              setCount={setUnitCount}
              setPage={setPage}
              page={page}
              companies={companiesData}
            />
          </TabPanel>
        )}

        {availableTabs.includes("Department") && (
          <TabPanel value={tabValue} index={availableTabs.indexOf("Department")}>
            <DepartmentTable
              saveData={departmentsData}
              setSaveData={setDepartmentsData}
              handleSearchChange={handleSearchChange}
              open={departmentModalOpen}
              setOpen={setDepartmentModalOpen}
              onSuccessPost={() => console.log("Department added successfully")}
              count={departmentCount}
              setCount={setDepartmentCount}
              setPage={setPage}
              page={page}
              companies={companiesData}
              units={unitsData}
            />
          </TabPanel>
        )}

        {availableTabs.includes("User") && (
          <TabPanel value={tabValue} index={availableTabs.indexOf("User")}>
            <UserTable
              saveData={usersData}
              setSaveData={setUsersData}
              handleSearchChange={handleSearchChange}
              open={userModalOpen}
              setOpen={setUserModalOpen}
              onSuccessPost={() => console.log("User added successfully")}
              count={userCount}
              setCount={setUserCount}
              setPage={setPage}
              page={page}
              companies={companiesData}
              units={unitsData}
              departments={departmentsData}
            />
          </TabPanel>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Master;
