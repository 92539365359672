import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { baseUrl, apiV1 } from "utils/constants";

function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);

  // Get email from URL parameters
  const searchParams = new URLSearchParams(location.search);
  const emailFromUrl = searchParams.get("email");

  const [formData, setFormData] = useState({
    email: emailFromUrl || "",
    code: "",
  });

  useEffect(() => {
    let timer;
    if (resendCooldown > 0) {
      timer = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendCooldown]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(`${baseUrl}${apiV1}/auth/confirm-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Verification failed");
      }

      dispatch(
        setAlert({
          message: "Email verified successfully! Please check your email for your password.",
          type: "success",
        })
      );
      navigate("/authentication/sign-in");
    } catch (error) {
      dispatch(
        setAlert({
          message: error.message || "Verification failed",
          type: "error",
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendCode = async () => {
    if (resendCooldown > 0) return;

    try {
      const response = await fetch(`${baseUrl}${apiV1}/auth/resend-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to resend verification code");
      }

      dispatch(
        setAlert({
          message: "Verification code has been resent to your email",
          type: "success",
        })
      );
      setResendCooldown(60); // Start 60-second cooldown
    } catch (error) {
      dispatch(
        setAlert({
          message: error.message || "Failed to resend verification code",
          type: "error",
        })
      );
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Verify Your Email
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter the verification code sent to your email
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                disabled={!!emailFromUrl}
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="text"
                label="Verification Code"
                name="code"
                value={formData.code}
                onChange={handleChange}
                fullWidth
                required
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Verifying..." : "Verify Email"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Didn't receive the code?{" "}
                <MDTypography
                  component="button"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={handleResendCode}
                  sx={{ 
                    cursor: resendCooldown > 0 ? "not-allowed" : "pointer",
                    opacity: resendCooldown > 0 ? 0.7 : 1,
                  }}
                >
                  {resendCooldown > 0
                    ? `Resend in ${resendCooldown}s`
                    : "Resend"}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default VerifyEmail; 