import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import { baseUrl, apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";

const useLogout = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const handleLogout = async () => {
    try {
      setLoading(true);
      // Get refreshToken from cookies instead of localStorage
      const refreshToken = cookies.get("refreshToken");
      
      await axios.post(`${baseUrl}${apiV1}/auth/logout`, {
        refreshToken: refreshToken
      });
      
      // Clear all cookies
      cookies.remove("accessToken");
      cookies.remove("refreshToken");
      cookies.remove("accessLevel");
      cookies.remove("role");
      cookies.remove("token");
      cookies.remove("csrftoken");
      cookies.remove("ext_name");
      
      // Redirect to login
      navigate("/authentication/sign-in");
      
      dispatch(setAlert({
        message: "Logged out successfully",
        type: "success",
      }));
    } catch (error) {
      dispatch(setAlert({
        message: error.response?.data?.message || "Error logging out",
        type: "error",
      }));
    } finally {
      setLoading(false);
    }
  };

  return { handleLogout, loading };
};

export default useLogout; 