import { useEffect, useState, useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Components
import AddUnit from "./AddUnit";
import ConfirmDialog from "components/MDDialog/ConfirmDialog";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import CommonTable from "../CommonTable";

function UnitTable({
  saveData,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  count,
  setPage,
  page,
  companies,
  setCount
}) {
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    companies: [],
    units: [],
    departments: []
  });
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedItemsEdit, setSelectedItemsEdit] = useState(null);
  const [modalType, setModalType] = useState(null);

  const cookies = new Cookies();

  useEffect(() => {
    fetchUnits();
  }, [page]);

  const fetchUnits = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      const res = await axios.get(`${baseUrl}${apiV1}/units?limit=10&page=${page}`, { headers });
      if (res.status === 200) {
        setSaveData(res.data || []);
        setCount(res.data.totalPages || 1);
      }
    } catch (e) {
      console.error("Error fetching units:", e);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Unit",
      flex: 1,
      sortable: true,
    },
    {
      field: "companyName",
      headerName: "Company",
      flex: 1,
      sortable: true,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 0.8,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      sortable: true,
      renderCell: (params) => (
        <MDTypography
          variant="caption"
          color={params.row.isActive ? "success" : "error"}
          fontWeight="medium"
        >
          {params.row.isActive ? "Active" : "Inactive"}
        </MDTypography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      sortable: false,
      renderCell: (params) => (
        <MDBox display="flex" gap={1}>
          <Icon
            sx={{ cursor: "pointer", color: "info.main" }}
            onClick={() => {
              setSelectedItemsEdit(params.row.originalData);
              setModalType("Edit");
              setOpen(true);
            }}
          >
            edit
          </Icon>
          <Icon
            sx={{ cursor: "pointer", color: "error.main" }}
            onClick={() => setSelectedItems(params.row.originalData)}
          >
            delete
          </Icon>
        </MDBox>
      ),
    },
  ];
  

  const filteredRows = useMemo(() => {
    let filtered = Array.isArray(saveData) ? saveData : saveData?.data || [];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(item =>
        item.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.company?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply company filter
    if (filters.companies.length > 0) {
      filtered = filtered.filter(item => {
        return item.company && filters.companies.includes(item.company._id);
      });
    }

    return filtered.map((item, index) => ({
      id: item.id || item._id,
      srNo: (page - 1) * 10 + index + 1,
      name: item.name || '',
      companyName: item.company?.name || "-",
      location: item.location || '',
      description: item.description || '',
      isActive: item.isActive,
      originalData: item,
    }));
  }, [saveData, searchTerm, filters, page]);

  return (
    <Card>
      <MDBox p={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h6">Units</MDTypography>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setOpen(true);
              setModalType("Add");
            }}
            startIcon={<AddIcon />}
          >
            Unit
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox>
        <CommonTable
          columns={columns}
          rows={filteredRows}
          loading={loading}
          filters={filters}
          setFilters={setFilters}
          onSearchChange={setSearchTerm}
          setSelectedRows={setSelectedRows}
          filterOptions={{
            companies,
            units: [],
            departments: [],
          }}
          showCompanyFilter
          page={page}
          setPage={setPage}
          rowCount={count}
        />
      </MDBox>

      <AddUnit
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={() => {
          onSuccessPost();
          fetchUnits();
        }}
        modalType={modalType}
        selectedUnit={selectedItemsEdit}
        companies={companies}
      />

      <ConfirmDialog
        isOpen={selectedItems !== null}
        onClose={() => setSelectedItems(null)}
        onDeleteSuccess={() => {
          console.log(`Unit ${selectedItems?.name} deleted successfully.`);
          fetchUnits();
        }}
        name={selectedItems?.name}
        userId={selectedItems?.id}
        saveData={saveData}
        setSaveData={setSaveData}
        entityType="unit"
      />
    </Card>
  );
}

export default UnitTable;
