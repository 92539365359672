/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'

// import filterIcon

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";
import MDButton from "components/MDButton";
import AddFile from "./AddFile";
import ViewLogs from "./viewLogs";
import userData from "./data/userData";
import { useLocation, useNavigate } from "react-router-dom";
import projectsList from "./data/projectsList";
import ConfirmDialog from "components/MDDialog/ConfirmDialog";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";

function Projects({
  saveData,
  dataType = null,
  setSaveData,
  handleSearchChange,
  open,
  setOpen,
  onSuccessPost,
  count,
  setPage,
  page,
  isDashboard = false,
  isAdmin = false,
  title = "Users",
}) {
  const [openErrorLogs, setOpenErrorLogs] = useState(false);
  const [newCompanies, setFetchCompanies] = useState([]);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [selectedItemsEdit, setSelectedItemsEdit] = useState(null);
  const [companies, setCompanies] = useState([]);

  const [modalType, setModalType] = useState(null);
  const userId = selectedItems?.id;
  const name = selectedItems?.name;
  const filterId = saveData.filter((item) => item.id === userId);
  const filterData = saveData.filter((item) => item);
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const navigate = useNavigate();
  const [projectName, setProjectName] = useState("");
  const onNameClick = (id) => {
    navigate(`/users/${id}`);
  };
  const router = useLocation();
  const { columns, rows } = data(
    saveData,
    setSelectedItems,
    setOpen,
    setSelectedItemsEdit,
    setModalType
  );
  const [menu, setMenu] = useState(null);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (searchText) => {
    handleSearchChange(searchText);
  };
  useEffect(() => {
    setFetchCompanies((prevCompanies) => {
      const newCompanies = saveData.map((item) => item.company);
      return newCompanies;
    });

    const timer = setTimeout(() => {
      handleSearch(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText, saveData]);
  const cookies = new Cookies();

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );
  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const fetchCompanies = async () => {
      try {
        const res = await axios.get(baseUrl + apiV1 + "/companies", { headers });
        if (res.status === 200) {
          setCompanies(res.data);
        } else {
          dispatch(setAlert({ message: res.data.message, color: "error" }));
        }
      } catch (e) {
        dispatch(setAlert({ message: e.response.data.message, color: "error" }));
      }
    };

    fetchCompanies();
  }, []);
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h6" gutterBottom>
            {title}
          </MDTypography>
        </MDBox>

        <MDBox display="flex" alignItems="center">
          {/* {!isDashboard && (
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )} */}

          <MDBox color="text" px={2}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setOpen(true);
                setModalType("Add");
              }}
              style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
              startIcon={<AddIcon />}
            >
              User
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          count={count}
          pageNumber={page}
          setPage={setPage}
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          isDashboard={isDashboard}
        />
      </MDBox>
      <AddFile
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={onSuccessPost}
        modalType={modalType}
        companies={companies}
        filterData={filterData}
        selectedUser={selectedItemsEdit}
      />

      {/* <AddFile
        open={open}
        setOpen={setOpen}
        saveData={saveData}
        setSaveData={setSaveData}
        onSuccessPost={onSuccessPost}
      /> */}

      <ConfirmDialog
        isOpen={selectedItems !== null}
        onClose={() => setSelectedItems(null)}
        onDeleteSuccess={({ userId, name }) => {
          console.log(`User ${name} with ID ${userId} deleted successfully.`);
        }}
        name={selectedItems?.name}
        userId={selectedItems?.id}
        filterId={filterId}
        saveData={saveData}
        setSaveData={setSaveData}
      />

      {/* <ViewLogs open={openErrorLogs} setOpen={setOpenErrorLogs} projectName={projectName} /> */}
    </Card>
  );
}

export default Projects;
