import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import AuthValidator from "layouts/AuthValidator";
import AdminDashboard from "layouts/Admin/AdminDashboard";
import AdminFields from "layouts/Admin/AdminFields";
import AdminTasks from "layouts/Admin/AdminTasks";
import DocumentLibrary from "layouts/documentLibrary";
import Master from "layouts/master";
import AssignedTasks from "layouts/assignedTasks";
import { hasPermission } from "config/roles";
import Survey from "layouts/Survey";
import Settings from "layouts/settings";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
// Define all potential sidebar items with their access requirements
const allSidebarItems = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon fontSize="small" />,
    route: "/dashboard",
    component: (
      <AuthValidator>
        <AdminDashboard />
      </AuthValidator>
    ),
    accessKey: "dashboard",
    visibleToRoles: ["L0", "L1", "L2", "L3", "L4"],
  },
  {
    type: "collapse",
    name: "Modules",
    key: "modules",
    icon: <ViewModuleIcon fontSize="small" />,
    route: "/modules",
    component: (
      <AuthValidator>
        <AdminFields />
      </AuthValidator>
    ),
    accessKey: "modules",
    visibleToRoles: ["L0", "L1"],
  },
  {
    type: "collapse",
    name: "Manage Tasks",
    key: "tasks",
    icon: <AssignmentIcon fontSize="small" />,
    route: "/tasks",
    component: (
      <AuthValidator>
        <AdminTasks />
      </AuthValidator>
    ),
    accessKey: "tasks",
    visibleToRoles: ["L0", "L1"],
  },
  {
    type: "collapse",
    name: "Assigned Tasks",
    key: "assigned-tasks",
    icon: <TaskAltIcon fontSize="small" />,
    route: "/assigned-tasks",
    component: (
      <AuthValidator>
        <AssignedTasks />
      </AuthValidator>
    ),
    accessKey: "assignedTasks",
    visibleToRoles: ["L1", "L2", "L3", "L4"],
  },
  {
    type: "collapse",
    name: "Document Library",
    key: "document-library",
    icon: <LibraryBooksIcon fontSize="small" />,
    route: "/document-library",
    component: (
      <AuthValidator>
        <DocumentLibrary />
      </AuthValidator>
    ),
    accessKey: "documentLibrary",
    visibleToRoles: ["L0", "L1", "L2", "L3", "L4"],
  },
  {
    type: "collapse",
    name: "Master",
    key: "master",
    icon: <SettingsIcon fontSize="small" />,
    route: "/master",
    component: (
      <AuthValidator>
        <Master />
      </AuthValidator>
    ),
    accessKey: "master",
    visibleToRoles: ["L0", "L1", "L2", "L3"],
  },
  {
    type: "collapse",
    name: "Survey Forms",
    key: "survey",
    icon: <AssignmentTurnedInIcon fontSize="small" />,
    route: "/survey",
    component: (
      <AuthValidator>
        <Survey />
      </AuthValidator>
    ),
    accessKey: "survey",
    visibleToRoles: ["L0", "L1", "L2", "L3", "L4"],
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <SettingsIcon fontSize="small" />,
    route: "/settings",
    component: (
      <AuthValidator>
        <Settings />
      </AuthValidator>
    ),
    accessKey: "settings",
    visibleToRoles: ["L0", "L1", "L2", "L3", "L4"],
  },
];

/**
 * Generate sidebar items based on user's access level
 * @param {string} accessLevel - The user's access level (L0-L4 or USER)
 * @returns {Array} - Array of sidebar items the user has access to
 */
export const getSidebarItems = (accessLevel) => {
  if (!accessLevel) return [];

  const normalizedAccessLevel =
    typeof accessLevel === "object"
      ? accessLevel.roleAccess || accessLevel.accessLevel
      : accessLevel;

  return allSidebarItems.filter(
    (item) =>
      item.visibleToRoles.includes(normalizedAccessLevel) &&
      hasPermission(normalizedAccessLevel, item.accessKey)
  );
};

export default {
  getSidebarItems,
  allSidebarItems,
};
