import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";

function AddUser({
  open,
  setOpen,
  saveData,
  setSaveData,
  onSuccessPost,
  modalType,
  selectedUser,
  companies,
  units,
  departments,
}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    companyId: "",
    unitId: "",
    departmentId: "",
  });
  const [errors, setErrors] = useState({});
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [showDropdowns, setShowDropdowns] = useState({
    company: false,
    unit: false,
    department: false,
  });
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [availableRoles, setAvailableRoles] = useState([]);

  useEffect(() => {
    console.log("Companies:", companies);
    console.log("Units:", units);
    console.log("Departments:", departments);
  }, [companies, units, departments]);

  useEffect(() => {
    if (modalType === "Edit" && selectedUser) {
      setFormData({
        name: selectedUser.name || "",
        email: selectedUser.email || "",
        role: selectedUser.role?.name || "",
        companyId: selectedUser.company?.id || "",
        unitId: selectedUser.unit?.id || "",
        departmentId: selectedUser.department?.id || "",
      });
    } else {
      resetForm();
    }
  }, [modalType, selectedUser, open]);

  useEffect(() => {
    if (formData.companyId) {
      const filtered = units.filter((unit) => {
        // Check for both _id and id
        const companyId = unit.company?._id || unit.company?.id || unit.company;
        const matches = companyId === formData.companyId;
        return matches;
      });
      setFilteredUnits(filtered);
    } else {
      setFilteredUnits([]);
    }
  }, [formData.companyId, units]);

  useEffect(() => {
    if (formData.unitId) {
      const filtered = departments.filter((dept) => {
        // Check for both _id and id
        const unitId = dept.unit?._id || dept.unit?.id || dept.unit;
        const matches = unitId === formData.unitId;
        return matches;
      });
      setFilteredDepartments(filtered);
    } else {
      setFilteredDepartments([]);
    }
  }, [formData.unitId, departments]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${cookies.get("token")}`,
        };
        const response = await axios.get(`${baseUrl}${apiV1}/roles`, { headers });
        setAvailableRoles(response.data.results || []);
      } catch (error) {
        dispatch(setAlert({ message: "Error fetching roles", color: "error" }));
      }
    };

    fetchRoles();
  }, []);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      role: "",
      companyId: "",
      unitId: "",
      departmentId: "",
    });
    setErrors({});
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    }
    if (!formData.role) {
      newErrors.role = "Role is required";
    }

    // Validate based on role
    switch (formData.role) {
      case "L1":
        if (!formData.companyId) {
          newErrors.companyId = "Company is required for Company Admin";
        }
        break;
      case "L2":
        if (!formData.companyId) {
          newErrors.companyId = "Company is required for Unit Admin";
        }
        if (!formData.unitId) {
          newErrors.unitId = "Unit is required for Unit Admin";
        }
        break;
      case "L3":
      case "L4":
        if (!formData.companyId) {
          newErrors.companyId = "Company is required";
        }
        if (!formData.unitId) {
          newErrors.unitId = "Unit is required";
        }
        if (!formData.departmentId) {
          newErrors.departmentId = "Department is required";
        }
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    // Transform data based on role
    const submitData = {
      name: formData.name,
      email: formData.email,
      role: formData.role,
    };

    // Add hierarchical data based on selected role's accessLevel
    const selectedRole = availableRoles.find((r) => r.id === formData.role);
    if (selectedRole) {
      switch (selectedRole.accessLevel) {
        case "L1":
          submitData.company = formData.companyId;
          break;
        case "L2":
          submitData.company = formData.companyId;
          submitData.unit = formData.unitId;
          break;
        case "L3":
        case "L4":
          submitData.company = formData.companyId;
          submitData.unit = formData.unitId;
          submitData.department = formData.departmentId;
          break;
      }
    }

    try {
      if (modalType === "Edit") {
        const res = await axios.patch(`${baseUrl}${apiV1}/users/${selectedUser.id}`, submitData, {
          headers,
        });
        if (res.status === 200) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `User updated successfully!`, color: "success" }));
        }
      } else {
        const res = await axios.post(`${baseUrl}${apiV1}/auth/create-user`, submitData, {
          headers,
        });
        if (res.status === 201) {
          onSuccessPost();
          handleClose();
          dispatch(setAlert({ message: `User created successfully!`, color: "success" }));
        }
      }
    } catch (e) {
      console.error("Error saving user:", e);
      dispatch(
        setAlert({ message: e.response?.data?.message || "Error saving user", color: "error" })
      );
      if (e.response?.data?.errors) {
        const formattedErrors = {};
        e.response.data.errors.forEach((err) => {
          formattedErrors[err.field] = err.message;
        });
        setErrors(formattedErrors);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updates = { [name]: value };

      if (name === "companyId") {
        updates.unitId = "";
        updates.departmentId = "";
        setFilteredUnits([]);
        setFilteredDepartments([]);
      } else if (name === "unitId") {
        updates.departmentId = "";
        setFilteredDepartments([]);
      }

      return { ...prev, ...updates };
    });
  };

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    const selectedRole = availableRoles.find((r) => r.id === roleId);

    setFormData((prev) => ({
      ...prev,
      role: roleId,
      companyId: "",
      unitId: "",
      departmentId: "",
    }));

    if (selectedRole) {
      switch (selectedRole.accessLevel) {
        case "L1":
          setShowDropdowns({
            company: true,
            unit: false,
            department: false,
          });
          break;
        case "L2":
          setShowDropdowns({
            company: true,
            unit: true,
            department: false,
          });
          break;
        case "L3":
        case "L4":
          setShowDropdowns({
            company: true,
            unit: true,
            department: true,
          });
          break;
        default:
          setShowDropdowns({
            company: false,
            unit: false,
            department: false,
          });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {modalType === "Edit" ? "Edit User" : "Add User"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={Boolean(errors.role)}>
              <InputLabel>Role</InputLabel>
              <Select
                name="role"
                value={formData.role}
                sx={{ height: "40px" }}
                onChange={handleRoleChange}
                label="Role"
              >
                {availableRoles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
            </FormControl>
          </Grid>
          {showDropdowns.company && (
            <Grid item xs={12}>
              <FormControl fullWidth error={Boolean(errors.companyId)}>
                <InputLabel>Company</InputLabel>
                <Select
                  name="companyId"
                  value={formData.companyId}
                  onChange={handleChange}
                  label="Company"
                  sx={{ height: "40px" }}
                >
                  {companies.map((company) => (
                    <MenuItem key={company._id || company.id} value={company._id || company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.companyId && <FormHelperText>{errors.companyId}</FormHelperText>}
              </FormControl>
            </Grid>
          )}
          {showDropdowns.unit && (
            <Grid item xs={12}>
              <FormControl fullWidth error={Boolean(errors.unitId)}>
                <InputLabel>Unit</InputLabel>
                <Select
                  name="unitId"
                  value={formData.unitId}
                  onChange={handleChange}
                  label="Unit"
                  disabled={!formData.companyId}
                  sx={{ height: "40px" }}
                >
                  {filteredUnits.length === 0 && formData.companyId && (
                    <MenuItem disabled>No units available for selected company</MenuItem>
                  )}
                  {filteredUnits.map((unit) => (
                    <MenuItem key={unit._id || unit.id} value={unit._id || unit.id}>
                      {unit.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.unitId && <FormHelperText>{errors.unitId}</FormHelperText>}
              </FormControl>
            </Grid>
          )}
          {showDropdowns.department && (
            <Grid item xs={12}>
              <FormControl fullWidth error={Boolean(errors.departmentId)}>
                <InputLabel>Department</InputLabel>
                <Select
                  name="departmentId"
                  value={formData.departmentId}
                  onChange={handleChange}
                  label="Department"
                  disabled={!formData.unitId}
                  sx={{height: '40px'}}
                >
                  {filteredDepartments.length === 0 && formData.unitId && (
                    <MenuItem disabled>No departments available for selected unit</MenuItem>
                  )}
                  {filteredDepartments.map((department) => (
                    <MenuItem
                      key={department._id || department.id}
                      value={department._id || department.id}
                    >
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.departmentId && <FormHelperText>{errors.departmentId}</FormHelperText>}
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} color="secondary">
          Cancel
        </MDButton>
        <MDButton onClick={handleSubmit} color="info">
          {modalType === "Edit" ? "Update" : "Save"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddUser;
