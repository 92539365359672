import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextArea from "layouts/questionsList/textarea";
import TableComponent from "layouts/questionsList/table";

import { Button, IconButton, Input } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Cookies from "universal-cookie";
import axios from "axios";
import { baseUrl } from "utils/constants";
import { apiV1 } from "utils/constants";
import { setAlert } from "reduxToolkit/alert/alertSlice";
import { useDispatch } from "react-redux";

const dialogStyle = {
  // minHeight: "80vh",
  width: "100%",
  // padding: "40px",
  margin: "0 auto",
};

const dialogActionsStyle = {
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0",
};

const Addmainfile = ({
  saveData,

  task,
  setTask,
  updateAnswer,
  isFields = false,
  customquestion,
  setcustomquestion,
  setSelectedQuestion,
}) => {
  const [answers, setAnswers] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedAnswer, setselectedAnswer] = useState([]);
  const [questionID, setquestionID] = useState([]);
  const [change, setchange] = useState(true);
  const cookies = new Cookies();

  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const headers = {
    Authorization: `Bearer ${cookies.get("token")}`,
    "Content-Type": "application/json",
  };
  const fetchTasks = async (taskID) => {
    const res = await axios.get(baseUrl + apiV1 + `/tasks/${taskID}/details`, {
      headers,
    });

    // setSaveData(res.data?.tasks);

    setSelectedQuestion(res.data.question);
    setTask((prevState) => ({ ...prevState, answer: res.data.answer }));
  };

  // useEffect(() => {
  //   const headers = {
  //     Authorization: `Bearer ${cookies.get("token")}`,
  //     "Content-Type": "application/json",
  //   };

  //   const fetchTasks = async () => {
  //     const res = await axios.get(baseUrl + apiV1 + `/custom?type=900`, {
  //       headers,
  //     });

  //     setcustomquestion(res.data?.tasks[0]);
  //     console.log("firstfirst", res);
  //   };

  //   fetchTasks();
  // }, []);

  // client 51, 67, 71

  // 65, 66, 113, 115
  useEffect(() => {
    console.log("Task in Addmainfile:", task);
    console.log("Task answer:", task?.answer);
    console.log("Task customanswer:", task?.customanswer);
    
    if (task?.customanswer) {
      console.log("Setting answers from customanswer:", task.customanswer?.answerText);
      setAnswers(task.customanswer?.answerText);
    } else if (task?.answer?.[0]) {
      console.log("Setting answers from answer:", task.answer[0]?.answerText);
      setAnswers(task.answer[0]?.answerText);
    } else {
      console.log("No answers found, setting empty array");
      setAnswers([]);
    }
    setSelectedFiles([]);
  }, [task]);

  const handleSubmit = async () => {
    console.log("Submitting data...", answers, task);

    // const formData = new FormData();

    // formData.append("taskId", task._id);
    // formData.append("answerText", answers);

    // // Append selected files to the FormData object
    // for (const file of selectedFiles) {
    //   formData.append("files", file);
    // }

    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json", // Specify content type as multipart/form-data for file uploads
    };

    try {
      console.log(customquestion)
      const res = await axios.post(
        `${baseUrl}${apiV1}/customanswers/${customquestion._id}`,
        {
          taskId: task._id,
          answerText: answers,
        },
        {
          headers,
        }
      );

      // console.log("Response:", res);

      if (res.status === 201) {
        dispatch(setAlert({ message: `Answer recorded successfully!`, color: "success" }));
        // setcustomquestion(null);

        setcustomquestion(null);

        updateAnswer(res.data);

        // window.location.reload();
        // setSelectedQuestion(saveData[0]?.tasks[0]?.question);
        // console.log("got first", res.data.question.id);

        fetchTasks(task._id);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleDraft = async () => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };
    try {
      const res = await axios.post(
        baseUrl + apiV1 + `/answers/${customquestion._id}`,
        {
          taskId: task._id,
          answerText: answers,
          isDraft: true,
        },
        { headers }
      );

      if (res.status === 201) {
        dispatch(setAlert({ message: `Answer drafted successfully!`, color: "success" }));
        updateAnswer(res.data);
        setcustomquestion(null);
      }
    } catch (e) {
      console.log({ message: e });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xl"}
      open={customquestion ? true : false}
      onClose={() => {
        setcustomquestion(null);
      }}
    >
      {console.log("Current answers state:", answers)}
      {console.log("Custom question:", customquestion)}
      <Box style={dialogStyle}>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
            {customquestion?.questionText}

            <Icon
              fontSize="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setcustomquestion(null);
              }}
            >
              close
            </Icon>
          </Box>
        </DialogTitle>
        <DialogContent>
          {customquestion?.questionType === "text" && (
            <TextArea answers={answers} setAnswers={setAnswers} />
          )}

          {(customquestion?.questionType === "table" ||
            customquestion?.questionType === "formulae") && (
            <TableComponent
              columns={customquestion?.columns}
              rows={customquestion?.rows}
              tableSections={customquestion?.table_sections}
              formulae={customquestion?.formulae}
              questionType={customquestion?.questionType}
              answers={answers}
              setAnswers={setAnswers}
            />
          )}
          {/* <div>
            <Input
              type="file"
              onChange={handleFileChange}
              inputProps={{ accept: ".pdf, .doc, .docx", multiple: true }} // Specify accepted file types and allow multiple files
              style={{ display: "none" }} // Hide the input visually
              id="file-upload-input"
            />
            <label htmlFor="file-upload-input">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
            {selectedFiles.length > 0 ? (
              <div style={{ marginLeft: "10px" }}>
                {selectedFiles.map((file, index) => (
                  <div key={index}>{file.name}</div>
                ))}
              </div>
            ) : (
              "No files"
            )}
          </div> */}
        </DialogContent>

        {!isFields && (
          <DialogActions style={dialogActionsStyle}>
            <MDBox
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
                alignItems: "end",
                width: "100%",
              }}
            >
              {/* <MDButton onClick={handleDraft} variant="outlined" color="secondary">
                Save as draft
              </MDButton> */}
              <MDButton onClick={handleSubmit} variant="gradient" color="info">
                {"Submit"}
              </MDButton>
            </MDBox>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

export default Addmainfile;
