import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import TaskStatisticsTable from "./components/TaskStatisticsTable";
import axios from "axios";
import { baseUrl, apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import MDTypography from "components/MDTypography";

function AdminDashboard() {
  const [statistics, setStatistics] = useState({ companies: [] });
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    company: null,
    unit: null,
    department: null,
  });
  const cookies = new Cookies();

  const fetchStatistics = async () => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      };

      // Add query parameters based on filters
      const params = new URLSearchParams();
      if (filters.company) params.append("companyId", filters.company);
      if (filters.unit) params.append("unitId", filters.unit);
      if (filters.department) params.append("departmentId", filters.department);

      const response = await axios.get(`${baseUrl}${apiV1}/tasks/statistics`, {
        headers,
        params,
      });
      setStatistics(response.data);
    } catch (error) {
      console.error("Error fetching statistics:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, [filters]);

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox>
              <MDTypography variant="h6" gutterBottom>
                Dashboard
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mb={3}>
            <Card>
              <TaskStatisticsTable data={statistics} loading={loading}></TaskStatisticsTable>
            </Card>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminDashboard;
