import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "reduxToolkit/user/userSlice";
import { apiV1, baseUrl } from "utils/constants";
import { isTokenValid } from "utils/tokenValidator";
import { setAlert } from "../reduxToolkit/alert/alertSlice";
import { ReasonPhrases } from "http-status-codes";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";

const AuthValidator = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const refreshToken = async () => {
    try {
      const storedRefreshToken = cookies.get("refreshToken");
      if (!storedRefreshToken) throw new Error("No refresh token available");

      const res = await axios.post(`${baseUrl}${apiV1}/auth/refresh-tokens`, {
        refreshToken: storedRefreshToken,
      });

      const { access, refresh } = res.data;
      cookies.set("token", access.token, { path: "/" });
      cookies.set("refreshToken", refresh.token, { path: "/" });
      return access.token;
    } catch (error) {
      cookies.remove("token");
      cookies.remove("refreshToken");
      cookies.remove("role");
      dispatch(
        setAlert({
          message: error.response?.data?.error || ReasonPhrases.UNAUTHORIZED,
          color: "error",
        })
      );
      navigate("/authentication/sign-in", { replace: true });
      return null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      let token = isTokenValid();
      if (!token) {
        token = await refreshToken();
        if (!token) return;
      }

      try {
        const res = await axios.get(`${baseUrl}${apiV1}/users/userinfo`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Transform the user data to include role information properly
        const userData = {
          ...res.data,
          roleAccess: res.data.role.name, // Use the role name (SUPER_ADMIN)
          accessLevel: res.data.role.accessLevel // Keep the L0 access level as backup
        };

        // Store role in cookies
        cookies.set("role", userData.roleAccess, { path: "/" });
        cookies.set("accessLevel", userData.accessLevel, { path: "/" });
        cookies.set("company", userData.company, { path: "/" });
        cookies.set("userId", userData.id, { path: "/" });
        console.log("Processed User Info:", userData);
        dispatch(setUser(userData));
        setIsLoading(false);
      } catch (error) {
        cookies.remove("token");
        cookies.remove("refreshToken");
        cookies.remove("role");
        cookies.remove("accessLevel");
        dispatch(
          setAlert({
            message: error.response?.data?.error || ReasonPhrases.INTERNAL_SERVER_ERROR,
            color: "error",
          })
        );
        navigate("/authentication/sign-in", { replace: true });
      }
    };

    getUser();
  }, []);

  if (isLoading) {
    return (
      <CircularProgress
        sx={{ color: "primary.main", position: "absolute", top: "50%", left: "50%" }}
        size={24}
      />
    );
  }

  return <>{children}</>;
};

export default AuthValidator;
