/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDBox from "components/MDBox";
import { Typography } from "@mui/material";

export default function data(list, setSelectedSection) {
  const columns = [
    { Header: "Sections", accessor: "sections" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  const newArr = [...new Set(list.map((item) => item?.question?.section))];

  return {
    columns,
    rows: newArr.map((item) => {
      return {
        sections: <div>{item}</div>,
        // email: (
        //   <MDBox
        //     sx={{
        //       display: "flex",
        //       flexDirection: "row",
        //       gap: 1,
        //     }}
        //   >
        //     {userName.map((user) => (
        //       <Tooltip title={user}>
        //         <Avatar
        //           sx={{
        //             bgcolor: "#1976d2",
        //             width: 25,
        //             height: 25,
        //             fontWeight: 400,
        //             padding: 1,
        //             fontSize: "16px",
        //           }}
        //         >
        //           {user.charAt(0)}
        //         </Avatar>
        //       </Tooltip>
        //     ))}
        //   </MDBox>
        // ),
        // action: (
        //   <MDBox
        //     width="16rem"
        //     textAlign="left"
        //     style={{ gap: "12px", display: "flex", justifyContent: "center" }}
        //   >
        //     <MDButton
        //       variant="gradient"
        //       color="info"
        //       onClick={() => setOpen(true)}
        //       style={{ color: "white", borderRadius: "5px", padding: "0.7rem" }}
        //     >
        //       Assign a Question
        //     </MDButton>
        //     <AddTask
        //       open={open}
        //       setOpen={setOpen}
        //       // saveData={saveData}
        //       // setSaveData={setSaveData}
        //       // onSuccessPost={onSuccessPost}
        //     />
        //     {/* <Tooltip title="Edit">
        //       <IconButton
        //         // className={classes.actionIcons}
        //         style={{}}
        //         onClick={() => console.log("Edit clicked")}
        //       >
        //         <EditIcon />
        //       </IconButton>
        //     </Tooltip>
        //     <Tooltip title="Delete">
        //       <IconButton
        //         // className={classes.actionIcons}
        //         onClick={() => console.log("Delete clicked")}
        //       >
        //         <DeleteIcon />
        //       </IconButton>
        //     </Tooltip> */}
        //   </MDBox>
        // ),
        action: (
          <MDBox
            onClick={() => {
              setSelectedSection(item);
            }}
          >
            <Typography style={{ color: "blue", fontSize: 14, cursor: "pointer" }}>
              View parts
            </Typography>
          </MDBox>
        ),
      };
    }),
  };
}
