import { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { baseUrl, apiV1 } from "utils/constants";
import Cookies from "universal-cookie";
import AssignedTasksTable from "./components/AssignedTasksTable";
import AddAnswer from "layouts/dashboard/components/Fields/AddAnswer";
import Addmainfile from "layouts/dashboard/components/Tasks/Addmainfile";

function AssignedTasks() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [customquestion, setCustomquestion] = useState(null);
  const [showCustomQuestion, setShowCustomQuestion] = useState(false);
  const cookies = new Cookies();

  const fetchAssignedTasks = async () => {
    try {
      console.log('Fetching assigned tasks...');
      setLoading(true);
      const token = cookies.get("token");
      const res = await axios.get(baseUrl + apiV1 + "/tasks/assignee", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (res.status === 200) {
        console.log('Received tasks from API:', res.data.tasks);
        const transformedTasks = res.data.tasks.map(task => ({
          ...task
        }));
        setTasks(transformedTasks);
      }
    } catch (error) {
      console.error("Error fetching assigned tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignedTasks();
  }, []);

  useEffect(() => {
    setShowCustomQuestion(false);
  }, [selectedQuestion]);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${cookies.get("token")}`,
      "Content-Type": "application/json",
    };

    const fetchCustomQuestion = async () => {
      if (selectedTask?.question?.parentQuestion && showCustomQuestion) {
        try {
          const res = await axios.get(
            baseUrl + apiV1 + `/custom?type=${selectedTask.question.parentQuestion}`,
            { headers }
          );
          setCustomquestion(res.data?.tasks[0]);
        } catch (error) {
          console.error("Error fetching custom question:", error);
        }
      }
    };

    fetchCustomQuestion();
  }, [selectedTask, showCustomQuestion]);

  const handleTaskUpdate = async (taskId, answer) => {
    console.log('Handling task update:', {
      taskId,
      newAnswer: answer
    });
    const updatedTasks = tasks.map(task => 
      task._id === taskId ? { 
        ...task, 
        answer: answer,
        taskStatus: answer ? "answered" : "unanswered" 
      } : task
    );
    setTasks(updatedTasks);
    fetchAssignedTasks();
  };

  const handleQuestionClick = (question, task) => {
    console.log('Question selected:', {
      question,
      task,
      existingAnswer: task?.answer
    });
    setSelectedQuestion(question);
    setSelectedTask(task);
    setShowCustomQuestion(false);
    setCustomquestion(null);
  };

  return (
    <DashboardLayout>
      <MDBox py={3} minHeight={"calc(100vh - 10rem)"}>
        <Card>
          <MDBox >
            <AssignedTasksTable
              tasks={tasks}
              onQuestionClick={handleQuestionClick}
            />
          </MDBox>
          <MDBox>
            {showCustomQuestion && customquestion ? (
              <Addmainfile
                customquestion={customquestion}
                setcustomquestion={setCustomquestion}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                task={selectedTask}
                setTask={setSelectedTask}
                updateAnswer={handleTaskUpdate}
              />
            ) : selectedQuestion && (
              <AddAnswer
                setCustomquestion={setCustomquestion}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                task={selectedTask}
                setTask={setSelectedTask}
                updateAnswer={handleTaskUpdate}
                onEnterData={() => setShowCustomQuestion(true)}
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default AssignedTasks; 